import React from 'react';
import PropTypes from 'prop-types';

const Block = ({ children }) => (
  <div className={'d-block box-shadow-block bg-white overflow-hidden radius-rounded-8px'}>
    {children}
  </div>
);

Block.propTypes = {
  children: PropTypes.any,
};

export default Block;