import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { searchApprovedChatConversations } from '../../../actions/chat_conversations';
import { onChangeSearch } from '../../../actions/chats';
import Icon from '../../../../brighteon/components/icon';
import IconButton from '../../../../brighteon/components/icon_button';
import { openModal } from '../../../actions/modal';
import { MODAL_CHAT_CONVERSATION_CREATE } from '../../../constants';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

class ChatConversationsSearch extends React.PureComponent {

  handleOnChange = (e) => {
    this.props.onChange(e.target.value);
  }

  handleOnCreateNewChat = () => {
    this.props.onOpenChatConversationCreateModal();
  }

  render() {
    const { value, isXS } = this.props;

    return (
      <div className={'chat-sidebar-top pos-relative d-flex border-bottom-medium1-1'}>
        <Icon id='search-gray' size={24} className={classNames('pos-absolute', { 'left-20px top-17px' : !isXS, 'left-10px top-8px' : isXS })} />
        <input
          className='search-input h-34px radius-rounded-4px w-100 pl40px'
          placeholder='Search Messages'
          value={value}
          onChange={this.handleOnChange}
        />
        <div className='mt4px ml10px'>
          <NavLink to='/messages/requests' className='circle icon-button p3px'>
            <Icon id='preferences' size={24} className='' title='Chat Preferences' />
          </NavLink>
        </div>
        <div className='mt4px ml10px'>
          <IconButton icon='new-gray' size={24} className='circle' title='New Chat' onClick={this.handleOnCreateNewChat} />
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  value: state.getIn(['chats', 'searchValue'], ''),
});

const mapDispatchToProps = (dispatch) => ({
  onChange(value) {
    dispatch(onChangeSearch(value));
    dispatch(searchApprovedChatConversations(value));
  },
  onOpenChatConversationCreateModal() {
    dispatch(openModal(MODAL_CHAT_CONVERSATION_CREATE));
  },
});

ChatConversationsSearch.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onOpenChatConversationCreateModal: PropTypes.func.isRequired,
  isXS: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatConversationsSearch);