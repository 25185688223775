import { fromJS, Map as ImmutableMap } from 'immutable';
import {
  BRIGHTEON_FEED_FETCH_REQUEST,
  BRIGHTEON_FEED_FETCH_SUCCESS,
  BRIGHTEON_FEED_FETCH_FAIL,
} from '../actions/brighteon_feed';

const initialState = ImmutableMap({
  items: ImmutableMap(),
  isLoading: true,
  loaded: false,
});

export default function brighteonFeed(state = initialState, action) {
  switch(action.type) {
  case BRIGHTEON_FEED_FETCH_REQUEST:
    return state.set('isLoading', true);
  case BRIGHTEON_FEED_FETCH_SUCCESS:
    return state
      .set('items', fromJS(action.videos))
      .set('isLoading', false);
  case BRIGHTEON_FEED_FETCH_FAIL:
    return state.set('isLoading', false);
  default:
    return state;
  }
};
