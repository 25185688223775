import React from 'react';

class DonateBoxContainer extends React.PureComponent {

  render() {
    return (
      <div className='widget widget-highlight'>
        <span className='title'>
          Donate to Brighteon
        </span>
        <div className='content'>
          <div className='text'>
            <p>
              Together, with your help, we can keep the Brighteon family of sites operating, innovating and fighting against online censorship. Any amount you can spare makes a difference.
            </p>
          </div>
          <div className='actions'>
            <a href='https://donation.brighteon.com' target='_blank' className='demo-button demo-button-a demo-button--block'>Support Us</a>
            <a href='https://donation.brighteon.com' target='_blank'>Learn More</a>
          </div>
        </div>
      </div>
    );
  }

}

export default DonateBoxContainer;
