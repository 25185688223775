import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { chatConversationUnreadCountReset } from '../../../actions/chat_conversations';
import { openModal } from '../../../actions/modal';
import { MODAL_CONFIRM_GAB } from '../../../constants';
import Button from '../other_components/button';
import Text from '../other_components/text';

class ChatConversationClearUnreadMessages extends React.PureComponent {

  handleOnClick = () => {
    this.props.onOpenConfirmationModal(this.handleOnChatConversationUnreadCountReset);
  }

  handleOnChatConversationUnreadCountReset = () => {
    this.props.onChatConversationUnreadCountReset();
  }

  render() {
    const { unreadChatsCount } = this.props;

    if (isNaN(unreadChatsCount) || unreadChatsCount <= 0) return null;

    return (
      <Button
        noClasses
        className={'d w-100 bg-transparent bg-subtle-onhover border-bottom-medium1-1 borderColorSecondary decoration-none outline-none cursor-pointer'}
        onClick={this.handleOnClick}
      >
        <div className={'d px15px py15px align-items-center flex-row'}>
          <Text size='medium' color='black'>Mark all as read</Text>
        </div>
      </Button>
    );
  }

}


const mapStateToProps = (state) => ({
  unreadChatsCount: state.getIn(['chats', 'chatsUnreadCount']),
});

const mapDispatchToProps = (dispatch) => ({
  onChatConversationUnreadCountReset() {
    dispatch(chatConversationUnreadCountReset());
  },
  onOpenConfirmationModal(onConfirm) {
    dispatch(openModal(MODAL_CONFIRM_GAB, {
      title: 'Mark All as Read?',
      message: 'This action will mark all of your unread messages as "read". Your unread message counter will be set to 0. No messages will be deleted.',
      confirm: 'Yes, Mark All as Read',
      onConfirm,
    }));
  },
});

ChatConversationClearUnreadMessages.propTypes = {
  onChatConversationUnreadCountReset: PropTypes.func,
  onOpenConfirmationModal: PropTypes.func,
  unreadChatsCount: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatConversationClearUnreadMessages);