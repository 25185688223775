import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { shortNumberFormat } from '../../../utils/numbers';
import { fetchChatConversationHiddenCount } from '../../../actions/chat_conversations';
import Text from '../other_components/text';
import Icon from '../../../components/icon';

class ChatConversationHiddenListItem extends React.PureComponent {

  componentDidMount() {
    this.props.onFetchChatConversationHiddenCount();
  }

  render() {
    const { hiddenCount } = this.props;

    if (!hiddenCount || hiddenCount < 1) return null;

    return (
      <NavLink
        className={'d w-100 bg-transparent bg-subtle-onhover border-bottom-medium1-1 decoration-none outline-none cursor-pointer'}
        to='/messages/hidden_conversations'
      >
        <div className={'d px15px py15px align-items-center flex-row color-black'}>
          <Text size='medium'>Hidden Conversations</Text>
          <Text size='medium' className='ml-auto mr15px' color='black'>
            {shortNumberFormat(hiddenCount)}
          </Text>
          <Icon id='angle-right' size={10} className={''} />
        </div>
      </NavLink>
    );
  }

}


const mapStateToProps = (state) => ({
  hiddenCount: state.getIn(['chats', 'chatConversationHiddenCount'], 0),
});

const mapDispatchToProps = (dispatch) => ({
  onFetchChatConversationHiddenCount: () => dispatch(fetchChatConversationHiddenCount()),
});

ChatConversationHiddenListItem.propTypes = {
  hiddenCount: PropTypes.number,
  onFetchChatConversationHiddenCount: PropTypes.func.isRequired,
};

ChatConversationHiddenListItem.defaultProps = {
  hiddenCount: 0,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatConversationHiddenListItem);
