import React from 'react';
import IconButton from '../../../components/icon_button';
import PropTypes from 'prop-types';

const iconStyle = {
  height: null,
  lineHeight: '27px',
  size: 18,
};

export default class SpoilerButton extends React.PureComponent {

  static propTypes = {
    label: PropTypes.string.isRequired,
    title: PropTypes.string,
    active: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    ariaControls: PropTypes.string,
    iconSize: PropTypes.number,
  };

  handleClick = (e) => {
    e.preventDefault();
    this.props.onClick();
  }

  render () {
    const { title } = this.props;
    return (

      <div className='compose-form__spoiler-button'>
        <IconButton
          icon='warning'
          title={title}
          onClick={this.handleClick}
          className='compose-form__spoiler-button-icon'
          size={this.props.iconSize || iconStyle.size}
          inverted
          style={iconStyle}
        />
        <label>
          <span style={{ display: 'none' }}>{title}</span>
          <input
            ref={this.setRef}
            type='file'
            multiple
            onChange={this.handleChange}
            style={{ display: 'none' }}
          />
        </label>
      </div>
    );
  }

}
