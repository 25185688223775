import { combineReducers } from 'redux-immutable';
import dropdown_menu from './dropdown_menu';
import timelines from './timelines';
import meta from './meta';
import alerts from './alerts';
import { loadingBarReducer } from 'react-redux-loading-bar';
import modal from './modal';
import user_lists from './user_lists';
import domain_lists from './domain_lists';
import accounts from './accounts';
import accounts_counters from './accounts_counters';
import statuses from './statuses';
import relationships from './relationships';
import settings from './settings';
import push_notifications from './push_notifications';
import status_lists from './status_lists';
import mutes from './mutes';
import blocks from './blocks';
import boosts from './boosts';
import reports from './reports';
import contexts from './contexts';
import compose from './compose';
import compose_in_reply from './compose_in_reply';
import search from './search';
import media_attachments from './media_attachments';
import notifications from './notifications';
import height_cache from './height_cache';
import custom_emojis from './custom_emojis';
import lists from './lists';
import listEditor from './list_editor';
import listAdder from './list_adder';
import filters from './filters';
import conversations from './conversations';
import suggestions from './suggestions';
import polls from './polls';
import identity_proofs from './identity_proofs';
import trends from './trends';
import missed_updates from './missed_updates';
import announcements from './announcements';
import markers from './markers';
import picture_in_picture from './picture_in_picture';
import brighteon_feed from './brighteon_feed';
import uncensored_news_feed from './uncensored_news_feed';
import notifications_status_bar from './notifications_status_bar';
import dashboard_settings from './dashboard_settings';
import chats from './chats';
import chat_compose from './chat_compose';
import chat_conversation_lists from './chat_conversation_lists';
import chat_conversation_messages from './chat_conversation_messages';
import chat_conversations from './chat_conversations';
import chat_messages from './chat_messages';
import chat_settings from './chat_settings';
import popover from './popover';
import crypto_wallets from './crypto_wallets';

const reducers = {
  announcements,
  dropdown_menu,
  timelines,
  meta,
  alerts,
  loadingBar: loadingBarReducer,
  modal,
  user_lists,
  domain_lists,
  status_lists,
  accounts,
  accounts_counters,
  statuses,
  relationships,
  settings,
  push_notifications,
  mutes,
  blocks,
  boosts,
  reports,
  contexts,
  compose,
  compose_in_reply,
  search,
  media_attachments,
  notifications,
  height_cache,
  custom_emojis,
  identity_proofs,
  lists,
  listEditor,
  listAdder,
  filters,
  conversations,
  suggestions,
  polls,
  trends,
  missed_updates,
  markers,
  picture_in_picture,
  brighteon_feed,
  uncensored_news_feed,
  notifications_status_bar,
  dashboard_settings,
  chats,
  chat_compose,
  chat_conversation_lists,
  chat_conversation_messages,
  chat_conversations,
  chat_messages,
  chat_settings,
  popover,
  crypto_wallets,
};

export default combineReducers(reducers);
