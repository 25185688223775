import api from '../api';

export const SETTINGS_FOLLOW_FETCH_ACCOUNTS  = 'SETTINGS_FOLLOW_FETCH_ACCOUNTS';
export const SETTINGS_FOLLOW_UPDATE_ACCOUNTS = 'SETTINGS_FOLLOW_UPDATE_ACCOUNTS';
export const SETTINGS_SET_MUTUAL_CONTACTS    = 'SETTINGS_SET_MUTUAL_CONTACTS';

export const fetchFollowAccounts = (filter) => (dispatch, getState) => {
  const { accountId } = filter;
  delete filter.accountId;
  const filterUrl = new URLSearchParams(filter).toString();

  api(getState).get(`/api/v1/accounts/relationships/${accountId}/filter?${filterUrl}`)
    .then(({ data }) => dispatch({
      type: SETTINGS_FOLLOW_FETCH_ACCOUNTS,
      data: data,
    })).catch(console.warn);
};

export const updateFollowAccounts = ({ action, accountIds, accountId, filter }) => (dispatch, getState) => {
  const params = {
    form_account_batch: {
      account_ids: accountIds,
    },
  };
  params[action] = '';

  api(getState).post(`/api/v1/accounts/relationships/${accountId}/update`, params)
    .then(({ /*data*/ }) => {
      dispatch(fetchFollowAccounts({ ...filter, accountId }));
    }).catch(console.warn);
};

export const fetchMutualAccounts = () => (dispatch, getState) => {
  api(getState).get('/api/v1/accounts/relationships/mutual_contacts')
    .then(({ data }) => dispatch({
      type: SETTINGS_SET_MUTUAL_CONTACTS,
      data: data,
    }))
    .catch(console.warn);
};
