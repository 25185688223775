import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ImmutablePureComponent from 'react-immutable-pure-component';
import { me } from '../../../initial_state';
import {
  POPOVER_USER_INFO,
} from '../../../constants';
import { openPopoverDeferred, cancelPopover } from '../../../actions/popover';
import Icon from './icon';
import Text from './text';
import classNames from 'classnames';

class DisplayName extends ImmutablePureComponent {

  openUserInfo(evt) {
    // targetRef can be missing until the components set it
    const targetRef = this.displayNameRef || evt.target;

    if (this.props.noHover) {
      // The display name can be inside a popover and we don't want to open
      // another popover or jump the existing popover to different coordinates.
      return;
    }

    if (!targetRef) {
      // it doesn't reliably get a ref
      return;
    }

    this.props.openUserInfoPopover({
      targetRef,
      position: 'top-start',
      accountId: this.props.account.get('id'),
      timeout: 1250,
    });
  }

  handleMouseEnter = evt => this.openUserInfo(evt)
  handleMouseMove = evt => this.openUserInfo(evt)
  handleMouseLeave = () => this.props.onCancelPopover()
  setDisplayNameRef = el => this.displayNameRef = el

  render() {
    const {
      account,
      isMultiline,
      isGrouped,
      isLarge,
      noHover,
      noDisplayName,
      noUsername,
      noRelationship,
      isSmall,
      isComment,
      isCentered,
      isInline,
    } = this.props;

    if (!account) return null;

    const containerClassName = classNames({
      'd': !isGrouped,
      'max-w-100pc': 1,
      'align-items-center': !isMultiline,
      'flex-row': !isMultiline,
      'cursor-pointer': !noHover,
      'align-items-center': isCentered,
      'd-inline-block': isInline,
    });

    const displayNameClasses = classNames({
      'text': 1,
      'overflow-wrap-break-word': 1,
      'white-space-no-wrap': 1,
      'font-weight-600': 1,
      'cPrimary': 1,
      'mr2px': 1,
      'line-height-125': !isSmall,
      'font-size-14px': isSmall,
      'font-size-15px': !isLarge,
      'font-size-24px': isLarge && !isSmall,
    });

    const usernameClasses = classNames({
      'text': 1,
      'd-flex': isMultiline,
      'white-space-no-wrap': 1,
      'text-overflow-ellipsis2': 1,
      'overflow-hidden': 1,
      'cSecondary': !noDisplayName,
      'cPrimary': noDisplayName,
      'font-weight-400': !noDisplayName,
      'font-weight-600': noDisplayName,
      'line-height-15': isMultiline,
      'line-height-125': !isMultiline,
      'ml5px': !isMultiline && !noDisplayName,
      'font-size-14px': isSmall,
      'font-size-15px': !isLarge,
      'font-size-16px': isLarge && !isSmall,
      'color-medium2': !isGrouped,
      'color-black': isGrouped,
    });

    let iconSize = '';
    if (!!isLarge) iconSize = 19;
    else if (!!isComment) iconSize = 12;
    else if (!!isSmall) iconSize = 14;
    else iconSize = 15;

    let relationshipLabel;
    if (me && account) {
      const accountId = account.get('id');
      const isFollowedBy = (me !==  accountId && account.getIn(['relationship', 'followed_by']));

      if (isFollowedBy) {
        relationshipLabel = 'Follows you';
      }
    }

    return (
      <div
        ref={this.setDisplayNameRef}
        className={containerClassName}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        onMouseLeave={this.handleMouseLeave}
      >
        {
          !noDisplayName &&
          <span className={'d flex-row align-items-center max-w-100pc flex-shrink0 overflow-hidden'}>
            <bdi className={'text white-space-no-wrap text-overflow-ellipsis'}>
              <strong
                className={displayNameClasses}
                dangerouslySetInnerHTML={{ __html: account.get('display_name_html') }}
              />
              {
                account.get('locked') &&
                <Icon id='lock-filled' size={`${iconSize - 3}px`} className={' '} />
              }
            </bdi>
            {
              account.get('is_verified') &&
              <Icon id='verified' size={`${iconSize}px`} className={' '} />
            }
          </span>
        }
        {
          !noUsername &&
          <span className={usernameClasses}>
            @{account.get('acct')}
            {
              !noRelationship && !!relationshipLabel &&
              <span className={'d ml5px justify-content-center'}>
                <Text
                  size='extraSmall'
                  isBadge
                  color='tertiary'
                  className={'bg-medium1 py2px color-black'}
                >
                  {relationshipLabel}
                </Text>
              </span>
            }
          </span>
        }
      </div>
    );
  }

}

const mapDispatchToProps = (dispatch) => ({
  openUserInfoPopover(props) {
    dispatch(openPopoverDeferred(POPOVER_USER_INFO, props));
  },
  onCancelPopover() {
    dispatch(cancelPopover());
  },
});

DisplayName.propTypes = {
  account: ImmutablePropTypes.map,
  openUserInfoPopover: PropTypes.func.isRequired,
  onCancelPopover: PropTypes.func.isRequired,
  isLarge: PropTypes.bool,
  isMultiline: PropTypes.bool,
  isSmall: PropTypes.bool,
  noHover: PropTypes.bool,
  noRelationship: PropTypes.bool,
  noUsername: PropTypes.bool,
  noDisplayName: PropTypes.bool,
  isComment: PropTypes.bool,
  isCentered: PropTypes.bool,
  isInline: PropTypes.bool,
  isGrouped: PropTypes.bool,
};

export default (connect(null, mapDispatchToProps)(DisplayName));
