import React from 'react';
import PropTypes from 'prop-types';
import ComposeFormPopupContainer from '../../../containers/compose_form_popup_container';
import Icon from '../../../components/icon';

class NewPostModalContainer extends React.PureComponent {

  static propTypes = {
    show: PropTypes.bool,
    closeNewPostModal: PropTypes.func.isRequired,
  };

  render() {
    const show = this.props.show;
    return (
      <div>
        {show &&
        (
          <div className='new-post-modal-container'>
            <div className='new-post-modal'>
              <ComposeFormPopupContainer singleColumn closeComposeModal={this.props.closeNewPostModal} />
              <div className='close-icon'>
                <Icon id='clear' onClick={this.props.closeNewPostModal} />
              </div>
            </div>
          </div>)
        }
      </div>
    );
  }

}

export default NewPostModalContainer;