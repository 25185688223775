import React from 'react';
import PropTypes from 'prop-types';
// import { getRandomInt } from '../../utils/numbers'
// import PlaceholderLayout from './placeholder_layout';
// import classNames from 'classnames';
// import ResponsiveClassesComponent from '../../features/ui/util/responsive_classes_component'

class ListItemPlaceholder extends React.PureComponent {

  render() {
    // const { isLast } = this.props;

    // const classes = classNames({
    //   d: 1,
    //   px15: 1,
    //   py15: 1,
    //   borderColorSecondary: !isLast,
    //   borderBottom1PX: !isLast,
    // });

    // const classesXS = classNames({
    //   d: 1,
    //   px15: 1,
    //   py7: 1,
    //   borderColorSecondary: !isLast,
    //   borderBottom1PX: !isLast,
    // });

    // const width = getRandomInt(120, 375)
    // const width = 177;

    return (
      <span>Responsive Class Component</span>
    );
  }

}

ListItemPlaceholder.propTypes = {
  isLast: PropTypes.bool,
};

export default ListItemPlaceholder;
