import { connect } from 'react-redux';
import UploadProgress from '../components/upload_progress';

const mapStateToProps = (state, { chatConversationId }) => {
  if (chatConversationId) {
    return {
      active: state.getIn(['chat_compose', chatConversationId, 'is_uploading']),
      progress: state.getIn(['chat_compose', chatConversationId, 'progress']),
    };
  }
  return {
    active: state.getIn(['compose', 'is_uploading']),
    progress: state.getIn(['compose', 'progress']),
  };
};

export default connect(mapStateToProps)(UploadProgress);
