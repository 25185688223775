import { defineMessages } from 'react-intl';
import { CancelToken, isCancel } from 'axios';
import { useEmoji } from './emojis';
import { throttle } from 'lodash';
import resizeImage from '../utils/resize_image';
import api from '../api';
import { showAlert } from './alerts';
import { showAlertForError } from './alerts';
import { uploadComposeFail } from './compose';
import { importFetchedAccounts } from './importer';
import { search as emojiSearch } from '../features/emoji/emoji_mart_search_light';

let cancelFetchChatComposeSuggestionsAccounts, cancelFetchChatComposeSuggestionsTags;

export const CHAT_STORE_HYDRATE                   = 'CHAT_STORE_HYDRATE';
export const CHAT_COMPOSE_MOUNT                   = 'CHAT_COMPOSE_MOUNT';
export const CHAT_COMPOSE_UNMOUNT                 = 'CHAT_COMPOSE_UNMOUNT';
export const CHAT_COMPOSE_CHANGE                  = 'CHAT_COMPOSE_CHANGE';
export const CHAT_COMPOSE_SET_ORIGIN_MESSAGE_ID   = 'CHAT_COMPOSE_SET_ORIGIN_MESSAGE_ID';
export const CHAT_COMPOSE_COMPOSING_CHANGE        = 'CHAT_COMPOSE_COMPOSING_CHANGE';
export const CHAT_COMPOSE_REPLY                   = 'CHAT_COMPOSE_REPLY';
export const CHAT_COMPOSE_SUBMIT_REQUEST          = 'CHAT_COMPOSE_SUBMIT_REQUEST';
export const CHAT_COMPOSE_UPLOAD_CHANGE_REQUEST   = 'CHAT_COMPOSE_UPLOAD_CHANGE_REQUEST';
export const CHAT_COMPOSE_REPLY_CANCEL            = 'CHAT_COMPOSE_REPLY_CANCEL';
export const CHAT_COMPOSE_RESET                   = 'CHAT_COMPOSE_RESET';
export const CHAT_COMPOSE_SUBMIT_SUCCESS          = 'CHAT_COMPOSE_SUBMIT_SUCCESS';
export const CHAT_COMPOSE_SUBMIT_FAIL             = 'CHAT_COMPOSE_SUBMIT_FAIL';
export const CHAT_COMPOSE_UPLOAD_CHANGE_FAIL      = 'CHAT_COMPOSE_UPLOAD_CHANGE_FAIL';
export const CHAT_COMPOSE_UPLOAD_REQUEST          = 'CHAT_COMPOSE_UPLOAD_REQUEST';
export const CHAT_COMPOSE_UPLOAD_SUCCESS          = 'CHAT_COMPOSE_UPLOAD_SUCCESS';
export const CHAT_COMPOSE_UPLOAD_FAIL             = 'CHAT_COMPOSE_UPLOAD_FAIL';
export const CHAT_COMPOSE_UPLOAD_UNDO             = 'CHAT_COMPOSE_UPLOAD_UNDO';
export const CHAT_COMPOSE_UPLOAD_PROGRESS         = 'CHAT_COMPOSE_UPLOAD_PROGRESS';

export const CHAT_THUMBNAIL_UPLOAD_REQUEST        = 'CHAT_THUMBNAIL_UPLOAD_REQUEST';
export const CHAT_THUMBNAIL_UPLOAD_PROGRESS       = 'CHAT_THUMBNAIL_UPLOAD_PROGRESS';
export const CHAT_THUMBNAIL_UPLOAD_FAIL           = 'CHAT_THUMBNAIL_UPLOAD_FAIL';
export const CHAT_THUMBNAIL_UPLOAD_SUCCESS        = 'CHAT_THUMBNAIL_UPLOAD_SUCCESS';

export const CHAT_COMPOSE_MENTION                 = 'CHAT_COMPOSE_MENTION';
export const CHAT_COMPOSE_SUGGESTIONS_CLEAR       = 'CHAT_COMPOSE_SUGGESTIONS_CLEAR';
export const CHAT_COMPOSE_SUGGESTIONS_READY       = 'CHAT_COMPOSE_SUGGESTIONS_READY';
export const CHAT_COMPOSE_SUGGESTION_SELECT       = 'CHAT_COMPOSE_SUGGESTION_SELECT';
export const CHAT_COMPOSE_SUGGESTION_TAGS_UPDATE  = 'CHAT_COMPOSE_SUGGESTION_TAGS_UPDATE';
export const CHAT_COMPOSE_TAG_HISTORY_UPDATE      = 'CHAT_COMPOSE_TAG_HISTORY_UPDATE';

export const CHAT_TIMELINE_DELETE                 = 'CHAT_TIMELINE_DELETE';

export const CHAT_COMPOSE_EMOJI_INSERT            = 'CHAT_COMPOSE_EMOJI_INSERT';
export const CHAT_COMPOSE_UPLOAD_CHANGE_SUCCESS   = 'CHAT_COMPOSE_UPLOAD_CHANGE_SUCCESS';
export const CHAT_COMPOSE_SET_STATUS              = 'CHAT_COMPOSE_SET_STATUS';

const messages = defineMessages({
  uploadErrorLimit: { id: 'upload_error.limit', defaultMessage: 'File upload limit exceeded.' },
  uploadErrorPoll:  { id: 'upload_error.poll', defaultMessage: 'File upload not allowed with polls.' },
});

export function changeChatCompose(text, chatConversationId) {
  return {
    type: CHAT_COMPOSE_CHANGE,
    text: text,
    chatConversationId,
  };
};

export function clearChatCompose(chatConversationId) {
  return {
    type: CHAT_COMPOSE_RESET,
    chatConversationId,
  };
};

export const setOriginChatMessage = (value, chatConversationId) => (dispatch) => {
  dispatch({
    type: CHAT_COMPOSE_SET_ORIGIN_MESSAGE_ID,
    value,
    chatConversationId,
  });
};

export function insertEmojiChatCompose(position, emoji, needsSpace, chatConversationId) {
  return {
    type: CHAT_COMPOSE_EMOJI_INSERT,
    position,
    emoji,
    needsSpace,
    chatConversationId,
  };
};

export function cancelReply(chatConversationId) {
  return {
    type: CHAT_COMPOSE_REPLY_CANCEL,
    chatConversationId,
  };
};

const fetchChatComposeSuggestionsAccounts = throttle((dispatch, getState, token, chatConversationId) => {
  if (cancelFetchChatComposeSuggestionsAccounts) {
    cancelFetchChatComposeSuggestionsAccounts();
  }

  api(getState).get('/api/v1/accounts/search', {
    cancelToken: new CancelToken(cancel => {
      cancelFetchChatComposeSuggestionsAccounts = cancel;
    }),

    params: {
      q: token.slice(1),
      resolve: false,
      limit: 4,
    },
  }).then(response => {
    dispatch(importFetchedAccounts(response.data));
    dispatch(readyChatComposeSuggestionsAccounts(token, response.data, chatConversationId));
  }).catch(error => {
    if (!isCancel(error)) {
      dispatch(showAlertForError(error));
    }
  });
}, 200, { leading: true, trailing: true });

const fetchChatComposeSuggestionsEmojis = (dispatch, getState, token, chatConversationId) => {
  const results = emojiSearch(token.replace(':', ''), { maxResults: 5 });
  dispatch(readyChatComposeSuggestionsEmojis(token, results, chatConversationId));
};

const fetchChatComposeSuggestionsTags = throttle((dispatch, getState, token, chatConversationId) => {
  if (cancelFetchChatComposeSuggestionsTags) {
    cancelFetchChatComposeSuggestionsTags();
  }

  dispatch(updateSuggestionTags(token, chatConversationId));

  api(getState).get('/api/v2/search', {
    cancelToken: new CancelToken(cancel => {
      cancelFetchChatComposeSuggestionsTags = cancel;
    }),

    params: {
      type: 'hashtags',
      q: token.slice(1),
      resolve: false,
      limit: 4,
      exclude_unreviewed: true,
    },
  }).then(({ data }) => {
    dispatch(readyChatComposeSuggestionsTags(token, data.hashtags, chatConversationId));
  }).catch(error => {
    if (!isCancel(error)) {
      dispatch(showAlertForError(error));
    }
  });
}, 200, { leading: true, trailing: true });

export function fetchChatComposeSuggestions(token, chatConversationId) {
  return (dispatch, getState) => {
    switch (token[0]) {
    case ':':
      fetchChatComposeSuggestionsEmojis(dispatch, getState, token, chatConversationId);
      break;
    case '#':
      fetchChatComposeSuggestionsTags(dispatch, getState, token, chatConversationId);
      break;
    default:
      fetchChatComposeSuggestionsAccounts(dispatch, getState, token, chatConversationId);
      break;
    }
  };
};

export function readyChatComposeSuggestionsEmojis(token, emojis, chatConversationId) {
  return {
    type: CHAT_COMPOSE_SUGGESTIONS_READY,
    token,
    emojis,
    chatConversationId,
  };
};

export function readyChatComposeSuggestionsAccounts(token, accounts, chatConversationId) {
  return {
    type: CHAT_COMPOSE_SUGGESTIONS_READY,
    token,
    accounts,
    chatConversationId,
  };
};

export const readyChatComposeSuggestionsTags = (token, tags, chatConversationId) => ({
  type: CHAT_COMPOSE_SUGGESTIONS_READY,
  token,
  tags,
  chatConversationId,
});

export function selectChatComposeSuggestion(position, token, suggestion, path, chatConversationId) {
  return (dispatch, getState) => {
    let completion, startPosition;

    if (suggestion.type === 'emoji') {
      completion    = suggestion.native || suggestion.colons;
      startPosition = position - 1;

      dispatch(useEmoji(suggestion));
    } else if (suggestion.type === 'hashtag') {
      completion    = `#${suggestion.name}`;
      startPosition = position - 1;
    } else if (suggestion.type === 'account') {
      completion    = getState().getIn(['accounts', suggestion.id, 'acct']);
      startPosition = position;
    }

    dispatch({
      type: CHAT_COMPOSE_SUGGESTION_SELECT,
      position: startPosition,
      token,
      completion,
      path,
      chatConversationId,
    });
  };
};

export function updateSuggestionTags(token, chatConversationId) {
  return {
    type: CHAT_COMPOSE_SUGGESTION_TAGS_UPDATE,
    token,
    chatConversationId,
  };
}

export function uploadChatComposeRequest(chatConversationId) {
  return {
    type: CHAT_COMPOSE_UPLOAD_REQUEST,
    skipLoading: true,
    chatConversationId,
  };
}

export function uploadChatComposeProgress(loaded, total, chatConversationId) {
  return {
    type: CHAT_COMPOSE_UPLOAD_PROGRESS,
    loaded: loaded,
    total: total,
    chatConversationId,
  };
};

export function uploadChatComposeSuccess(media, file, chatConversationId) {
  return {
    type: CHAT_COMPOSE_UPLOAD_SUCCESS,
    media: media,
    file: file,
    skipLoading: true,
    chatConversationId,
  };
};

export function undoUploadChatCompose(media_id, chatConversationId) {
  return {
    type: CHAT_COMPOSE_UPLOAD_UNDO,
    media_id: media_id,
    chatConversationId,
  };
};

export function clearChatComposeSuggestions(chatConversationId) {
  if (cancelFetchChatComposeSuggestionsAccounts) {
    cancelFetchChatComposeSuggestionsAccounts();
  }
  return {
    type: CHAT_COMPOSE_SUGGESTIONS_CLEAR,
    chatConversationId,
  };
};

export function uploadChatCompose(files, conversationId) {
  return function (dispatch, getState) {
    let media, pending;
    media  = getState().getIn(['chat_compose', conversationId, 'media_attachments']);
    pending  = getState().getIn(['chat_compose', conversationId, 'pending_media_attachments']);
    const uploadLimit = 4;
    const progress = new Array(files.length).fill(0);
    let total = Array.from(files).reduce((a, v) => a + v.size, 0);

    if (files.length + media.size + pending > uploadLimit) {
      dispatch(showAlert(undefined, messages.uploadErrorLimit));
      return;
    }

    dispatch(uploadChatComposeRequest(conversationId));

    for (const [i, f] of Array.from(files).entries()) {
      if (media.size + i > 3) break;

      resizeImage(f).then(file => {
        const data = new FormData();
        data.append('file', file);
        // Account for disparity in size of original image and resized data
        total += file.size - f.size;

        return api(getState).post('/api/v2/media', data, {
          onUploadProgress: function({ loaded }){
            progress[i] = loaded;
            dispatch(uploadChatComposeProgress(progress.reduce((a, v) => a + v, 0), total, conversationId));
          },
        }).then(({ status, data }) => {
          // If server-side processing of the media attachment has not completed yet,
          // poll the server until it is, before showing the media attachment as uploaded

          if (status === 200) {
            dispatch(uploadChatComposeSuccess(data, f, conversationId));
          } else if (status === 202) {
            const poll = () => {
              api(getState).get(`/api/v1/media/${data.id}`).then(response => {
                if (response.status === 200) {
                  dispatch(uploadChatComposeSuccess(response.data, f, conversationId));
                } else if (response.status === 206) {
                  setTimeout(() => poll(), 1000);
                }
              }).catch(error => dispatch(uploadComposeFail(error)));
            };

            poll();
          }
        });
      }).catch(error => {
        if (error.response.status === 413) {
          error.response.statusText = 'The maximum video file size you can upload is 24 MB. If you need to upload larger video files, please use Brighteon.com';
        }
        dispatch(uploadComposeFail(error));
      });
    };
  };
};
