import React from 'react';
import PropTypes from 'prop-types';
import Loading from './loading';
import VerifiedIcon from './verified_icon';
import classNames from 'classnames';

class Icon extends React.PureComponent {

  render() {
    const {
      className,
      id,
      size,
    } = this.props;

    if (id === 'logo') {
      return (
        <div className={'d-block px-5 py-5circle bg-navigation-blend-light'}>
          <i className={'gfi gfi-gab-0 px-5 py-5 fill-navigation-brand'} style={{ fontSize: '22px' }} />
        </div>
      );
    } else if (id === 'loading') return <Loading size={size} className={className} />;
    else if (id === 'verified') return <VerifiedIcon size={size} className={className} />;

    const additionalClasses = {};
    additionalClasses.gfi = 1;
    additionalClasses[`gfi-${id}`] = 1;

    // const classes = CX(className, additionalClasses)
    const classes = classNames(className, additionalClasses);

    return <i style={{ fontSize: size }} className={classes} />;

  }

}

Icon.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  minimizeLogo: PropTypes.bool,
};

export default Icon;