import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import NavigationContainer from './navigation_container';
import LeftSideBar from '../../../../brighteon/components/left_side_bar';
import RightSideBar from '../../../../brighteon/components/right_side_bar';
import NewPostModalContainer from '../../../../brighteon/features/ui/containers/new_post_modal_container';
import ModalContainer from '../../ui/containers/modal_container';
import { showComposePopup } from '../../../actions/compose';

class TopContainer extends React.PureComponent {

  static propTypes = {
    onShowCompose: PropTypes.func.isRequired,
    showCompose: PropTypes.bool,
  }

  state = {
    showLeftSideBar: false,
    showRightSideBar: false,
  }

  handleLeftSideBar = (show) => {
    this.setState({ showLeftSideBar: show });
  }

  handleRightSideBar = (show) => {
    this.setState({ showRightSideBar: show });
  }

  handleNewPostModal = () => {
    this.props.onShowCompose(true);
  }

  closeNewPostModal = () => {
    this.props.onShowCompose(false);
  }

  render () {
    return (
      <BrowserRouter basename='/web'>
        <div>
          <NewPostModalContainer show={this.props.showCompose} closeNewPostModal={this.closeNewPostModal} />
          <NavigationContainer onClose={this.onBlur} openLeftSideBar={this.handleLeftSideBar} openRightSideBar={this.handleRightSideBar} />
          <LeftSideBar open={this.state.showLeftSideBar} handleLeftSideBar={this.handleLeftSideBar} onOpenNewPostModal={this.handleNewPostModal} />
          <RightSideBar open={this.state.showRightSideBar} handleRightSideBar={this.handleRightSideBar} />
          <ModalContainer />
        </div>
      </BrowserRouter>
    );
  }

}

const mapStateToProps = state => ({
  showCompose: state.getIn(['compose', 'showPopup']),
});

const mapDispatchToProps = dispatch => ({
  onShowCompose: (show) => dispatch(showComposePopup(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopContainer);
