import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';
import ImmutablePureComponent from 'react-immutable-pure-component';
import { connect } from 'react-redux';
import Avatar from '../../../components/avatar';
import Icon from '../../../../brighteon/components/icon';
import SearchContainer from 'brighteon/features/ui/components/search_container';
import SearchResultsContainer from 'brighteon/features/ui/components/search_results_container';
import horizontalLogo from 'brighteon/../images/brighteon-social/logo_horiz.svg';
import NotificationsStatusBar from '../../notifications_status_bar';
import ChatUnreadCounterIcon from './chat_unread_counter_icon';
import IconWithBadge from '../../../../brighteon/components/icon_with_badge';
import { cleanNotifications, expandNotificationsStatusBar } from '../../../actions/notifications_status_bar';
import { me } from '../../../initial_state';
import { fetchChatConversationUnreadCount } from '../../../actions/chat_conversations';
import { fetchChatConversations } from '../../../actions/chat_conversations';

const getNotifications = createSelector([
  state => ImmutableList(state.getIn(['settings', 'notifications', 'shows']).filter(item => !item).keys()),
  state => state.getIn(['notifications', 'items']),
], (excludedTypes, notifications) => {
  return notifications.filter(item => item !== null)
    .filter(item => item.get('read') !== true)
    .filter(item => !excludedTypes.includes(item.get('type')));
});

const getPendingNotifications = createSelector([
  state => ImmutableList(state.getIn(['settings', 'notifications', 'shows']).filter(item => !item).keys()),
  state => state.getIn(['notifications', 'pendingItems']),
], (excludedTypes, notifications) => {
  return notifications.filter(item => item !== null)
    .filter(item => item.get('read') !== true)
    .filter(item => !excludedTypes.includes(item.get('type')));
});

const getExcludedTypes = createSelector([
  state => state.getIn(['settings', 'notifications', 'shows']),
], (shows) => {
  return ImmutableList(shows.filter(item => !item).keys());
});

const getNotificationsStatusBar = createSelector([
  getExcludedTypes,
  state => state.getIn(['notifications_status_bar', 'items']),
], (excludedTypes, notifications) => {
  // used if user changed the notification settings after loading the notifications from the server
  // otherwise a list of notifications will come pre-filtered from the backend
  // we need to turn it off for FilterBar in order not to block ourselves from seeing a specific category
  return notifications.filter(item => item !== null)
    .filter(item => item.get('read') !== true)
    .filter(item => !excludedTypes.includes(item.get('type')));
});

const mapStateToProps = state => ({
  count: (getNotifications(state).size + getPendingNotifications(state).size) || getNotificationsStatusBar(state).size,
  id: 'bell',
  hasAnnouncements: !state.getIn(['announcements', 'items']).isEmpty(),
  unreadAnnouncements: state.getIn(['announcements', 'items']).count(item => !item.get('read')),
});

const NotificationsCounterIcon = connect(mapStateToProps, null)(IconWithBadge);

class NavigationBar extends ImmutablePureComponent {

  static propTypes = {
    account: ImmutablePropTypes.map,
    onLogout: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    openLeftSideBar: PropTypes.func,
    openRightSideBar: PropTypes.func,
    fetchNotifications: PropTypes.func,
    fetchChatUnread: PropTypes.func,
    hasAnnouncements: PropTypes.bool.isRequired,
    unreadAnnouncements: PropTypes.number.isRequired,
  };

  state = {
    popupVisible: false,
    showLeftMenu: false,
    searchResultShow: true,
  };

  toggleNotificationsPopup = (e) => {
    e.stopPropagation();
    this.setState({ popupVisible: !this.state.popupVisible });
  };

  showLeftMenu = (e) => {
    e.stopPropagation();
    this.props.openLeftSideBar(true);
  };

  showRightMenu = (e) => {
    e.stopPropagation();
    this.props.openRightSideBar(true);
  };

  closeNotificationsPopup = () => {
    this.setState({ popupVisible: false });
  };

  handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setTimeout(() => this.setState({ searchResultShow: false }), 0); // iOS device a tag click does not trigger due to search results hidden early
    }
  }

  handleFocus = () => {
    this.setState({ searchResultShow: true });
  }

  markAsRead = () => {
    if (this.props.count > 0) {
      this.props.cleanNotifications();
    }
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  componentDidMount() {
    this.props.fetchNotifications();
    this.props.fetchChatUnread();
    window.addEventListener('click', this.closeNotificationsPopup.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closeNotificationsPopup);
  }

  render() {
    const avatarStyle = {
      borderRadius: '50%',
      border: '2px solid white',
    };

    return (
      <>
        <div className='navigation-bar'>
          <div className='navigation-bar__inner'>
            <div className='d-flex'>
              <div className='header-menu-logo d-flex'>
                <div className='header-menu'>
                  <div className='icon mr2' onClick={this.showLeftMenu} role='button' tabIndex={0}>
                    {/* <Icon id='bars' fixedWidth /> */}
                    <div className='icon-menu navigation-icon button-icon' />
                  </div>
                </div>
                <div className='head-logo'>
                  <a href='/' >
                    <img src={horizontalLogo} style={{ width: '100%' }} alt='img' />
                  </a>
                </div>
              </div>

              {
                <div className='desktop-container search-container flex-fill pe-auto' onBlur={this.handleBlur}>
                  <SearchContainer handleFocus={this.handleFocus} />

                  <SearchResultsContainer focused={this.state.searchResultShow} />
                </div>
              }

              {me && (<div className='donate-shop-small'>
                <a href='https://donation.brighteon.com' target='_blank' className='demo-button demo-button-a'>Donate</a>
                <a href='https://www.brighteonstore.com' target='_blank' className='demo-button demo-button-a ml3px'>Shop</a>
              </div>)}

              <div className='d-flex'>
                <a href='https://www.brighteon.tv/' target='_blank' className='head-text-link'>Watch</a>
                <a href='https://www.brighteonradio.com/' target='_blank' className='head-text-link'>Listen</a>
                <a href='https://donation.brighteon.com/' target='_blank' className='head-text-link'>Donate</a>
                <a href='https://www.brighteonstore.com/' target='_blank' className='head-text-link'>Shop</a>

                {me && (
                  <>
                    <div className='notification-bell head-icon-link'>
                      <div className='icon mr2' onClick={this.toggleNotificationsPopup} role='button' tabIndex={0}>
                        <NotificationsCounterIcon className='column-link__icon navigation-icon' />
                      </div>

                      {
                        this.state.popupVisible && (
                          <div className='drawer__pager' onClick={this.stopPropagation} role='button' tabIndex={0}>
                            <div className='drawer__inner darker'>
                              <div className='notifications-header'>
                                <div>NOTIFICATIONS</div>
                                <button className='mark_as_read' onClick={this.markAsRead}>
                                  Mark All as Read
                                </button>
                              </div>
                              <NotificationsStatusBar />
                              <span className='all-notifications-link-wrapper'>
                                <a href='/web/notifications'>See All Notifications</a>
                              </span>
                            </div>
                          </div>
                        )
                      }

                    </div>

                    <a href='/web/messages' className='decoration-none head-icon-link messages-link notification-messages'>
                      <div className='icon mr2'>
                        <ChatUnreadCounterIcon className='column-link__icon navigation-icon' />
                      </div>
                    </a>

                    <a href='/settings/preferences' className='decoration-none head-icon-link settings-link'>
                      <div className='icon mr2'>
                        <Icon id='settings' className='navigation-icon' />
                      </div>
                    </a>

                    <div target='_blank' rel='noopener noreferrer' onClick={this.showRightMenu} role='button' tabIndex={0} className='cursor-pointer'>
                      <span style={{ display: 'none' }}>{this.props.account.get('acct')}</span>
                      <Avatar account={this.props.account} size={36} style={avatarStyle} />
                    </div>
                  </>
                )}

                {!me && (
                  <div className='d-flex align-items-center'>
                    <a href='/auth/sign_up' className='demo-button demo-button-a'>Sign Up</a>
                    <a href='/auth/sign_in' className='demo-button demo-button-b ml5px'>Sign In</a>
                  </div>
                )}
              </div>
            </div>

            {
              <div className='mobile-container mobile-search-container' onBlur={this.handleBlur}>
                <SearchContainer handleFocus={this.handleFocus} />

                <SearchResultsContainer focused={this.state.searchResultShow} />
              </div>
            }

            {/*<div className='navigation-bar__profile'>*/}
            {/*<Permalink href={this.props.account.get('url')} to={`/accounts/${this.props.account.get('id')}`}>*/}
            {/*<strong className='navigation-bar__profile-account'>@{this.props.account.get('acct')}</strong>*/}
            {/*</Permalink>*/}

            {/*<a href='/settings/profile' className='navigation-bar__profile-edit'><FormattedMessage id='navigation_bar.edit_profile' defaultMessage='Edit profile' /></a>*/}
            {/*</div>*/}

            {/*<div className='navigation-bar__actions'>*/}
            {/*<IconButton className='close' title='' icon='close' onClick={this.props.onClose} />*/}
            {/*<ActionBar account={this.props.account} onLogout={this.props.onLogout} />*/}
            {/*</div>*/}
          </div>
        </div>
      </>
    );
  }

}

const mapDispatchToProps = (dispatch) => ({
  fetchNotifications() {
    dispatch(expandNotificationsStatusBar());
  },
  fetchChatUnread() {
    dispatch(fetchChatConversations());
    dispatch(fetchChatConversationUnreadCount());
  },
  cleanNotifications() {
    dispatch(cleanNotifications());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
