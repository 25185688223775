import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openModal } from '../../actions/modal';
import { openPopover } from '../../actions/popover';
import {
  POPOVER_CHAT_SETTINGS,
  MODAL_CHAT_CONVERSATION_CREATE,
} from '../../constants';
// import Layout from './layout'
// import Responsive from '../features/ui/util/responsive_component'
// import ResponsiveClassesComponent from '../features/ui/util/responsive_classes_component'
import ChatSettingsSidebar from '../messages/components/chat_settings_sidebar';
import ChatApprovedConversationsSidebar from '../messages/components/chat_approved_conversations_sidebar';
// import FooterBar from '../messages/other_components/footer_bar'
// import DefaultNavigationBar from '../messages/components/navigation_bar/default_navigation_bar'
import ChatNavigationBar from '../messages/components/chat_navigation_bar_xs';
import ChatMessageScrollingList from '../messages/components/chat_message_scrolling_list';
import ChatMessageComposeForm from '../messages/containers/chat_message_compose_form_container';
import ChatConversationRequestApproveBar from '../messages/components/chat_conversation_request_approve_bar';
import ChatBackBar from '../messages/components/chat_back_bar';
import { isMobile } from '../../is_mobile';
import { saveWindowDimensions } from '../../actions/settings';

class MessagesLayout extends React.PureComponent {

  handleOpenSettingsOptionsPopover = () => {
    this.props.onOpenSettingsOptionsPopover();
  }

  onClickAdd = () => {
    this.props.onOpenChatConversationCreateModal();
  }

  componentDidMount = () => {
    this.handleResize();
    window.addEventListener('resize', this.handleResize, false);
  }

  handleResize = () => {
    this.props.onWindowResize();
  }

  render() {
    const {
      children,
      isSettings,
      source,
      currentConversationIsRequest,
      selectedChatConversationId,
      width,
    } = this.props;
    const isXS = isMobile(width);

    if (isXS) {
      if (!selectedChatConversationId && !isSettings) {
        return (
          <div className={'d w-100 min-h-100vh'}>
            <ChatBackBar />
            <main role='main' className={'d w-100 flex-grow-1 bg-white border-bottom-medium1-1 border-color-secondary'}>
              <div className={'d w-100 flex-row pb15px'}>
                {
                  (isSettings || currentConversationIsRequest) &&
                  <ChatSettingsSidebar isXS />
                }
                { !isSettings && !currentConversationIsRequest && <ChatApprovedConversationsSidebar source={source} isXS={isXS} /> }
              </div>
            </main>
          </div>
        );
      } else if (selectedChatConversationId && !isSettings) {
        return (
          <div className={['d', 'w-100', 'min-h-100vh-200px', 'bg-white'].join(' ')}>
            <ChatNavigationBar chatConversationId={selectedChatConversationId} />
            <main role='main' className={['d', 'w-100', 'flex-grow-1'].join(' ')}>
              <ChatMessageScrollingList chatConversationId={selectedChatConversationId} isXS={isXS} />
            </main>
            { !currentConversationIsRequest && <ChatMessageComposeForm chatConversationId={selectedChatConversationId} isXS={isXS} /> }
            { currentConversationIsRequest && <ChatConversationRequestApproveBar chatConversationId={selectedChatConversationId} isXS={isXS} /> }
          </div>
        );
      } else {
        return (
          <div className={'d w-100 min-h-100vh bg-tertiary'}>
            <ChatBackBar />
            <main role='main' className={['d', 'w-100', 'flex-grow-1', 'bg-primary', 'border-bottom-medium1-1', 'borderColorSecondary'].join(' ')}>
              <div className={['d', 'w-100', 'flex-row', 'flex-grow-1', 'mb15px'].join(' ')}>
                {children}
              </div>
            </main>
          </div>
        );
      }
    }

    return (
      <div className='chat-page'>
        <div className='chat-container'>
          <div className='chat-box-wrapper d-flex'>
            {
              (isSettings || currentConversationIsRequest) &&
              <ChatSettingsSidebar />
            }
            {
              !isSettings && !currentConversationIsRequest &&
              <ChatApprovedConversationsSidebar source={source} />
            }
            <div className='chat-messages-container'>
              <div className='d w-100 h-100'>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  const selectedChatConversationId = state.getIn(['chats', 'selectedChatConversationId'], null);
  const currentConversationIsRequest = selectedChatConversationId ? !state.getIn(['chat_conversations', selectedChatConversationId, 'is_approved'], true) : false;

  return {
    selectedChatConversationId,
    currentConversationIsRequest,
    width: state.getIn(['settings', 'window_dimensions', 'width']),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onOpenChatConversationCreateModal() {
    dispatch(openModal(MODAL_CHAT_CONVERSATION_CREATE));
  },
  onOpenSettingsOptionsPopover() {
    dispatch(openPopover(POPOVER_CHAT_SETTINGS));
  },
  onWindowResize() {
    dispatch(saveWindowDimensions());
  },
});

MessagesLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  isSettings: PropTypes.bool,
  showBackBtn: PropTypes.bool,
  source: PropTypes.string,
  onOpenChatConversationCreateModal: PropTypes.func.isRequired,
  onOpenSettingsOptionsPopover: PropTypes.func,
  onWindowResize: PropTypes.func,
  selectedChatConversationId: PropTypes.string,
  currentConversationIsRequest: PropTypes.bool,
  width: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesLayout);