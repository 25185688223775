import api from '../api';

const BRIGHTEON_URL = 'https://www.brighteon.com/api-v3/public/browse?videosType=new&page=1';

export const BRIGHTEON_FEED_FETCH_REQUEST = 'BRIGHTEON_FEED_FETCH_REQUEST';
export const BRIGHTEON_FEED_FETCH_SUCCESS = 'BRIGHTEON_FEED_FETCH_SUCCESS';
export const BRIGHTEON_FEED_FETCH_FAIL    = 'BRIGHTEON_FEED_FETCH_FAIL';

export function fetchVideos() {
  return (dispatch) => {
    dispatch(fetchVideosRequest());

    api().get(BRIGHTEON_URL,
      {
        transformRequest: (data, headers) => {
          delete headers['X-CSRF-Token'];
          delete headers.Authorization;
        },
        crossDomain: true,
      }).then(response => {
      dispatch(fetchVideosSuccess(response.data.videos));
    }).catch(error => {
      dispatch(fetchVideosFail(error));
    });
  };
}

export function fetchVideosRequest() {
  return {
    type: BRIGHTEON_FEED_FETCH_REQUEST,
    skipLoading: true,
  };
}

export function fetchVideosSuccess(videos) {
  return {
    type: BRIGHTEON_FEED_FETCH_SUCCESS,
    videos,
    skipLoading: true,
  };
}

export function fetchVideosFail(error) {
  return {
    type: BRIGHTEON_FEED_FETCH_FAIL,
    error,
    skipLoading: true,
  };
}
