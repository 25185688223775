import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { closePopover } from '../../actions/popover';
import Block from '../../features/messages/other_components/block';
import Button from '../../features/messages/other_components/button';
import Heading from '../../features/messages/other_components/heading';
import Text from '../../features/messages/other_components/text';

class PopoverLayout extends React.PureComponent {

  handleOnClose = () => {
    if (this.props.onClose) {
      return this.props.onClose();
    }
    return this.props.onCloseAlternate(); // because onClose can be missing
  }

  render() {
    const {
      children,
      width,
      isXS,
      title,
    } = this.props;

    if (isXS) {
      return (
        <div className={'d modal px10px pb10px'}>
          <div className={'d bg-white radius-rounded-8px overflow-hidden mb10px'}>
            {
              !!title &&
              <div className={'d flex-row align-items-center justify-content-center border-bottom-medium1-1 borderColorSecondary h-53px px15px'}>
                <Heading size='h2'>
                  {title}
                </Heading>
              </div>
            }
            <div className={'d max-h-80vh radius-rounded-8px overflow-y-scroll'}>
              {children}
            </div>
          </div>

          <Button
            backgroundColor='primary'
            color='primary'
            onClick={this.handleOnClose}
            radiusSmall
          >
            <Text color='inherit' size='large' align='center'>
              Cancel
            </Text>
          </Button>
        </div>
      );
    }

    return (
      <div style={{ width: `${width}px` }} className={'modal'}>
        <Block>
          {children}
        </Block>
      </div>
    );
  }

}

const mapDispatchToProps = () => ({
  onCloseAlternate: () => closePopover(),
});

PopoverLayout.propTypes = {
  children: PropTypes.node,
  width: PropTypes.number,
  isXS: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onCloseAlternate: PropTypes.func,
};

PopoverLayout.defaultProps = {
  width: 250,
};

export default connect(null, mapDispatchToProps)(PopoverLayout);
