import React from 'react';
import PropTypes from 'prop-types';
import DisplayName from './display_name';

const DisplayNameGroup = ({
  accounts,
  maxVisible,
  noLink,
}) => {
  if (!accounts) return null;

  const newMaxVisible = maxVisible || accounts.size;

  const handleAccountClick = (account) => () => {
    if (noLink) {
      return;
    }

    window.open(`/@${account.get('acct')}`, '_blank');
  };

  return (
    <div className={'d w-100 d-flex flex-row align-items-center overflow-hidden text-overflow-ellipsis2 white-space-no-wrap'}>
      {accounts.slice(0, newMaxVisible).map((account, i) => {
        const isLast = i === newMaxVisible - 1;
        return (
          <div
            key={`display-name-group-${i}`}
            className={'d-inline-block decoration-none cursor-pointer'}
            title={account.get('acct')}
            onClick={handleAccountClick(account)}
            role='button'
            tabIndex={0}
          >
            <span className={'text font-weight-600 cPrimary font-size-15px mr2px'}>
              <DisplayName
                account={account}
                noDisplayName
                isInline
                isMultiline
                isGrouped
              />
              {`${ !isLast ? ',' : '' }`}
            </span>
          </div>
        );
      })}
    </div>
  );
};

DisplayNameGroup.propTypes = {
  accounts: PropTypes.any,
  maxVisible: PropTypes.number,
  noLink: PropTypes.bool,
};

export default DisplayNameGroup;