import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePureComponent from 'react-immutable-pure-component';
import api from 'brighteon/api';
import Icon from '../../../../brighteon/components/icon';
import { injectIntl, FormattedMessage } from 'react-intl';

const style = {
  size: 16,
};

const mapStateToProps = (state, { status }) => ({
  relationship: state.getIn(['relationships', status.getIn(['account', 'id'])]),
});

export default @connect(mapStateToProps)
@injectIntl
class StatusPrepend extends ImmutablePureComponent {

  static contextTypes = {
    router: PropTypes.object,
  };

  static propTypes = {
    status: ImmutablePropTypes.map.isRequired,
    intl: PropTypes.object.isRequired,
    onOpenRepliesModal: PropTypes.func.isRequired,
    onOpenReblogsModal: PropTypes.func.isRequired,
    onOpenFavouritesModal: PropTypes.func.isRequired,
  };

  state = {};

  handleOpenRepliesModal = () => {
    const { status } = this.props;
    const count = status.get('replies_count');
    if (count > 0) {
      this.props.onOpenRepliesModal(status);
    }
  }

  handleOpenReblogsModal = () => {
    const { status } = this.props;
    const count = status.get('reblogs_count');
    if (count > 0) {
      this.props.onOpenReblogsModal(status);
    }
  }

  handleOpenFavouritesModal = () => {
    const { status } = this.props;
    const count = status.get('favourites_count');
    if (count > 0) {
      this.props.onOpenFavouritesModal(status);
    }
  }

  handleOpenOtherFollowingFavoritedAccounts = () => {
    const { status } = this.props;

    this.props.onOpenOtherFavoritedAccounts(status);
  };

  handleOpenOtherFollowingRepliedAccounts = () => {
    const { status } = this.props;

    this.props.onOpenOtherRepliedAccounts(status);
  };

  handleOpenOtherFollowingBoostedAccounts = () => {
    const { status } = this.props;

    this.props.onOpenOtherBoostedAccounts(status);
  };

  getRepliedAccountsPreview = () => {
    const { status } = this.props;
    api().get(`/api/v1/statuses/${status.get('id')}/replied_following_accounts_preview`)
      .then(({ data }) => {
        const replies = data;

        this.setState({
          repliedAccounts: replies,
        });
      })
      .catch(err => {
        console.error(err);
      });
  }

  getRepliedFollowingCount = () => {
    const { status } = this.props;
    api().get(`/api/v1/statuses/${status.get('id')}/replied_following_count`)
      .then(({ data }) => {
        this.setState({
          repliedFollowingCount: data,
        });
      })
      .catch(err => {
        console.error(err);
      });
  }

  getFavouritedAccountsPreview = () => {
    const { status } = this.props;
    api().get(`/api/v1/statuses/${status.get('id')}/favorited_following_accounts_preview`)
      .then(({ data }) => {
        const favourites = data;
        this.setState({
          favoritedAccounts: favourites,
        });
      })
      .catch(err => {
        console.error(err);
      });
  }

  getFavoritedFollowingCount = () => {
    const { status } = this.props;
    api().get(`/api/v1/statuses/${status.get('id')}/favorited_following_count`)
      .then(({ data }) => {
        this.setState({
          favoritedFollowingCount: data,
        });
      })
      .catch(err => {
        console.error(err);
      });
  }

  getBoostedAccountsPreview = () => {
    const { status } = this.props;
    api().get(`/api/v1/statuses/${status.get('id')}/boosted_following_accounts_preview`)
      .then(({ data }) => {
        this.setState({
          boostedAccounts: data,
        });
      })
      .catch(err => {
        console.error(err);
      });
  }

  getBoostedFollowingCount = () => {
    const { status } = this.props;
    api().get(`/api/v1/statuses/${status.get('id')}/boosted_following_count`)
      .then(({ data }) => {
        this.setState({
          boostedFollowingCount: data,
        });
      })
      .catch(err => {
        console.error(err);
      });
  }

  componentDidMount() {
    if (this.props.status) {
      this.getRepliedAccountsPreview();
      this.getRepliedFollowingCount();
      this.getFavouritedAccountsPreview();
      this.getFavoritedFollowingCount();
      this.getBoostedAccountsPreview();
      this.getBoostedFollowingCount();
    }
  }

  render() {
    let prepend = '';
    let { status, account } = this.props;
    const repliedAccounts = this.state.repliedAccounts;
    const repliedFollowingCount = this.state.repliedFollowingCount;
    const favoritedAccounts = this.state.favoritedAccounts;
    const favoritedFollowingCount = this.state.favoritedFollowingCount;
    const boostedAccounts = this.state.boostedAccounts;
    const boostedFollowingCount = this.state.boostedFollowingCount;

    const originRepliedPost = (<a
      href={status.get('url')}
      target='_blank'
      className='status__origin-replied-post'
    >
      post
    </a>);

    // Boost prepend
    if (boostedFollowingCount > 0 && boostedAccounts && boostedAccounts.length > 0) {

      const otherBoostedText = (boostedFollowingCount > 3) ?
        (<>
          {' '}
          <span
            className='status__following-accounts__other'
            onClick={this.handleOpenOtherFollowingBoostedAccounts}
            role='button' tabIndex={0}
          >
            and {boostedFollowingCount - 3} others
          </span>
        </>) :
        null;

      const boostedNames = (<>
        {boostedAccounts.slice(0, 3).map((boostedAccount, index) => (
          <React.Fragment key={index}>
            <a onClick={this.handleAccountClick} data-id={boostedAccount.id} href={boostedAccount.url} className='status__display-name muted'>
              <bdi>
                <strong dangerouslySetInnerHTML={{
                  __html: boostedAccount.id === account.get('id') ? 'you' : (boostedAccount.username || boostedAccount.display_name),
                }}
                />
              </bdi>
            </a>
          </React.Fragment>
        )).reduce((prev, curr) => [prev, ', ', curr])}
        {otherBoostedText}
      </>);

      prepend = (<>
        {prepend}
        <div className='status__prepend'>
          <div className='status__prepend-icon-wrapper'><Icon id='retweet' className='status__prepend-icon' size={style.size} /></div>
          <FormattedMessage
            id='status.reblogged_by'
            defaultMessage='{name} boosted'
            values={{
              name: boostedNames,
            }}
          />
        </div>
      </>);

      // account = status.get('account');
      // status = status.get('reblog'); don't understand this code means
    }

    // Replies prepend
    if (repliedFollowingCount && repliedFollowingCount > 0 && repliedAccounts && repliedAccounts.length > 0) {
      const otherRepliesText = (repliedAccounts.length > 3) ?
        (<>
          {' '}
          <span
            className='status__following-accounts__other'
            onClick={this.handleOpenOtherFollowingRepliedAccounts}
            role='button' tabIndex={0}
          >
            and {repliedFollowingCount - 3} others
          </span>
        </>) :
        null;

      const repliesNames = (<>
        {repliedAccounts.slice(0, 3).map((repliedAccount, index) => (
          <React.Fragment key={index}>
            <a onClick={this.handleAccountClick} data-id={repliedAccount.id} href={repliedAccount.url} className='status__display-name muted'>
              <bdi>
                <strong dangerouslySetInnerHTML={{
                  __html: repliedAccount.id === account.get('id') ? 'you' : (repliedAccount.display_name || repliedAccount.username),
                }}
                />
              </bdi>
            </a>
          </React.Fragment>
        )).reduce((prev, curr) => [prev, ', ', curr])}
        {otherRepliesText}
      </>);

      prepend = (<>
        {prepend}
        <div className='status__prepend'>
          <div className='status__prepend-icon-wrapper'><Icon id='reply' className='status__prepend-icon' size={style.size} /></div>
          <FormattedMessage
            id='status.replied_to_with_post'
            defaultMessage='{origin} replied to this {post}'
            values={{
              origin: repliesNames,
              post: originRepliedPost,
            }}
          />
        </div>
      </>);
    }

    // Favourites prepend
    if (favoritedFollowingCount && favoritedFollowingCount > 0 && favoritedAccounts && favoritedAccounts.length > 0) {
      const otherFavouritesText = (favoritedFollowingCount > 3) ?
        (<>
          {' '}
          <span
            className='status__following-accounts__other'
            onClick={this.handleOpenOtherFollowingFavoritedAccounts}
            role='button' tabIndex={0}
          >
            and {favoritedFollowingCount - 3} others
          </span>
        </>) :
        null;

      const favouritesNames = (<>
        {favoritedAccounts.slice(0, 3).map((favoritedAccount, index) => (
          <React.Fragment key={index}>
            <a onClick={this.handleAccountClick} data-id={favoritedAccount.id} href={favoritedAccount.url} className='status__display-name muted'>
              <bdi>
                <strong dangerouslySetInnerHTML={{
                  __html: favoritedAccount.id === account.get('id') ? 'you' : (favoritedAccount.display_name || favoritedAccount.username),
                }}
                />
              </bdi>
            </a>
          </React.Fragment>
        )).reduce((prev, curr) => [prev, ', ', curr])}
        {otherFavouritesText}
      </>);

      prepend = (<>
        {prepend}
        <div className='status__prepend'>
          <div className='status__prepend-icon-wrapper'><Icon id='star' className='status__prepend-icon' size={style.size} /></div>
          <FormattedMessage
            id='status.favourited_to_with_post'
            defaultMessage='{origin} favorited this {post}'
            values={{
              origin: favouritesNames,
              post: originRepliedPost,
            }}
          />
        </div>
      </>);
    }

    return (
      <div>
        {prepend}
      </div>
    );
  }

}
