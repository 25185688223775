import React from 'react';
import PropTypes from 'prop-types';
import { fetchFollowRequests } from 'brighteon/actions/accounts';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import IconWithBadge from '../../../../brighteon/components/icon_with_badge';
import { List as ImmutableList } from 'immutable';
import { FormattedMessage } from 'react-intl';

const mapStateToProps = state => ({
  count: state.getIn(['user_lists', 'follow_requests', 'items'], ImmutableList()).size,
});

export default @withRouter
@connect(mapStateToProps)
class FollowRequestsNavLink extends React.Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired,
    nav: PropTypes.bool,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchFollowRequests());
  }

  render() {
    const { count, nav } = this.props;

    if (count === 0) {
      return null;
    }

    const content = (
      <>
        <IconWithBadge className='column-link__icon' id='invite' count={count} size={24} />
        <div className='ml2 pt1'>
          <FormattedMessage id='navigation_bar.follow_requests' defaultMessage='Follow requests' />
        </div>
      </>
    );

    return nav ?
      (<NavLink className='column-link column-link--transparent d-flex' to='/follow_requests' >{content}</NavLink>) :
      (<a className='column-link column-link--transparent d-flex' href='/web/follow_requests' >{content}</a>);
  }

}
