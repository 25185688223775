import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import Block from '../../features/messages/other_components/block';
import Button from '../../features/messages/other_components/button';
import IconButton from '../../../brighteon/components/icon_button';
import Heading from '../../features/messages/other_components/heading';
import Text from '../../features/messages/other_components/text';

class ConfirmationModal extends React.PureComponent {

  componentDidMount() {
    this.button.focus();
  }

  handleClick = () => {
    this.props.onClose();
    this.props.onConfirm();
  }

  handleCancel = () => {
    const { onClose, onCancel } = this.props;
    onClose();
    if (onCancel) onCancel();
  }

  setRef = (c) => {
    this.button = c;
  }

  render() {
    const {
      title,
      message,
      confirm,
      intl,
    } = this.props;

    return (
      <div className={'modal-root__modal w-430px border-medium1-1 radius-rounded-8px'}>
        <Block>
          <div className={'d'}>
            <div className={'d px15px pb15px'}>

              <div className={'d flex-row align-items-center justify-content-center borderColorSecondary h-53px'}>
                <Heading size='h2'>
                  {title}
                </Heading>
                {
                  <IconButton
                    backgroundColor='none'
                    title={intl.formatMessage(messages.close)}
                    className={'ml-auto circle'}
                    onClick={this.handleCancel}
                    color='secondary'
                    icon='clear'
                    size={25}
                  />
                }
              </div>

              <div className={'d mt10px'}>
                <Text align='left' color='secondary'>
                  {message}
                </Text>

                <div className={'d flex-row mt10px pt10px'}>
                  <Button
                    backgroundColor='brand'
                    color='white'
                    onClick={this.handleClick}
                    ref={this.setRef}
                    className={'bg-blue2 color-white radius-rounded-4px'}
                  >
                    <Text size='medium' weight='bold' align='center' color='white' className='color-white'>
                      {confirm}
                    </Text>
                  </Button>

                  <Button
                    backgroundColor='tertiary'
                    color='primary'
                    onClick={this.handleCancel}
                    className={'mr10px radius-rounded-4px ml5px'}
                  >
                    <Text size='medium' weight='bold' align='center' color='inherit'>
                      <FormattedMessage id='confirmation_modal.cancel' defaultMessage='Cancel' />
                    </Text>
                  </Button>
                </div>

              </div>

            </div>
          </div>
        </Block>
      </div>
    );
  }

}

const messages = defineMessages({
  close: { id: 'lightbox.close', defaultMessage: 'Close' },
});

ConfirmationModal.propTypes = {
  title: PropTypes.any,
  message: PropTypes.any.isRequired,
  confirm: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
};

export default injectIntl(ConfirmationModal);
