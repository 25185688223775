import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePureComponent from 'react-immutable-pure-component';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { deleteChatMessageReaction } from '../../../actions/chat_messages';
import Avatar from '../../../components/avatar';

class ReactionsContainer extends ImmutablePureComponent {

  handleClick = (e) => {
    const reactionId = e.target.getAttribute('data-id');
    this.props.onRemoveReaction(reactionId);
  }
  render() {
    const { reactions, className } = this.props;
    return (
      <div className={classNames('d-flex pos-absolute z4 top-min15px font-size-20px', className)} >
        {
          reactions.map((reaction, i) => {
            return (
              <div className='cursor-pointer d-flex w-29px h-29px bg-white align-items-center text-align-center justify-content-center circle box-shadow-popover mr5px box-shadow-emoticon' key={i} >
                <span onClick={this.handleClick} data-id={reaction.get('id')} role='button' tabIndex={0} className='pos-relative tooltip' >
                  {reaction.get('reaction')}
                  <div className='tooltiphtml pos-absolute bg-medium2 color-white p5px radius-rounded-4px d-flex'>
                    <Avatar account={reaction.get('account')} size={18} />
                    <span className='font-size-12px ml5px'>{reaction.get('account').get('username')}</span>
                  </div>
                </span>
              </div>);
          })
        }
      </div>
    );
  }

}

ReactionsContainer.propTypes = {
  reactions: PropTypes.object,
  onRemove: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onRemoveReaction(reactionId) {
    dispatch(deleteChatMessageReaction(reactionId));
  },
});

export default connect(null, mapDispatchToProps)(ReactionsContainer);
