import React from 'react';
import ImmutablePureComponent from 'react-immutable-pure-component';
import Heading from '../other_components/heading';
import Button from '../other_components/button';

class ChatSettingsHeader extends ImmutablePureComponent {

  render() {

    return (
      <div className={'d w-100 h-60px border-bottom-medium1-1'}>
        <div className={'d flex-row pl15px pr5px py15px'}>
          <Button
            noClasses
            className={'d decoration-none justify-content-center mr5px align-items-center bg-transparent cursor-pointer outline-none'}
            to='/messages'
            color='primary'
            backgroundColor='none'
            icon='arrow-left'
            iconSize={24}
            iconClassName={'mr5px'}
          />
          <Heading size='h1'>
            Chat Settings
          </Heading>
        </div>
      </div>
    );
  }

}

export default ChatSettingsHeader;