import React from 'react';
import PropTypes from 'prop-types';
// import ResponsiveClassesComponent from '../../ui/util/responsive_classes_component'
import ChatSettingsHeader from './chat_settings_header';
import List from '../other_components/list';

class ChatSettingsSidebar extends React.PureComponent {

  render() {
    const { isXS } = this.props;

    return (
      <div className='chat-settings-sidebar'>
        { !isXS && <ChatSettingsHeader /> }
        <List
          items={[
            {
              title: 'Message Requests',
              to: '/messages/requests',
            },
            {
              title: 'Blocked Chat Messengers',
              to: '/messages/blocks',
            },
            {
              title: 'Muted Conversations',
              to: '/messages/muted_conversations',
            },
            {
              title: 'Hidden Conversations',
              to: '/messages/hidden_conversations',
            },
          ]}
        />
      </div>
    );
  }

}

ChatSettingsSidebar.propTypes = {
  isXS: PropTypes.bool,
};

export default ChatSettingsSidebar;