import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import Video from 'brighteon/features/video';
import ImmutablePureComponent from 'react-immutable-pure-component';
import Footer from 'brighteon/features/picture_in_picture/components/footer';
import { getAverageFromBlurhash } from 'brighteon/blurhash';

export default class VideoModal extends ImmutablePureComponent {

  static propTypes = {
    media: ImmutablePropTypes.map.isRequired,
    statusId: PropTypes.string,
    src: PropTypes.string.isRequired,
    options: PropTypes.shape({
      startTime: PropTypes.number,
      autoPlay: PropTypes.bool,
      defaultVolume: PropTypes.number,
    }),
    onClose: PropTypes.func.isRequired,
    onChangeBackgroundColor: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { onChangeBackgroundColor } = this.props;

    const backgroundColor = getAverageFromBlurhash('UlKK{9E1Di00%Lj[j?t6Rjay%Mxu%NofjZof');

    if (backgroundColor) {
      onChangeBackgroundColor(backgroundColor);
    }
  }

  render() {
    const { statusId, onClose } = this.props;
    const options = this.props.options || {};

    return (
      <div className='modal-root__modal video-modal'>
        <div className='video-modal__container'>
          <Video
            preview={options.preview_url || this.props.media.preview_url}
            blurhash={options.blurhash || this.props.media.blurhash}
            src={options.src || this.props.media.src}
            currentTime={options.startTime || this.props.media.startTime}
            autoPlay={options.autoPlay || this.props.media.autoPlay}
            volume={options.defaultVolume || this.props.media.defaultVolume}
            onCloseVideo={onClose}
            detailed
          />
        </div>

        <div className='media-modal__overlay'>
          {statusId && <Footer statusId={statusId} withOpenButton onClose={onClose} />}
        </div>
      </div>
    );
  }

}