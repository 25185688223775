import React from 'react';
import PropTypes from 'prop-types';
import ChatConversationsSearch from './chat_conversations_search';
import ChatConversationsList from './chat_conversations_list';
import ChatConversationRequestsListItem from './chat_conversations_requests_list_item';
import ChatConversationMutedListItem from './chat_conversations_muted_list_item';
import ChatConversationHiddenListItem from './chat_conversations_hidden_list_item';
import ChatConversationBlockedListItem from './chat_conversations_blocked_list_item';
import ChatConversationClearUnreadMessages from './chat_conversation_clear_unread_messages';

class ChatApprovedConversationsSidebar extends React.PureComponent {

  render() {
    const { source, isXS } = this.props;

    let containerClasses = '', wrapperClasses = '';
    if (isXS) {
      containerClasses = 'd w-100 h-100 overflow-y-auto bg-primary';
      wrapperClasses = 'd w-100 brighteon-scrollbar';
    } else {
      containerClasses = 'chat-conversations-sidebar-container w-340px';
      wrapperClasses = 'd w-100 pos-absolute bottom-0 top-60px overflow-y-auto brighteon-scrollbar';
    }

    return (
      <div className={containerClasses}>
        <div className={'d h-100 overflow-hidden w-100 box-shadow-none'}>
          <ChatConversationsSearch isXS={isXS} />
          <div className={wrapperClasses}>
            <ChatConversationClearUnreadMessages />
            <ChatConversationRequestsListItem />
            <ChatConversationMutedListItem />
            <ChatConversationHiddenListItem />
            <ChatConversationBlockedListItem />
            <ChatConversationsList source={source} />
          </div>
        </div>
      </div>
    );
  }

}

ChatApprovedConversationsSidebar.propTypes = {
  source: PropTypes.string,
  isXS: PropTypes.bool,
};

export default ChatApprovedConversationsSidebar;