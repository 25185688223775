import React from 'react';
import BlockHeading from './messages/other_components/block_heading';
import ChatConversationsList from './messages/components/chat_conversations_list';

class ChatConversationRequests extends React.PureComponent {

  render() {
    return (
      <div className={'d w-100 flex-grow-1 overflow-hidden box-shadow-none'}>
        <div className={'d h-60px w-100 px10px py10px border-bottom-medium1-1 borderColorSecondary'}>
          <BlockHeading title={'Message Requests'} />
        </div>
        <div className={'d pos-absolute top-60px left-0 right-0 bottom-0 overflow-y-auto'}>
          <ChatConversationsList source='requested' />
        </div>
      </div>
    );
  }

}

export default ChatConversationRequests;