import {
  Map as ImmutableMap,
  List as ImmutableList,
} from 'immutable';
import {
  CHAT_CONVERSATION_CREATE_SEARCH_ACCOUNTS_SUCCESS,
  CLEAR_CHAT_CONVERSATION_CREATE_SEARCH_ACCOUNTS,
  SET_CHAT_CONVERSATION_SELECTED,
  SET_CHAT_CONVERSATION_SEARCH_VALUE,
  OPEN_STICK_MESSAGE,
  CLOSE_STICK_MESSAGE,
  SET_POST_FOR_CHAT,
  SET_POST_SHARE_SUGGESTIONS_SEARCH,
  SET_POST_SHARE_ACCOUNT_SUGGESTIONS,
  SET_POST_SHARE_CONVERSATION_SUGGESTIONS,
} from '../actions/chats';
import {
  CHAT_CONVERSATION_APPROVED_UNREAD_COUNT_FETCH_SUCCESS,
  CHAT_CONVERSATION_REQUESTED_COUNT_FETCH_SUCCESS,
  CHAT_CONVERSATION_MUTED_COUNT_FETCH_SUCCESS,
  CHAT_CONVERSATION_HIDDEN_COUNT_FETCH_SUCCESS,
  CHAT_CONVERSATION_BLOCKED_COUNT_FETCH_SUCCESS,
  CHAT_CONVERSATION_MARK_READ_FETCH,
  CHAT_CONVERSATION_UNREAD_COUNT_RESET_SUCCESS,
} from '../actions/chat_conversations';
import { CHAT_CONVERSATION_APPROVED_UNREAD_COUNT_INCREMENT } from '../actions/chat_messages';
import { normalizedAccounts } from '../actions/importer/normalizer';

const initialState = ImmutableMap({
  createChatConversationSuggestionIds: ImmutableList(),
  stickedChatConversationIds: ImmutableList(),
  selectedChatConversationId: null,
  chatConversationRequestCount: 0,
  chatConversationHiddenCount: 0,
  chatConversationMutedCount: 0,
  chatConversationBlockedCount: 0,
  chatsUnreadCount: 0,
  searchValue: '',
  chatText: '',
  selectedStatus: ImmutableMap(),
  postShareSearchValue: '',
  postShareAccountSuggestions: ImmutableList([]),
  postShareConversationSuggestions: [],
});

export default function chats(state = initialState, action) {
  switch(action.type) {
  case CHAT_CONVERSATION_CREATE_SEARCH_ACCOUNTS_SUCCESS:
    return state.set('createChatConversationSuggestionIds', ImmutableList(action.accounts.map((item) => item.id)));
  case CLEAR_CHAT_CONVERSATION_CREATE_SEARCH_ACCOUNTS:
    return state.set('createChatConversationSuggestionIds', ImmutableList());
  case SET_CHAT_CONVERSATION_SELECTED:
    return state.set('selectedChatConversationId', action.chatConversationId);
  case CHAT_CONVERSATION_REQUESTED_COUNT_FETCH_SUCCESS:
    return state.set('chatConversationRequestCount', action.count);
  case CHAT_CONVERSATION_HIDDEN_COUNT_FETCH_SUCCESS:
    return state.set('chatConversationHiddenCount', action.count);
  case CHAT_CONVERSATION_MUTED_COUNT_FETCH_SUCCESS:
    return state.set('chatConversationMutedCount', action.count);
  case CHAT_CONVERSATION_BLOCKED_COUNT_FETCH_SUCCESS:
    return state.set('chatConversationBlockedCount', action.count);
  case CHAT_CONVERSATION_APPROVED_UNREAD_COUNT_INCREMENT:
    const currentUnreadCount = state.get('chatsUnreadCount');
    return state.set('chatsUnreadCount', currentUnreadCount + 1);
  case CHAT_CONVERSATION_APPROVED_UNREAD_COUNT_FETCH_SUCCESS:
    return state.set('chatsUnreadCount', action.count);
  case CHAT_CONVERSATION_UNREAD_COUNT_RESET_SUCCESS:
    return state.set('chatsUnreadCount', 0);
  case CHAT_CONVERSATION_MARK_READ_FETCH:
    const chatConversationUnreadCount = action.chatConversation.get('unread_count');
    const totalUnreadCount = state.get('chatsUnreadCount');
    return state.set('chatsUnreadCount', Math.max(totalUnreadCount - chatConversationUnreadCount, 0));
  case SET_CHAT_CONVERSATION_SEARCH_VALUE:
    return state.set('searchValue', action.value);
  case OPEN_STICK_MESSAGE:
    let newSt = state;
    const oldIds = state.get('stickedChatConversationIds');
    const panelWidth = 270;
    const tabWidth = 340;
    const windowWidth = window.innerWidth;
    if (oldIds.toJS().includes(action.value)) {
      return newSt;
    }
    if (windowWidth < panelWidth + tabWidth * (state.get('stickedChatConversationIds').count() + 1)) {
      const firstConversationId = state.get('stickedChatConversationIds').toJS()[0];
      newSt = state.set('stickedChatConversationIds', state.get('stickedChatConversationIds').filter(chatId => chatId !== firstConversationId));
    }
    const newIds = newSt.get('stickedChatConversationIds').concat(action.value);
    return newSt.set('stickedChatConversationIds', newIds);
  case CLOSE_STICK_MESSAGE:
    return state.set('stickedChatConversationIds', state.get('stickedChatConversationIds').filter(chatId => chatId !== action.value));
  case SET_POST_FOR_CHAT:
    return state.set('selectedStatus', action.status);
  case SET_POST_SHARE_SUGGESTIONS_SEARCH:
    return state.set('postShareSearchValue', action.value);
  case SET_POST_SHARE_ACCOUNT_SUGGESTIONS:
    return state.set('postShareAccountSuggestions', normalizedAccounts(action.data));
  case SET_POST_SHARE_CONVERSATION_SUGGESTIONS:
    return state.set('postShareConversationSuggestions', action.data.map(item => item.chat_conversation_id));
  default:
    return state;
  }
}
