import React from 'react';
import classNames from 'classnames';
import CharacterCounter from 'brighteon/features/compose/components/character_counter';
import Button from 'brighteon/components/button';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import AutosuggestTextarea from '../../../components/autosuggest_textarea';
import UploadButtonContainer from '../containers/upload_button_container';
import { defineMessages, injectIntl } from 'react-intl';
import EmojiPickerDropdown from '../containers/emoji_picker_dropdown_container';
import UploadFormContainer from '../../../../brighteon/components/status_with_reply/containers/upload_form_container';
import ImmutablePureComponent from 'react-immutable-pure-component';
import { length } from 'stringz';
import { countableText } from 'brighteon/features/compose/util/counter';
import Icon from 'brighteon/components/icon';
import { me } from '../../../../brighteon/initial_state';

const allowedAroundShortCode = '><\u0085\u0020\u00a0\u1680\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000\u2028\u2029\u0009\u000a\u000b\u000c\u000d';

const messages = defineMessages({
  placeholder: { id: 'compose_form.placeholder', defaultMessage: 'What is on your mind?' },
  spoiler_placeholder: { id: 'compose_form.spoiler_placeholder', defaultMessage: 'Write your warning here' },
  publish: { id: 'compose_form.publish', defaultMessage: 'Post' },
  publishLoud: { id: 'compose_form.publish_loud', defaultMessage: '{publish}' },
});

export default @injectIntl
class ComposeForm extends ImmutablePureComponent {

  static contextTypes = {
    router: PropTypes.object,
  };

  static propTypes = {
    intl: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    suggestions: ImmutablePropTypes.list,
    spoiler: PropTypes.bool,
    privacy: PropTypes.string,
    spoilerText: PropTypes.string,
    focusDate: PropTypes.instanceOf(Date),
    caretPosition: PropTypes.number,
    preselectDate: PropTypes.instanceOf(Date),
    isSubmitting: PropTypes.bool,
    isChangingUpload: PropTypes.bool,
    isUploading: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClearSuggestions: PropTypes.func.isRequired,
    onFetchSuggestions: PropTypes.func.isRequired,
    onSuggestionSelected: PropTypes.func.isRequired,
    onChangeSpoilerText: PropTypes.func.isRequired,
    onPaste: PropTypes.func.isRequired,
    onPickEmoji: PropTypes.func.isRequired,
    closeComposeModal: PropTypes.func.isRequired,
    showSearch: PropTypes.bool,
    anyMedia: PropTypes.bool,
    inReplyTo: PropTypes.any,
    singleColumn: PropTypes.bool,
    ancestor: ImmutablePropTypes.map.isRequired,
    replyCompose: PropTypes.func.isRequired,
    getReplies: PropTypes.func.isRequired,
  };

  static defaultProps = {
    showSearch: false,
  };

  state = {
    jumping: false,
  }

  handleChange = (e) => {
    // this.props.onChange(e.target.value);
    if (!this.props.inReplyTo) {
      this.props.replyCompose(this.props.ancestor);
    }
    if (this.props.inReplyTo === this.props.ancestor.get('id')) {
      this.props.onChange(e.target.value);
    }
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13 && (e.ctrlKey || e.metaKey)) {
      this.handleSubmit();
    }
  }

  handleSubmit = () => {
    if (!me) {
      this._openInteractionDialog('reply');
      return;
    }

    if (this.props.text !== this.autosuggestTextarea.textarea.value) {
      // Something changed the text inside the textarea (e.g. browser extensions like Grammarly)
      // Update the state to match the current text
      this.props.onChange(this.autosuggestTextarea.textarea.value);
    }

    // Submit disabled:
    const { isSubmitting, isChangingUpload, isUploading, anyMedia } = this.props;
    const fulltext = [this.props.spoilerText, countableText(this.props.text)].join('');

    if (isSubmitting || isUploading || isChangingUpload || length(fulltext) > 1500 || (fulltext.length !== 0 && fulltext.trim().length === 0 && !anyMedia)) {
      return;
    }

    this.props.closeComposeModal(); // for only account card dropdown actions and profile page dropdown actions
    this.jumpSubmitButton();
    this.props.onSubmit(this.context.router ? this.context.router.history : null);
  }

  _openInteractionDialog = type => {
    window.open(`/interact/${this.props.ancestor.get('id')}?type=${type}`, 'brighteon-intent', 'width=445,height=600,resizable=no,menubar=no,status=no,scrollbars=yes');
  }

  jumpSubmitButton = () => {
    this.setState({
      jumping: true,
    });
    setTimeout(() => {
      this.setState({
        jumping: false,
      });
    }, 2000);
  }

  onSuggestionsClearRequested = () => {
    this.props.onClearSuggestions();
  }

  onSuggestionsFetchRequested = (token) => {
    this.props.onFetchSuggestions(token);
  }

  onSuggestionSelected = (tokenStart, token, value) => {
    this.props.onSuggestionSelected(tokenStart, token, value, ['text']);
  }

  onSpoilerSuggestionSelected = (tokenStart, token, value) => {
    this.props.onSuggestionSelected(tokenStart, token, value, ['spoiler_text']);
  }

  handleChangeSpoilerText = (e) => {
    this.props.onChangeSpoilerText(e.target.value);
  }

  handleFocus = () => {
    if (this.composeForm) {
      this.composeForm.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
    if (this.props.inReplyTo !== this.props.ancestor.get('id')) {
      this.props.replyCompose(this.props.ancestor);
    }
  }

  componentDidUpdate(prevProps) {
    // This statement does several things:
    // - If we're beginning a reply, and,
    //     - Replying to zero or one users, places the cursor at the end of the textbox.
    //     - Replying to more than one user, selects any usernames past the first;
    //       this provides a convenient shortcut to drop everyone else from the conversation.
    // if (this.props.focusDate !== prevProps.focusDate) {
    //   let selectionEnd, selectionStart;
    //
    //   if (this.props.preselectDate !== prevProps.preselectDate) {
    //     selectionEnd   = this.props.text.length;
    //     selectionStart = this.props.text.search(/\s/) + 1;
    //   } else if (typeof this.props.caretPosition === 'number') {
    //     selectionStart = this.props.caretPosition;
    //     selectionEnd   = this.props.caretPosition;
    //   } else {
    //     selectionEnd   = this.props.text.length;
    //     selectionStart = selectionEnd;
    //   }
    //
    //   this.autosuggestTextarea.textarea.setSelectionRange(selectionStart, selectionEnd);
    //   this.autosuggestTextarea.textarea.focus();
    // } else if(prevProps.isSubmitting && !this.props.isSubmitting) {
    //   this.autosuggestTextarea.textarea.focus();
    // } else if (this.props.spoiler !== prevProps.spoiler) {
    //   if (this.props.spoiler) {
    //     this.spoilerText.input.focus();
    //   } else {
    //     this.autosuggestTextarea.textarea.focus();
    //   }
    // }
    if (prevProps.isSubmitting && !this.props.isSubmitting) {
      this.props.getReplies();
    }
  }

  setAutosuggestTextarea = (c) => {
    this.autosuggestTextarea = c;
    if (this.props.passRefCb) {
      this.props.passRefCb(c);
    }
  }

  setSpoilerText = (c) => {
    this.spoilerText = c;
  }

  setRef = c => {
    this.composeForm = c;
  };

  handleEmojiPick = (data) => {
    const { text } = this.props;
    const position = this.autosuggestTextarea.textarea.selectionStart;
    const needsSpace = data.custom && position > 0 && !allowedAroundShortCode.includes(text[position - 1]);

    this.props.onPickEmoji(position, data, needsSpace);
  }

  render() {
    const { intl, onPaste } = this.props;
    const { jumping } = this.state;
    const disabled = this.props.isSubmitting;
    const text = [this.props.spoilerText, countableText(this.props.text)].join('');
    let publishText = '';

    if (this.props.privacy === 'private' || this.props.privacy === 'direct') {
      publishText = <span className='compose-form__publish-private'><Icon id='lock' /> {intl.formatMessage(messages.publish)}</span>;
    } else {
      publishText = this.props.privacy !== 'unlisted' ? intl.formatMessage(messages.publishLoud, { publish: intl.formatMessage(messages.publish) }) : intl.formatMessage(messages.publish);
    }

    return (
      <div className='compose-form brighteon-reply-compose' ref={this.setRef} >
        <AutosuggestTextarea
          ref={this.setAutosuggestTextarea}
          placeholder='Write a reply'
          disabled={disabled}
          // value={this.props.text}
          value={(this.props.inReplyTo === this.props.ancestor.get('id')) ? this.props.text : ''}
          onChange={this.handleChange}
          suggestions={this.props.suggestions}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          onPaste={onPaste}
          // autoFocus={!showSearch && !isMobile(window.innerWidth)}
          autoFocus={false}
        >
          <div className='compose-form__modifiers'>
            {
              this.props.inReplyTo === this.props.ancestor.get('id') && (<UploadFormContainer />)
            }
          </div>
        </AutosuggestTextarea>

        <div className='compose-form__buttons-wrapper'>
          <div className='compose-form__buttons'>
            <EmojiPickerDropdown
              onPickEmoji={this.handleEmojiPick}
              onFocus={this.handleFocus}
            />
            <UploadButtonContainer
              onFocus={this.handleFocus}
            />
          </div>
          <div className='character-counter__wrapper'>
            <CharacterCounter max={1500} text={(this.props.inReplyTo === this.props.ancestor.get('id')) ? text : ''} />
          </div>
          <div className='compose-form__publish'>
            <div className='compose-form__publish-button-wrapper'>
              <Button className={classNames({ jumping }, 'button-a')} text={publishText} onClick={this.handleSubmit} disabled={!((this.props.inReplyTo === this.props.ancestor.get('id')) ? this.props.text : '')} block />
            </div>
          </div>
        </div>

      </div>
    );
  }

}
