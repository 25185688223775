import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Button from './button';
import classNames from 'classnames';

class BackButton extends React.PureComponent {

  historyBack = () => {
    if (window.history && window.history.length === 1 || this.props.toHome) {
      this.props.history.push('/home');
    } else {
      this.props.history.goBack();
    }
  }

  handleBackClick = () => {
    this.historyBack();
    if (!!this.props.onClick) this.props.onClick();
  }

  render() {
    const {
      className,
      icon,
      iconClassName,
      iconSize,
    } = this.props;

    const classes = classNames(className, {
      'align-items-center': 1,
      'bg-transparent': 1,
      'mr5px': 1,
      'cursor-pointer': 1,
      'outline-none': 1,
      'd': 1,
      'justify-content-center': 1,
    });

    return (
      <Button
        noClasses
        color='primary'
        backgroundColor='none'
        className={classes}
        icon={icon || 'arrow-left'}
        iconSize={iconSize || 36}
        iconClassName={iconClassName || ['mr5px', 'cPrimary'].join(' ')}
        onClick={this.handleBackClick}
      />
    );
  }

}

BackButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  iconSize: PropTypes.number,
  toHome: PropTypes.bool,
  onClick: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }),
};

export default withRouter(BackButton);
