import { supportsPassiveEvents } from 'detect-passive-events';
import { forceSingleColumn } from 'brighteon/initial_state';

const LAYOUT_BREAKPOINT = 630;

export const isMobile = width => width <= LAYOUT_BREAKPOINT;

export const layoutFromWindow = () => {
  if (isMobile(window.innerWidth)) {
    return 'mobile';
  } else if (forceSingleColumn) {
    return 'single-column';
  } else {
    return 'multi-column';
  }
};

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

let userTouching = false;
let listenerOptions = supportsPassiveEvents ? { passive: true } : false;

const touchListener = () => {
  userTouching = true;
  window.removeEventListener('touchstart', touchListener, listenerOptions);
};

window.addEventListener('touchstart', touchListener, listenerOptions);

export const isUserTouching = () => userTouching;

export const isIOS = () => iOS;

export function checkIfAndroid() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return false;
  }

  return /android/i.test(userAgent);
}

export const getWindowDimension = () => {
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  return { width, height };
};
