import React from 'react';
import PropTypes from 'prop-types';
import { Popper } from 'react-popper';
import Text from './text';

class Tooltip extends React.PureComponent {

  render() {
    const { message, targetRef } = this.props;

    return (
      <Popper
        placement='top'
        referenceElement={targetRef}
        strategy='fixed'
      >
        {({ ref, style, placement, arrowProps }) => (
          <div ref={ref} style={style} data-placement={placement} className={['z5', 'mt5px', 'mb5px', 'px5px', 'py5px'].join(' ')}>
            <div ref={arrowProps.ref} style={arrowProps.style} />
            <div data-popover='true' className={['d', 'bgBlackOpaque', 'px10px', 'py5px', 'circle'].join(' ')}>
              <Text color='white' size='small' className={'minW120PX'}>
                {message}
              </Text>
            </div>
          </div>
        )}
      </Popper>
    );
  }

}

Tooltip.propTypes = {
  message: PropTypes.string.isRequired,
  targetRef: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};

export default Tooltip;
