import api from '../api';
import { debounce } from 'lodash';
import { showAlertForError } from './alerts';
import { me } from '../initial_state';
import { getWindowDimension } from '../is_mobile';

export const SETTING_CHANGE = 'SETTING_CHANGE';
export const SETTING_SAVE   = 'SETTING_SAVE';
export const WINDOW_DIMENSION_CHANGE = 'WINDOW_DIMENSION_CHANGE';

export function changeSetting(path, value) {
  return dispatch => {
    dispatch({
      type: SETTING_CHANGE,
      path,
      value,
    });

    dispatch(saveSettings());
  };
};

const debouncedSave = debounce((dispatch, getState) => {
  if (getState().getIn(['settings', 'saved'])) {
    return;
  }

  const data = getState().get('settings').filter((_, path) => path !== 'saved').toJS();

  api().put('/api/web/settings', { data })
    .then(() => dispatch({ type: SETTING_SAVE }))
    .catch(error => dispatch(showAlertForError(error)));

  return;
}, 5000, { trailing: true });

export function saveSettings() {
  if (me) {
    return (dispatch, getState) => debouncedSave(dispatch, getState);
  }
  return 0;
};

export const saveWindowDimensions = () => (dispatch) => {
  const { width, height } = getWindowDimension();

  dispatch({
    type: WINDOW_DIMENSION_CHANGE,
    width,
    height,
  });
};
