import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { shortNumberFormat } from '../../../utils/numbers';
import { fetchChatConversationMutedCount } from '../../../actions/chat_conversations';
import Text from '../other_components/text';
import Icon from '../../../components/icon';

class ChatConversationMutedListItem extends React.PureComponent {

  componentDidMount() {
    this.props.onFetchChatConversationMutedCount();
  }

  render() {
    const { mutedCount } = this.props;

    if (!mutedCount || mutedCount < 1) return null;

    return (
      <NavLink
        className={'d w-100 bg-transparent bg-subtle-onhover border-bottom-medium1-1 decoration-none outline-none cursor-pointer'}
        to='/messages/muted_conversations'
      >
        <div className={'d px15px py15px align-items-center flex-row color-black'}>
          <Text size='medium'>Muted Conversations</Text>
          <Text size='medium' className='ml-auto mr15px' color='black'>
            {shortNumberFormat(mutedCount)}
          </Text>
          <Icon id='angle-right' size='10px' className={''} />
        </div>
      </NavLink>
    );
  }

}


const mapStateToProps = (state) => ({
  mutedCount: state.getIn(['chats', 'chatConversationMutedCount'], 0),
});

const mapDispatchToProps = (dispatch) => ({
  onFetchChatConversationMutedCount: () => dispatch(fetchChatConversationMutedCount()),
});

ChatConversationMutedListItem.propTypes = {
  mutedCount: PropTypes.number,
  onFetchChatConversationMutedCount: PropTypes.func.isRequired,
};

ChatConversationMutedListItem.defaultProps = {
  mutedCount: 0,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatConversationMutedListItem);
