import debounce from 'lodash.debounce';
import { List as ImmutableList } from 'immutable';
import api from '../api';
import { importFetchedAccounts } from './importer';
import { me } from '../initial_state';
import { sendChatMessage, sendChatMessageToAccount } from './chat_messages';
import { searchApprovedChatConversationsSuccess } from './chat_conversations';

export const CHAT_CONVERSATION_CREATE_SEARCH_ACCOUNTS_SUCCESS = 'CHAT_CONVERSATION_CREATE_SEARCH_ACCOUNTS_SUCCESS';

export const CLEAR_CHAT_CONVERSATION_CREATE_SEARCH_ACCOUNTS = 'CLEAR_CHAT_CONVERSATION_CREATE_SEARCH_ACCOUNTS';

export const SET_CHAT_CONVERSATION_SELECTED = 'SET_CHAT_CONVERSATION_SELECTED';

export const SET_CHAT_CONVERSATION_SEARCH_VALUE = 'SET_CHAT_CONVERSATION_SEARCH_VALUE';

export const OPEN_STICK_MESSAGE  = 'OPEN_STICK_MESSAGE';

export const CLOSE_STICK_MESSAGE = 'CLOSE_STICK_MESSAGE';

export const SET_POST_FOR_CHAT = 'SET_POST_FOR_CHAT';

export const SET_POST_SHARE_SUGGESTIONS_SEARCH = 'SET_POST_SHARE_SUGGESTIONS_SEARCH';

export const SET_POST_SHARE_ACCOUNT_SUGGESTIONS = 'SET_POST_SHARE_ACCOUNT_SUGGESTIONS';

export const SET_POST_SHARE_CONVERSATION_SUGGESTIONS = 'SET_POST_SHARE_CONVERSATION_SUGGESTIONS';
/**
 *
 */
export const fetchChatConversationAccountSuggestions = (query) => (dispatch, getState) => {
  if (!query || !me) return;
  debouncedFetchChatConversationAccountSuggestions(query, dispatch, getState);
};

export const debouncedFetchChatConversationAccountSuggestions = debounce((query, dispatch, getState) => {
  if (!query) return;

  api(getState).get('/api/v1/accounts/search', {
    params: {
      q: query,
      resolve: false,
      following: true,
      limit: 6,
      chat: true,
    },
  }).then((response) => {
    dispatch(importFetchedAccounts(response.data));
    dispatch(fetchChatConversationAccountSuggestionsSuccess(response.data));
  }).catch(error => console.warn(error));
}, 650, { leading: true });

const fetchChatConversationAccountSuggestionsSuccess = (accounts) => ({
  type: CHAT_CONVERSATION_CREATE_SEARCH_ACCOUNTS_SUCCESS,
  accounts,
});

/**
 *
 */
export const clearChatConversationAccountSuggestions = () => (dispatch) => {
  dispatch({ type: CLEAR_CHAT_CONVERSATION_CREATE_SEARCH_ACCOUNTS });
};

/**
 *
 */
export const setChatConversationSelected = (chatConversationId) => (dispatch) => {
  dispatch({
    type: SET_CHAT_CONVERSATION_SELECTED,
    chatConversationId,
  });
};

/**
 *
 */
export const onChangeSearch = (value) => (dispatch) => {
  dispatch({
    type: SET_CHAT_CONVERSATION_SEARCH_VALUE,
    value,
  });
};

export const openStickMessage = (value) => (dispatch) => {
  dispatch({
    type: OPEN_STICK_MESSAGE,
    value,
  });
};

export const closeStickMessage = (value) => (dispatch) => {
  dispatch({
    type: CLOSE_STICK_MESSAGE,
    value,
  });
};

export const setPostForChat = (status) => {
  return {
    type: SET_POST_FOR_CHAT,
    status,
  };
};

export const sendPostToChatConversation = (chatConversationId) => (dispatch, getState) => {
  const statusId = getState().getIn(['chats', 'selectedStatus', 'id']);
  dispatch(sendChatMessage('', chatConversationId, '', ImmutableList(), statusId));
};

export const sendPostToAccount = (accountId) => (dispatch, getState) => {
  const statusId = getState().getIn(['chats', 'selectedStatus', 'id']);
  dispatch(sendChatMessageToAccount(accountId, statusId));
};

export const changePostShareSuggestionsSearch = (value) => (dispatch) => {
  dispatch(setPostShareSearchValue(value));

  dispatch(fetchAccountSuggestions(value));
  dispatch(fetchConversationSuggestions(value));
};

export const fetchAccountSuggestions = value => (dispatch, getState) => {
  api(getState).get('/api/v1/accounts/relationships/mutual_contacts', {
    params: { q: value },
  }).then(({ data }) => {
    dispatch(setPostShareAccountSuggestions(data));
  }).catch(console.warn);
};

export const fetchConversationSuggestions = value => (dispatch, getState) => {
  api(getState).get('/api/v1/chat_conversations/search_conversations', {
    params: { q: value },
  }).then(({ data }) => {
    dispatch(searchApprovedChatConversationsSuccess(data));
    dispatch(setPostShareConversationSuggestions(data));
  }).catch(console.warn);
};

export const setPostShareSearchValue = value => {
  return {
    type: SET_POST_SHARE_SUGGESTIONS_SEARCH,
    value,
  };
};

export const setPostShareAccountSuggestions = data => {
  return {
    type: SET_POST_SHARE_ACCOUNT_SUGGESTIONS,
    data,
  };
};

export const setPostShareConversationSuggestions = data => {
  return {
    type: SET_POST_SHARE_CONVERSATION_SUGGESTIONS,
    data,
  };
};
