import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-mini';
import ImmutablePureComponent from 'react-immutable-pure-component';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { openPopover } from '../../../actions/popover';
import { sendChatMessageReaction } from '../../../actions/chat_messages';
import {
  POPOVER_CHAT_MESSAGE_OPTIONS,
} from '../../../constants';
import { me } from '../../../initial_state';
// import Input from '../../../components/input'
import Icon from '../../../components/icon';
import Avatar from '../../../components/avatar';
import Text from '../other_components/text';
// import DotTextSeperator from '../../../components/dot_text_seperator'
import { makeGetChatMessage } from '../../../selectors';
import IconButton from '../../../../brighteon/components/icon_button';
import EmojiPickerDropdown from '../../../components/status_with_reply/containers/emoji_picker_dropdown_container';
import ReactionsContainer from '../other_components/reactions_container';
import BrighteonIcon from '../../../../brighteon/components/icon';
import Bundle from '../../ui/components/bundle';
import { MediaGallery, Video, Audio } from '../../ui/util/async-components';
import { openModal } from '../../../actions/modal';
import Card from '../../status/components/card';

class ChatMessageItem extends ImmutablePureComponent {

  state = {
    hovering: false,
    isNewDay: false,
    isCloseToMyLast: false,
    isExpired: false,
  }

  componentDidMount() {
    const {
      lastChatMessageSameSender,
      lastChatMessageDate,
      chatMessage,
    } = this.props;
    if (lastChatMessageDate && chatMessage) {
      const createdAt = chatMessage.get('created_at');
      const isNewDay = moment(createdAt).format('L') !== moment(lastChatMessageDate).format('L');
      const isCloseToMyLast = moment(lastChatMessageDate).diff(createdAt, 'minutes') < 60 && lastChatMessageSameSender && !isNewDay;
      this.setState({
        isNewDay,
        isCloseToMyLast,
      });
    }

    this._scheduleNextUpdate();
  }

  componentWillUnmount() {
    clearTimeout(this._timer);
  }

  _scheduleNextUpdate() {
    const { chatMessage } = this.props;
    const { isExpired } = this.state;
    if (!chatMessage || isExpired) return;

    const expirationDate = chatMessage.get('expires_at');
    if (!expirationDate) return;

    const msUntilExpiration = moment(expirationDate).valueOf() - moment().valueOf();
    this._timer = setTimeout(() => {
      this.setState({ isExpired: true });
    }, msUntilExpiration);
  }

  handleOnMouseEnter = () => {
    this.setState({ isHovering: true });
  }

  handleOnMouseLeave = () => {
    this.setState({ isHovering: false });
  }

  handleMoreClick = () => {
    this.props.onOpenChatMessageOptionsPopover(this.props.chatMessageId, this.deleteBtnRef, this.props.chatConversationId);
  }

  setDeleteBtnRef = (c) => {
    this.deleteBtnRef = c;
  }

  handleFocus = () => {
  }

  handleEmojiPick = (data) => {
    this.props.onReactMessage(this.props.chatMessageId, data);
    // const { text }     = this.props;
    // const position     = this.autosuggestTextarea.textarea.selectionStart;
    // const needsSpace   = data.custom && position > 0 && !allowedAroundShortCode.includes(text[position - 1]);

    // this.props.onPickEmoji(position, data, needsSpace);
  }

  handleOpenMedia = (media, index) => {
    this.props.onOpenMedia(this.props.chatMessage.get('id'), media, index);
  }

  handleStatusClick = (status) => (e) => {
    const url = status.get('url');
    window.open(url, '_blank');
    e.preventDefault();
  }

  mediaHtml = (mediaAttachments) => {
    let media = '';
    if (mediaAttachments.getIn([0, 'type']) === 'audio') {
      const attachment = mediaAttachments.get(0);

      media = (
        <Bundle fetchComponent={Audio} loading={this.renderLoadingAudioPlayer} >
          {Component => (
            <Component
              src={attachment.get('url')}
              alt={attachment.get('description')}
              poster={attachment.get('preview_url')}
              backgroundColor={attachment.getIn(['meta', 'colors', 'background'])}
              foregroundColor={attachment.getIn(['meta', 'colors', 'foreground'])}
              accentColor={attachment.getIn(['meta', 'colors', 'accent'])}
              duration={attachment.getIn(['meta', 'original', 'duration'], 0)}
              width={this.props.cachedMediaWidth}
              height={110}
              cacheWidth={this.props.cacheMediaWidth}
              deployPictureInPicture={undefined}
            />
          )}
        </Bundle>
      );
    } else if (mediaAttachments.getIn([0, 'type']) === 'video') {
      const attachment = mediaAttachments.get(0);

      media = (
        <Bundle fetchComponent={Video} loading={this.renderLoadingVideoPlayer} >
          {Component => (
            <Component
              preview={attachment.get('preview_url')}
              frameRate={attachment.getIn(['meta', 'original', 'frame_rate'])}
              blurhash={attachment.get('blurhash')}
              src={attachment.get('url')}
              alt={attachment.get('description')}
              width={this.props.cachedMediaWidth}
              height={110}
              inline
              sensitive={false}
              onOpenVideo={this.handleOpenVideo}
              cacheWidth={this.props.cacheMediaWidth}
              deployPictureInPicture={undefined}
              visible
            />
          )}
        </Bundle>
      );
    } else {
      media = (
        <Bundle fetchComponent={MediaGallery} loading={this.renderLoadingMediaGallery}>
          {Component => (
            <Component
              media={mediaAttachments}
              sensitive={false}
              height={110}
              onOpenMedia={this.handleOpenMedia}
              cacheWidth={this.props.cacheMediaWidth}
              defaultWidth={this.props.cachedMediaWidth}
              visible
              noMediaHidden
            />
          )}
        </Bundle>
      );
    }

    return media;
  }

  render() {
    const { chatMessage, isHidden, lastChatMessageDate } = this.props;
    const { isHovering, isNewDay, isExpired } = this.state;

    if (!chatMessage || isExpired) return <div />;

    const mediaAttachments = chatMessage.get('media_attachments');

    const account = chatMessage.get('account');
    if (!account) return <div />;

    //If blocked or muted, hide
    const blocks = !!me ? localStorage.getItem('blocks') : '';
    const mutes = !!me ? localStorage.getItem('mutes') : '';
    const blockedby = !!me ? localStorage.getItem('blockedby') : '';
    if (!!me && (
      (blockedby && blockedby.split(',').includes(account.get('id'))) ||
      (blocks && blocks.split(',').includes(account.get('id'))) ||
      (mutes && mutes.split(',').includes(account.get('id'))))
    ) {
      return null;
    }

    const content = { __html: chatMessage.get('text_html') };
    const alt = account.get('id', null) === me;
    const createdAt = chatMessage.get('created_at');
    const originMessage = chatMessage.get('origin_message');
    let originMessageHtml = '';
    if (originMessage) {
      const originMessageAccount = originMessage.get('account');
      const originMessageContent = { __html: originMessage.get('text_html') };
      originMessageHtml = (
        <div className='d-flex border-bottom-medium1-1 mb5px pb5px'>
          <div>
            <BrighteonIcon id='reply-white' className='reply-white' size={24} />
          </div>
          <div className='ml3px'>
            <span className='font-weight-bold'>{originMessageAccount.get('username')}</span>
            <div className='font-style-italic' dangerouslySetInnerHTML={originMessageContent} />
          </div>
        </div>
      );
    }

    if (isHidden) {
      return (
        <React.Fragment>
          {account.get('display_name')}
          <div dangerouslySetInnerHTML={content} />
        </React.Fragment>
      );
    }

    const messageContainerClasses = classNames({
      'd': 1,
      'flex-row': !alt,
      'flex-row-reverse': alt,
      'pb5px': 1,
    });

    const messageInnerContainerClasses = classNames({
      'd': 1,
      'p15px': 1,
      'max-w-80pc-30px': 1,
      'chat-bg-tertiary': alt,
      'chat-bg-secondary': !alt,
      'radius-rounded-4px': 1,
      'ml10px': 1,
      'mr10px': 1,
    });

    const lowerContainerClasses = classNames({
      'd': 1,
      'pt10px': 1,
      'pos-absolute': 1,
      'bottom-0': 1,
      'right-0': alt,
      'left-0': !alt,
      'd-none': !isHovering,
      'pl50px': !alt,
      'pr50px': alt,
    });

    const buttonContainerClasses = classNames({
      'd': 1,
      'flex-row': 1,
      'd-none': !isHovering,
      'align-items-center': 1,
    });

    const expirationDate = chatMessage.get('expires_at');
    let timeUntilExpiration;
    if (!!expirationDate) {
      timeUntilExpiration = moment(expirationDate).fromNow();
    }

    const reactions = chatMessage.get('reactions');

    let messageContent = '';
    if (chatMessage.get('status')) {
      const status = chatMessage.get('status');
      if (status.get('card')) {
        messageContent = (
          <div onClick={this.handleStatusClick(status)} role='button' tabIndex={0}>
            <Card
              onOpenMedia={this.handleOpenMedia}
              card={status.get('card')}
              defaultWidth={200}
            />
          </div>
        );
      } else if (status.get('media_attachments').size > 0) {
        const content = { __html: status.get('content') };
        messageContent = (
          <div onClick={this.handleStatusClick(status)} className='radius-rounded-4px border-color-white border-1px border-solid' role='button' tabIndex={0}>
            <div>
              <img src={status.getIn(['media_attachments', 0, 'preview_url'])} alt='empty' className='max-w-100pc' />
            </div>
            <div className='p3px'>
              <div dangerouslySetInnerHTML={content} />
            </div>
          </div>
        );
      } else {
        const content = { __html: status.get('content') };
        messageContent = (
          <div onClick={this.handleStatusClick(status)} className='radius-rounded-4px border-color-white border-1px border-solid' role='button' tabIndex={0}>
            <div className='p5px' dangerouslySetInnerHTML={content} />
          </div>
        );
        // messageContent = <StatusContent status={status} onClick={this.handleStatusClick(status)} expanded={!status.get('hidden')} />;
      }
    } else {
      messageContent = (
        <>
          {mediaAttachments.size > 0 && this.mediaHtml(mediaAttachments)}
          {originMessageHtml}
          <div className='chat-message overflow-wrap-break-word' dangerouslySetInnerHTML={content} />
        </>
      );
    }

    return (
      <div
        className={'d w-100 pb10px'}
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
      >
        {
          !!lastChatMessageDate && isNewDay &&
          <Text color='secondary' size='small' align='center' className={'d py10px'}>
            {moment(createdAt).format('lll')}
          </Text>
        }

        <div className={'d pb15px'}>
          <div className={messageContainerClasses}>
            { reactions.size > 0 && <ReactionsContainer className={alt ? 'right-50px' : 'left-50px'} reactions={reactions} /> }
            <a href={chatMessage.getIn(['account', 'url'])} target='_blank'>
              <Avatar account={chatMessage.get('account')} size={38} />
            </a>
            <div className={messageInnerContainerClasses}>
              {messageContent}
            </div>
            <div className={buttonContainerClasses}>
              <IconButton
                buttonRef={this.setDeleteBtnRef}
                onClick={this.handleMoreClick}
                color='tertiary'
                backgroundColor='none'
                icon='ellipsis-h'
                iconSize='18px'
                className='circle'
                title=''
              />
              <EmojiPickerDropdown
                onPickEmoji={this.handleEmojiPick}
                onFocus={this.handleFocus}
              />
            </div>
          </div>
          <div className={lowerContainerClasses}>
            <Text size='extraSmall' color='medium2' align={alt ? 'right' : 'left'}>
              {moment(createdAt).format('lll')}

              {
                !!expirationDate &&
                <React.Fragment>
                  {/* <DotTextSeperator /> */}
                  <Text size='extraSmall' color='tertiary' className={'ml5'}>Expires {timeUntilExpiration}</Text>
                  <Icon id='stopwatch' size='11px' className={' '} />
                </React.Fragment>
              }
            </Text>
          </div>
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state, { lastChatMessageId, chatMessageId }) => ({
  chatMessage: makeGetChatMessage()(state, { id: chatMessageId }),
  lastChatMessageDate: lastChatMessageId ? state.getIn(['chat_messages', `${lastChatMessageId}`, 'created_at'], null) : null,
  lastChatMessageSameSender: lastChatMessageId ? state.getIn(['chat_messages', `${lastChatMessageId}`, 'from_account_id'], null) === state.getIn(['chat_messages', `${chatMessageId}`, 'from_account_id'], null) : false,
});

const mapDispatchToProps = (dispatch) => ({
  onOpenChatMessageOptionsPopover(chatMessageId, targetRef, chatConversationId) {
    dispatch(openPopover(POPOVER_CHAT_MESSAGE_OPTIONS, {
      targetRef,
      chatMessageId,
      chatConversationId,
      position: 'top',
    }));
  },
  onReactMessage(chatMessageId, emoji) {
    dispatch(sendChatMessageReaction(chatMessageId, emoji.native));
  },
  onOpenMedia (chatMessageId, media, index) {
    dispatch(openModal('MEDIA', { chatMessageId, media, index }));
  },
});

ChatMessageItem.propTypes = {
  lastChatMessageId: PropTypes.string,
  lastChatMessageDate: PropTypes.string,
  lastChatMessageSameSender: PropTypes.bool,
  chatMessageId: PropTypes.string.isRequired,
  chatConversationId: PropTypes.string.isRequired,
  chatMessage: ImmutablePropTypes.map,
  isHidden: PropTypes.bool,
  alt: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatMessageItem);
