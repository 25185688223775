import api, { getLinks } from '../api';
import { fetchRelationships } from './accounts';
import {
  importFetchedAccounts,
  importFetchedStatuses,
} from './importer';
import { submitMarkers } from './markers';
import { defineMessages } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { usePendingItems as preferPendingItems } from 'brighteon/initial_state';
import compareId from 'brighteon/compare_id';
import { requestNotificationPermission } from '../utils/notifications';
import { readAllNotificationsSuccess } from './notifications';

export const NOTIFICATIONS_STATUS_BAR_UPDATE = 'NOTIFICATIONS_STATUS_BAR_UPDATE';
export const NOTIFICATIONS_STATUS_BAR_UPDATE_NOOP = 'NOTIFICATIONS_STATUS_BAR_UPDATE_NOOP';

export const NOTIFICATIONS_STATUS_BAR_EXPAND_REQUEST = 'NOTIFICATIONS_STATUS_BAR_EXPAND_REQUEST';
export const NOTIFICATIONS_STATUS_BAR_EXPAND_SUCCESS = 'NOTIFICATIONS_STATUS_BAR_EXPAND_SUCCESS';
export const NOTIFICATIONS_STATUS_BAR_EXPAND_FAIL = 'NOTIFICATIONS_STATUS_BAR_EXPAND_FAIL';

export const NOTIFICATIONS_STATUS_BAR_FILTER_SET = 'NOTIFICATIONS_STATUS_BAR_FILTER_SET';

export const NOTIFICATIONS_STATUS_BAR_CLEAR = 'NOTIFICATIONS_STATUS_BAR_CLEAR';
export const NOTIFICATIONS_STATUS_BAR_SCROLL_TOP = 'NOTIFICATIONS_STATUS_BAR_SCROLL_TOP';
export const NOTIFICATIONS_STATUS_BAR_LOAD_PENDING = 'NOTIFICATIONS_STATUS_BAR_LOAD_PENDING';

export const NOTIFICATIONS_STATUS_BAR_MOUNT = 'NOTIFICATIONS_STATUS_BAR_MOUNT';
export const NOTIFICATIONS_STATUS_BAR_UNMOUNT = 'NOTIFICATIONS_STATUS_BAR_UNMOUNT';


export const NOTIFICATIONS_STATUS_BAR_MARK_AS_READ = 'NOTIFICATIONS_STATUS_BAR_MARK_AS_READ';

export const NOTIFICATIONS_STATUS_BAR_SET_BROWSER_SUPPORT = 'NOTIFICATIONS_STATUS_BAR_SET_BROWSER_SUPPORT';
export const NOTIFICATIONS_STATUS_BAR_SET_BROWSER_PERMISSION = 'NOTIFICATIONS_STATUS_BAR_SET_BROWSER_PERMISSION';

defineMessages({
  mention: { id: 'notification.mention', defaultMessage: '{name} mentioned you' },
  group: { id: 'notifications.group', defaultMessage: '{count} notifications' },
});

const fetchRelatedRelationships = (dispatch, notifications) => {
  const accountIds = notifications.filter(item => item.type === 'follow').map(item => item.account.id);

  if (accountIds.length > 0) {
    dispatch(fetchRelationships(accountIds));
  }
};

export const loadPending = () => ({
  type: NOTIFICATIONS_STATUS_BAR_LOAD_PENDING,
});

const excludeTypesFromSettings = state => state.getIn(['settings', 'notifications', 'shows']).filter(enabled => !enabled).keySeq().toJS();

const noOp = () => { };

export function expandNotificationsStatusBar({ maxId } = {}, done = noOp) {
  return (dispatch, getState) => {
    const notifications = getState().get('notifications_status_bar');
    const isLoadingMore = !!maxId;

    if (notifications.get('isLoading')) {
      done();
      return;
    }

    const params = {
      max_id: maxId,
      exclude_types: excludeTypesFromSettings(getState()),
    };

    if (!params.max_id && (notifications.get('items', ImmutableList()).size + notifications.get('pendingItems', ImmutableList()).size) > 0) {
      const a = notifications.getIn(['pendingItems', 0, 'id']);
      const b = notifications.getIn(['items', 0, 'id']);

      if (a && b && compareId(a, b) > 0) {
        params.since_id = a;
      } else {
        params.since_id = b || a;
      }
    }

    const isLoadingRecent = !!params.since_id;

    dispatch(expandNotificationsStatusBarRequest(isLoadingMore));


    api(getState).get('/api/v1/notifications', { params }).then(response => {
      const next = getLinks(response).refs.find(link => link.rel === 'next');

      dispatch(importFetchedAccounts(response.data.map(item => item.account)));
      dispatch(importFetchedStatuses(response.data.map(item => item.status).filter(status => !!status)));

      dispatch(expandNotificationsStatusBarSuccess(response.data, next ? next.uri : null, isLoadingMore, isLoadingRecent, isLoadingRecent && preferPendingItems));
      fetchRelatedRelationships(dispatch, response.data);
      dispatch(submitMarkers());
    }).catch(error => {
      dispatch(expandNotificationsStatusBarFail(error, isLoadingMore));
    }).finally(() => {
      done();
    });
  };
};

export function expandNotificationsStatusBarRequest(isLoadingMore) {
  return {
    type: NOTIFICATIONS_STATUS_BAR_EXPAND_REQUEST,
    skipLoading: !isLoadingMore,
  };
};

export function expandNotificationsStatusBarSuccess(notifications, next, isLoadingMore, isLoadingRecent, usePendingItems) {
  return {
    type: NOTIFICATIONS_STATUS_BAR_EXPAND_SUCCESS,
    notifications,
    next,
    isLoadingRecent: isLoadingRecent,
    usePendingItems,
    skipLoading: !isLoadingMore,
  };
};

export function expandNotificationsStatusBarFail(error, isLoadingMore) {
  return {
    type: NOTIFICATIONS_STATUS_BAR_EXPAND_FAIL,
    error,
    skipLoading: !isLoadingMore,
    skipAlert: !isLoadingMore,
  };
};

export function clearNotifications() {
  return (dispatch, getState) => {
    dispatch({
      type: NOTIFICATIONS_STATUS_BAR_CLEAR,
    });

    api(getState).post('/api/v1/notifications/clear');
  };
};

export function cleanNotifications() {
  return (dispatch, getState) => {
    api(getState).post('/api/v1/notifications/mark_as_read').then(response => {
      dispatch(expandNotificationsStatusBarSuccess(response.data, null, false, false, false));
      dispatch(readAllNotificationsSuccess());
    }).catch(console.warn);
  };
};

export function scrollTopNotifications(top) {
  return {
    type: NOTIFICATIONS_STATUS_BAR_SCROLL_TOP,
    top,
  };
};


export const mountNotifications = () => ({
  type: NOTIFICATIONS_STATUS_BAR_MOUNT,
});

export const unmountNotifications = () => ({
  type: NOTIFICATIONS_STATUS_BAR_UNMOUNT,
});


export const markNotificationsAsRead = () => ({
  type: NOTIFICATIONS_STATUS_BAR_MARK_AS_READ,
});

// Browser support
export function setupBrowserNotifications() {
  return dispatch => {
    dispatch(setBrowserSupport('Notification' in window));
    if ('Notification' in window) {
      dispatch(setBrowserPermission(Notification.permission));
    }

    if ('Notification' in window && 'permissions' in navigator) {
      navigator.permissions.query({ name: 'notifications' }).then((status) => {
        status.onchange = () => dispatch(setBrowserPermission(Notification.permission));
      }).catch(console.warn);
    }
  };
}

export function requestBrowserPermission(callback = noOp) {
  return dispatch => {
    requestNotificationPermission((permission) => {
      dispatch(setBrowserPermission(permission));
      callback(permission);
    });
  };
};

export function setBrowserSupport(value) {
  return {
    type: NOTIFICATIONS_STATUS_BAR_SET_BROWSER_SUPPORT,
    value,
  };
}

export function setBrowserPermission(value) {
  return {
    type: NOTIFICATIONS_STATUS_BAR_SET_BROWSER_PERMISSION,
    value,
  };
}
