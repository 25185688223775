import { connect } from 'react-redux';
import IconWithBadge from '../../../../brighteon/components/icon_with_badge';

const mapStateToProps = (state) => ({
  count: state.getIn(['chats', 'chatsUnreadCount']),
  id: 'messages',
});

const ChatUnreadCounterIcon = connect(mapStateToProps)(IconWithBadge);

export default connect(mapStateToProps)(ChatUnreadCounterIcon);