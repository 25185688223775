import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ImmutablePureComponent from 'react-immutable-pure-component';
import PropTypes from 'prop-types';
import RelativeTimestamp from '../../../brighteon/components/relative_timestamp';
import Avatar from 'brighteon/components/avatar';

export default class NotificationStatus extends ImmutablePureComponent {

  static propTypes = {
    status: ImmutablePropTypes.map,
    account: ImmutablePropTypes.map,
    onOpenOtherFollowingAccounts: PropTypes.func,
  }

  handleOpenOtherFollowingAccounts = () => {
    const { status } = this.props;

    this.props.onOpenOtherFollowingAccounts(status);
  }

  render() {
    const status = this.props.status;
    const account = this.props.account;

    const followingAccounts = status.get('notification_followings');
    const targetAccount = status.get('target_account');
    const followingNumbers = status.get('notification_following_count');

    const otherFollowingText = (followingNumbers > 3) ?
      (<>
        {' '}
        <span>and </span>
        <span
          className='status__following-accounts__other'
          onClick={this.handleOpenOtherFollowingAccounts}
          role='button' tabIndex={0}
        >
          {followingNumbers - 3}&nbsp;others
        </span>
      </>) :
      null;

    const space = (<span>,&nbsp;</span>);

    const followingNames = (<>
      {followingAccounts.map((followingAccount, index) => (
        <React.Fragment key={index}>
          <a data-id={followingAccount.get('id')} href={followingAccount.get('url')} className='status__display-name muted'>
            <bdi>
              <Avatar account={followingAccount} size={20} />&nbsp;
              <strong dangerouslySetInnerHTML={{
                __html: followingAccount.get('id') === account.get('id') ? 'you' : (followingAccount.get('username') || followingAccount.get('display_name')),
              }}
              />
            </bdi>
          </a>
        </React.Fragment>
      )).reduce((prev, curr) => [prev, space, curr])}
      {otherFollowingText}
      <span>&nbsp;started following&nbsp;</span>
      <a data-id={targetAccount.get('id')} href={targetAccount.get('url')} className='status__display-name muted'>
        <bdi>
          <Avatar account={targetAccount} size={20} />&nbsp;
          <strong dangerouslySetInnerHTML={{
            __html: targetAccount.get('id') === account.get('id') ? 'you' : (targetAccount.get('username') || targetAccount.get('display_name')),
          }}
          />
        </bdi>
      </a>
    </>);
    return (
      <div className='status__wrapper status__wrapper-public' >
        <div className='status status-public status-notification'>
          <RelativeTimestamp timestamp={status.get('created_at')} />
          <br />
          <span className='content'>{followingNames}</span>
        </div>
      </div>
    );
  }

}