import React from 'react';
import PropTypes from 'prop-types';
import Text from '../other_components/text';

const ChatConversationsListHeader = ({ title }) => {
  return (
    <div className={'border-bottom-medium1-1 pl20px py10px'}>
      <Text color='secondary' weight='medium' align='left'>
        {title}
      </Text>
    </div>
  );
};

ChatConversationsListHeader.propTypes = {
  title: PropTypes.string,
};

export default ChatConversationsListHeader;