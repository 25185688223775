import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import Icon from './icon';
import { me, statuses_count, followers_count, following_count } from 'brighteon/initial_state';
import RightSideMenu from '../features/ui/components/right_side_menu';
import { logOut } from '../../brighteon/utils/log_out';
import { openModal } from '../../brighteon/actions/modal';

const messages = defineMessages({
  logoutMessage: { id: 'confirmations.logout.message', defaultMessage: 'Are you sure you want to log out?' },
  logoutConfirm: { id: 'confirmations.logout.confirm', defaultMessage: 'Log out' },
});

class RightSideBar extends React.Component {

  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleRightSideBar: PropTypes.func.isRequired,
    account: PropTypes.object,
    onLogout: PropTypes.func.isRequired,
  };

  handleSignOut = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onLogout();

    return false;
  }

  closeSideBar = () => {
    this.props.handleRightSideBar(false);
  }

  handleSideBarClick = (e) => {
    e.stopPropagation();
  }

  render() {
    return (
      <>
        {
          this.props.open === true && (
            <div className='right-side-bar-container' onClick={this.closeSideBar} role='button' tabIndex={0} >
              <Icon id='close' className='navigation-icon' onClick={this.closeSideBar} />
              <div className='right-side-bar' onClick={this.handleSideBarClick} role='button' tabIndex={0} >
                <RightSideMenu
                  account={this.props.account}
                  statuses_count={statuses_count}
                  followers_count={followers_count}
                  following_count={following_count}
                  handleSignOut={this.handleSignOut}
                  closeMenu={this.closeSideBar}
                />
              </div>
            </div>
          )
        }
      </>
    );
  }

}
const mapStateToProps = state => {
  return {
    account: state.getIn(['accounts', me]),
  };
};

const mapDispatchToProps = (dispatch, { intl }) => ({
  onLogout() {
    dispatch(openModal('CONFIRM', {
      message: intl.formatMessage(messages.logoutMessage),
      confirm: intl.formatMessage(messages.logoutConfirm),
      onConfirm: () => logOut(),
    }));
  },
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RightSideBar));
