import { connect } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import UploadForm from '../../compose/components/upload_form';

const mapStateToProps = (state, { chatConversationId }) => {
  if (chatConversationId) {
    const chatCompose = state.getIn(['chat_compose', chatConversationId]);
    const mediaIds = chatCompose  && chatCompose.get('media_attachments') ? chatCompose.get('media_attachments').map(item => item.get('id')) : ImmutableList();
    return {
      chatConversationId: chatConversationId,
      mediaIds: mediaIds,
    };
  };
  return {
    chatConversationId: null,
    mediaIds: state.getIn(['compose', 'media_attachments']).map(item => item.get('id')),
  };
};

export default connect(mapStateToProps)(UploadForm);
