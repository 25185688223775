import { connect } from 'react-redux';
import UploadButton from '../../compose/components/upload_button';
import { uploadChatCompose } from '../../../actions/chat_compose';

const mapStateToProps = state => ({
  disabled: state.getIn(['compose', 'is_uploading']) || (state.getIn(['compose', 'media_attachments']).size + state.getIn(['compose', 'pending_media_attachments']) > 3 || state.getIn(['compose', 'media_attachments']).some(m => ['video', 'audio'].includes(m.get('type')))),
  unavailable: state.getIn(['compose', 'poll']) !== null,
  resetFileKey: state.getIn(['compose', 'resetFileKey']),
});

const mapDispatchToProps = (dispatch, { chatConversationId }) => ({

  onSelectFile(files) {
    dispatch(uploadChatCompose(files, chatConversationId));
  },

});

export default connect(mapStateToProps, mapDispatchToProps)(UploadButton);
