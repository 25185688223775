import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setChatConversationSelected } from '../../../actions/chats';
import Heading from '../other_components/heading';
import BackButton from '../other_components/back_button';

class ChatBackBar extends React.PureComponent {

  handleOnBack = () => {
    this.props.onSetChatConversationSelectedEmpty();
  }

  setOptionsBtnRef = (c) => {
    this.optionsBtnRef = c;
  }

  render() {
    const nameHTML = 'Chat';

    return (
      <div className={['d', 'z4', 'min-h-53px', 'w-100'].join(' ')}>
        <div className={['d', 'min-h-53px', 'bg-white', 'align-items-center', 'z3', 'top-0', 'w-100'].join(' ')} >
          <div className={['d', 'flex-row', 'saveAreaInsetPT', 'saveAreaInsetPL', 'saveAreaInsetPR', 'w-100'].join(' ')}>
            <BackButton
              className={['h-53px', 'pl10px', 'pr10px'].join(' ')}
              iconSize={18}
              onClick={this.handleOnBack}
              iconClassName={['mr5px', 'fill-navigation'].join(' ')}
            />

            <div className={['d', 'min-h-53px', 'flex-row', 'align-items-center', 'mr-auto', 'flex-fill', 'overflow-hidden'].join(' ')}>
              <div className={['d', 'min-h-53PX', 'justify-content-center', 'w-100', 'flex-shrink'].join(' ')}>
                <Heading size='h1'>
                  <div className={['d', 'w-100'].join(' ')}>
                    <span
                      className={['w-100', 'text-overflow-ellipsis', 'color-black'].join(' ')}
                      dangerouslySetInnerHTML={{ __html: nameHTML }}
                    />
                  </div>
                </Heading>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

const mapDispatchToProps = (dispatch) => ({
  onSetChatConversationSelectedEmpty() {
    dispatch(setChatConversationSelected(null));
  },
});

ChatBackBar.propTypes = {
  onSetChatConversationSelectedEmpty: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(ChatBackBar);