import {
  CRYPTO_WALLETS_FETCH_SUCCESS,
} from '../actions/accounts';
import {
  ACCOUNT_IMPORT,
  ACCOUNTS_IMPORT,
} from '../actions/importer';
import { Map as ImmutableMap } from 'immutable';

const normalizeAccount = (state, account) => state.set(account.id, account.crypto_wallets);

const normalizeAccounts = (state, accounts) => {
  accounts.forEach(account => {
    state = normalizeAccount(state, account);
  });

  return state;
};

const initialState = ImmutableMap();

export default function crypto_wallets(state = initialState, action) {
  switch(action.type) {
  case CRYPTO_WALLETS_FETCH_SUCCESS:
    return state.set(action.accountId, action.cryptoWallets);
  case ACCOUNT_IMPORT:
    return normalizeAccount(state, action.account);
  case ACCOUNTS_IMPORT:
    return normalizeAccounts(state, action.accounts);
  default:
    return state;
  }
};
