import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-mini';
import ImmutablePureComponent from 'react-immutable-pure-component';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { makeGetChatConversation } from '../../../selectors';
import { setChatConversationSelected } from '../../../actions/chats';
import Icon from '../../../../brighteon/components/icon';
import DisplayNameGroup from '../other_components/display_name_group';
import DisplayName from '../other_components/display_name';
import AvatarGroup from '../other_components/avatar_group';
import Text from '../other_components/text';
import RelativeTimestamp from '../../../components/relative_timestamp';

class ChatConversationsListItem extends ImmutablePureComponent {

  state = {
    chatMessageIsExpired: false,
  }

  componentDidMount() {
    this._scheduleNextUpdate();
  }

  componentWillUnmount() {
    clearTimeout(this._timer);
  }

  _scheduleNextUpdate() {
    const { chatConversation } = this.props;
    const { chatMessageIsExpired } = this.state;

    if (!chatConversation || chatMessageIsExpired) return;

    const lastMessage = chatConversation.get('last_chat_message', null);
    if (!lastMessage) return;

    const expirationDate = lastMessage.get('expires_at');
    if (!expirationDate) return;

    const msUntilExpiration = moment(expirationDate).valueOf() - moment().valueOf();
    this._timer = setTimeout(() => {
      this.setState({ chatMessageIsExpired: true });
    }, msUntilExpiration);
  }

  handleOnClick = () => {
    const { chatConversationId } = this.props;
    this.props.onSetChatConversationSelected(chatConversationId);
    this.props.history.push(`/messages/${chatConversationId}`);
  }

  render() {
    const { selected, chatConversation } = this.props;
    const { chatMessageIsExpired } = this.state;

    if (!chatConversation) return <div />;

    const containerClasses = classNames('w-100 border-none conversation-item-container bg-white', {
      'border-right-4px': selected,
      'border-color-red': selected,
      'bg-light1': selected,
      'bg-subtle-onhover': 1,
      'd': 1,
    });

    const innerContainerClasses = classNames({
      'd': 1,
      'flex-row': 1,
      'align-items-start': 1,
      'align-items-center': 0,
      'pr15px': 1,
      'pl25px': 1,
      'py15px': 1,
    });

    const avatarSize = 46;
    const otherAccounts = chatConversation.get('other_accounts');
    const lastMessage = chatConversation.get('last_chat_message', null);
    const lastMessageSentAt = chatConversation.get('last_chat_message_sent_at', null);
    let lastMessageText = !!lastMessage ? lastMessage.get('text', '') : '';
    lastMessageText = lastMessageText.length >= 28 ? `${lastMessageText.substring(0, 28).trim()}...` : lastMessageText;
    if (chatMessageIsExpired) {
      // delete or expired
      lastMessageText = 'Message deleted.';
    } else if (lastMessage && lastMessage.get('status')) {
      lastMessageText = 'Post sent!';
    } else if (lastMessage && lastMessage.get('media_attachments') && lastMessage.get('media_attachments').size > 0) {
      lastMessageText = 'Media sent!';
    } else if (!lastMessageText && !!lastMessageSentAt) {
      // delete or expired
      lastMessageText = 'Message deleted.';
    }
    const content = { __html: lastMessageText };
    const date = chatConversation.get('last_activity_at');
    const isUnread = chatConversation.get('is_unread');
    const isMuted = chatConversation.get('is_muted');

    const previewTextClasses = classNames({
      'mt1px text-align-left preview-text-class': 1,
      'color-medium2': !(isUnread && !isMuted),
      'color-blue': (isUnread && !isMuted),
    });

    return (
      <button
        className={containerClasses}
        onClick={this.handleOnClick}
      >

        {/* { isUnread && !isMuted && <div className={''} /> } */}
        {
          isMuted &&
          <div className={'d pos-absolute left-0 top-50pc ml5px mt-neg-5px max-circle w-10px h-10px bg-transparent'}>
            <Icon id='audio-mute' className={''} size={16} />
          </div>
        }

        <div className={innerContainerClasses}>
          <AvatarGroup accounts={otherAccounts} size={avatarSize} maxVisible={3} noHover isUnread={isUnread && !isMuted} showText noLink />

          <div className={classNames('pl10px overflow-y-hidden overflow-x-hidden flex-shrink', { 'color-blue': isUnread && !isMuted })}>
            <div className={'d flex-row align-items-center'}>
              <div className={'d pt2px pr5px decoration-none overflow-hidden flex-normal flex-row align-items-start align-items-center'}>
                <div className={'max-w-100pc-78px'}>
                  { otherAccounts.size === 1 && <DisplayName account={otherAccounts.get(0)} noHover /> }
                  { otherAccounts.size > 1 && <DisplayNameGroup accounts={otherAccounts} noHover noLink /> }
                </div>
                <Text size='extraSmall' color='secondary' className={'ml-auto'}>
                  <RelativeTimestamp timestamp={date} />
                </Text>
              </div>
            </div>

            <div className={previewTextClasses} dangerouslySetInnerHTML={content} />
          </div>
        </div>
      </button>
    );
  }

}

const mapStateToProps = (state, { chatConversationId }) => ({
  chatConversation: makeGetChatConversation()(state, { id: chatConversationId }),
  selectedId: state.getIn(['chats', 'selectedChatConversationId'], null),
  selected: state.getIn(['chats', 'selectedChatConversationId'], null) === chatConversationId,
});

const mapDispatchToProps = (dispatch) => ({
  onSetChatConversationSelected: (chatConversationId) => {
    dispatch(setChatConversationSelected(chatConversationId));
  },
});

ChatConversationsListItem.propTypes = {
  chatConversationId: PropTypes.string.isRequired,
  chatConversation: ImmutablePropTypes.map,
  onSetChatConversationSelected: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  source: PropTypes.string.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatConversationsListItem));
