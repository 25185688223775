import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import UploadProgressContainer from '../containers/upload_progress_container';
import ImmutablePureComponent from 'react-immutable-pure-component';
import UploadContainer from '../containers/upload_container';
import SensitiveButtonContainer from '../containers/sensitive_button_container';
import { FormattedMessage } from 'react-intl';

export default class UploadForm extends ImmutablePureComponent {

  static propTypes = {
    mediaIds: ImmutablePropTypes.list.isRequired,
    chatConversationId: PropTypes.string,
  };

  render () {
    const { mediaIds, chatConversationId } = this.props;

    return (
      <div className='compose-form__upload-wrapper'>
        <UploadProgressContainer
          icon='upload'
          message={<FormattedMessage id='upload_progress.label' defaultMessage='Uploading…' />}
          chatConversationId={chatConversationId}
        />

        <div className='compose-form__uploads-wrapper'>
          {mediaIds.map(id => (
            <UploadContainer id={id} key={id} chatConversationId={chatConversationId} />
          ))}
        </div>

        {!mediaIds.isEmpty() && !chatConversationId && <SensitiveButtonContainer />}
      </div>
    );
  }

}
