import { connect } from 'react-redux';
import AccountTip from './account_tip';
import { openModal } from '../actions/modal';
import { injectIntl } from 'react-intl';
import { MODAL_TIP_CRYPTO } from '../constants';
import { fetchCryptoWallets } from '../actions/accounts';

const makeMapStateToProps = () => {
  const mapStateToProps = (state, { accountId, accountName }) => ({
    accountId: accountId,
    accountName: accountName,
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  onClick(accountId, accountName) {
    dispatch(openModal(MODAL_TIP_CRYPTO, { accountId, accountName }));
  },

  fetchWallets(accountId) {
    dispatch(fetchCryptoWallets(accountId));
  },
});

export default injectIntl(connect(makeMapStateToProps, mapDispatchToProps)(AccountTip));
