import React from 'react';
import ImmutablePureComponent from 'react-immutable-pure-component';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cryptoImage from '../../images/brighteon-social/Icon-Crypto.svg';

export default @injectIntl
class AccountTip extends ImmutablePureComponent {

  static propTypes = {
    accountId: PropTypes.string,
    accountName: PropTypes.string,
    fetchWallets: PropTypes.func,
  };

  componentDidMount() {
    this.props.fetchWallets(this.props.accountId);
  }

  handleClick = () => {
    const { accountId, accountName } = this.props;
    this.props.onClick(accountId, accountName);
  }

  render() {
    return (
      <>
        <div>
          <button type='button' className='btn btn-secondary d-flex align-items-center border-color-transparent radius-rounded-4px gold-background' onClick={this.handleClick}>
            <img src={cryptoImage} alt='Send Crypto Cash Tip' className='h-24px pr5px' />Tip Crypto
          </button>
        </div>
      </>);
  }

}
