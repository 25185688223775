import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { closeModal } from '../../../actions/modal';
import NewPostModalContainer from '../../../../brighteon/features/ui/containers/new_post_modal_container';


const mapDispatchToProps = (dispatch) => ({

  closeComposeModal() {
    dispatch(closeModal('COMPOSE'));
  },

});

class ComposePanel extends React.PureComponent {

  static propTypes = {
    closeComposeModal: PropTypes.func.isRequired,
  }

  state = {
    showNewPostModal: true,
  }

  openNewPostModal = () => {
    this.setState({ showNewPostModal: true });
  }

  closeNewPostModal = () => {
    this.setState({ showNewPostModal: false });
    this.props.closeComposeModal();
  }

  render() {
    const showNewPostModal = this.state.showNewPostModal;
    return (
      <div className='w-100 modal-root__modal compose-modal'>
        <NewPostModalContainer show={showNewPostModal} closeNewPostModal={this.closeNewPostModal} />
      </div>
    );
  }

}

export default connect(null, mapDispatchToProps)(ComposePanel);
