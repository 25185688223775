import { Map as ImmutableMap, List as ImmutableList } from 'immutable';
import { normalizedAccounts } from '../actions/importer/normalizer';
import {
  SETTINGS_FOLLOW_FETCH_ACCOUNTS,
  SETTINGS_SET_MUTUAL_CONTACTS,
  // SETTINGS_FOLLOW_UPDATE_RELATIONSHIP,
  // SETTINGS_FOLLOW_UPDATE_ACCOUNT_STATUS,
  // SETTINGS_FOLLOW_UPDATE_ACCOUNT_ACTIVITY,
  // SETTINGS_FOLLOW_UPDATE_ACCOUNT_ORDER_BY
} from '../actions/dashboard_settings';

const initialState = ImmutableMap({
  settingsFollow: [],
  mutualContacts: ImmutableList([]),
});

export default function dashboard_settings(state = initialState, action) {
  switch (action.type) {
  case SETTINGS_FOLLOW_FETCH_ACCOUNTS:
    return state.set('settingsFollow', action.data);
  case SETTINGS_SET_MUTUAL_CONTACTS:
    return state.set('mutualContacts', normalizedAccounts(action.data));
  default:
    return state;
  }
}
