import React from 'react';
import PropTypes from 'prop-types';
import Icon from './icon';
import LeftSideMenu from '../features/ui/components/left_side_menu';

class LeftSideBar extends React.PureComponent {

  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleLeftSideBar: PropTypes.func.isRequired,
    onOpenNewPostModal: PropTypes.func.isRequired,
  };

  closeSideBar = () => {
    this.props.handleLeftSideBar(false);
  }

  handleSideBarClick = (e) => {
    e.stopPropagation();
  }

  render() {
    return (
      <>
        {
          this.props.open === true && (
            <div className='left-side-bar-container' onClick={this.closeSideBar} role='button' tabIndex={0} >
              <div className='left-side-bar' onClick={this.handleSideBarClick} role='button' tabIndex={0} >
                <LeftSideMenu closeMenu={this.closeSideBar} onOpenNewPostModal={this.props.onOpenNewPostModal} />
              </div>
              <Icon id='close' className='navigation-icon' onClick={this.closeSideBar} />
            </div>
          )
        }
      </>
    );
  }

}

export default LeftSideBar;
