import React from 'react';
import ImmutablePureComponent from 'react-immutable-pure-component';
import { unescape as unescapeHtml } from 'lodash';
import RelativeTimestamp from '../../../../brighteon/components/relative_timestamp';

export class FeedItem extends ImmutablePureComponent {

  processHeadline = (text) => {
    return unescapeHtml(text)
      .replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec));
  }
  
  addDefaultSrc = (event) => {
    const alt_url = 'https://brighteon.social/packs/media/images/brighteon-social/Icon-News-Gray-7baa87e528064c5256c8b340f515c84f.svg';
    event.target.setAttribute('src', alt_url);
  }

  render() {
    const {
      headline,
      date,
      link,
      newsSource,
      image,
    } = this.props;

    if (date) {
      return (
        <div className='uncensored-news-feed__item d-flex'>
          <a className='mr5px' href={link}>
            <img src={image} className='mt7px w-62px h-62px object-fit-cover' alt='feed item' onError={this.addDefaultSrc}/>
          </a>
          <a href={link} target='_blank'>
            <div className='uncensored-news-feed__item__description'>
              <span>{newsSource}</span> • <RelativeTimestamp timestamp={(new Date(date)).toISOString()} />
            </div>
            <div className='uncensored-news-feed__item__headline'>
              {this.processHeadline(headline)}
            </div>
          </a>
        </div>
      );
    }
    return (<></>);

  }

}
