import { connect } from 'react-redux';
import IconWithBadge from '../../../../brighteon/components/icon_with_badge';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getNotifications = createSelector([
  state => ImmutableList(state.getIn(['settings', 'notifications', 'shows']).filter(item => !item).keys()),
  state => state.getIn(['notifications', 'items']),
], (excludedTypes, notifications) => {
  return notifications.filter(item => item !== null)
    .filter(item => item.get('read') !== true)
    .filter(item => !excludedTypes.includes(item.get('type')));
});

const getPendingNotifications = createSelector([
  state => ImmutableList(state.getIn(['settings', 'notifications', 'shows']).filter(item => !item).keys()),
  state => state.getIn(['notifications', 'pendingItems']),
], (excludedTypes, notifications) => {
  return notifications.filter(item => item !== null)
    .filter(item => item.get('read') !== true)
    .filter(item => !excludedTypes.includes(item.get('type')));
});

// const getPendingNotificationsStatusBar = createSelector([
//   state => ImmutableList(state.getIn(['settings', 'notifications', 'shows']).filter(item => !item).keys()),
//   state => state.getIn(['notifications_status_bar', 'pendingItems']),
// ], (excludedTypes, notifications) => {
//   return notifications.filter(item => item !== null)
//     .filter(item => item.get('read') !== true)
//     .filter(item => !excludedTypes.includes(item.get('type')));
// });

const mapStateToProps = state => ({
  count: getNotifications(state).size + getPendingNotifications(state).size,
  id: 'bell',
});

export default connect(mapStateToProps)(IconWithBadge);
