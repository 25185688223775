import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openPopover } from '../../../actions/popover';
import { approveChatConversationRequest } from '../../../actions/chat_conversations';
import {
  POPOVER_CHAT_CONVERSATION_OPTIONS,
} from '../../../constants';
import Button from '../../../components/button';
import Text from '../other_components/text';

class ChatConversationRequestApproveBar extends React.PureComponent {

  handleOnApproveMessageRequest = () => {
    this.props.onApproveChatConversationRequest(this.props.chatConversationId);
  }

  setOptionsBtnRef = (c) => {
    this.optionsBtnRef = c;
  }

  render () {
    const { chatConversationId, isXS } = this.props;

    if (!chatConversationId) return null;

    const btn = (
      <Button
        isBlock
        isNarrow
        onClick={this.handleOnApproveMessageRequest}
        className='normal-button'
      >
        <Text color='inherit' align='center' weight='medium'>
          Approve Message Request
        </Text>
      </Button>
    );

    if (isXS) {
      return (
        <div className='d z4 min-h-53px w-100 mt-auto bg-primary'>
          <div className='d min-h-53px bg-primary align-items-center z3 bottom-0 right-0 left-0 pos-fixed'>
            <div className='d w-100 pb5px px15px saveAreaInsetPB saveAreaInsetPL saveAreaInsetPR'>
              <div className='d flex-row align-items-center min-h-53px w-100 border-top-1px borderColorSecondary px10px'>
                {btn}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={'d pos-absolute bottom-0 left-0 right-0 flex-row align-items-center min-h-58px bgPrimary w-100 border-top-medium1-1 borderColorSecondary px15px'}>
        {btn}
      </div>
    );
  }

}

const mapDispatchToProps = (dispatch) => ({
  onApproveChatConversationRequest(chatConversationId) {
    dispatch(approveChatConversationRequest(chatConversationId));
  },
  onOpenChatConversationOptionsPopover(chatConversationId, targetRef) {
    dispatch(openPopover(POPOVER_CHAT_CONVERSATION_OPTIONS, {
      chatConversationId,
      targetRef,
      position: 'bottom',
    }));
  },
});

ChatConversationRequestApproveBar.propTypes = {
  chatConversationId: PropTypes.string,
  onApproveChatConversationRequest: PropTypes.func.isRequired,
  isXS: PropTypes.bool,
};

export default connect(null, mapDispatchToProps)(ChatConversationRequestApproveBar);