import React from 'react';
import PropTypes from 'prop-types';
import Button from './button';
// import Icon from './icon'
import Icon from '../../../../brighteon/components/icon';
import Image from './image';
import Text from './text';
import classNames from 'classnames';

class ListItem extends React.PureComponent {

  handleOnClick = (e) => {
    if (!!this.props.onClick) {
      this.props.onClick(e);
    }
  }

  render() {
    const {
      title,
      isLast,
      to,
      href,
      onClick,
      actionIcon,
      size,
      icon,
      image,
      hideArrow,
      isHidden,
      subtitle,
      isActive,
      openInNewTab,
    } = this.props;

    if (!title) {
      return (
        <div className={'d bgSecondary w-100 h4px'} />
      );
    }

    if (isHidden) {
      return (
        <React.Fragment>
          {title}
        </React.Fragment>
      );
    }

    const small = size === 'small';
    const large = size === 'large';

    let textSize = '';
    if (small) textSize = 'normal';
    else if (large) textSize = 'large';
    else textSize = 'normal';

    const iconSize = large ? 14 : 10;
    const imageSize = large ? '22px' : '18px';
    const showActive = isActive !== undefined;

    const containerClasses = classNames({
      'd': 1,
      'cursor-pointer': 1,
      'decoration-none': 1,
      'px15px': !small,
      'py15px': !small,
      'px10px': small,
      'py10px': small,
      'flex-row': 1,
      'align-items-center': 1,
      'w-100': 1,
      'outline-none': 1,
      'bg-subtle-onhover': 1,
      'borderColorSecondary': !isLast,
      'border-bottom-medium1-1': !isLast,
      'color-black': 1,
    });

    const iconClasses = classNames({
      'mr10px': !large,
      'mr15px': large,
      'cPrimary': !!icon,
      'max-circle': !icon && !!image,
    });

    const textContainerClasses = classNames({
      'd': 1,
      'pr5px': 1,
      'w-100': hideArrow,
      'max-w-100pc-42px': !hideArrow || showActive,
    });

    const arrowClasses = classNames({
      'ml-auto': !showActive,
      'ml10px': showActive,
      'cSecondary': 1,
      'flex-shrink1': 1,
    });

    return (
      <Button
        to={to}
        href={href}
        onClick={!!onClick ? this.handleOnClick : undefined}
        className={containerClasses}
        target={openInNewTab ? '_blank' : undefined}
        noClasses
      >

        {
          !!image &&
          <Image
            src={image}
            height={imageSize}
            width={imageSize}
            className={iconClasses}
          />
        }

        {
          !!icon &&
          <Icon
            id={icon}
            size={iconSize}
            className={iconClasses}
          />
        }

        <div className={textContainerClasses}>
          <Text color='primary' weight={!!subtitle ? 'bold' : 'normal'} size={textSize} className={'overflow-hidden flex-normal text-overflow-ellipsis'}>
            {title}
          </Text>

          {
            !!subtitle &&
            <Text color='primary' size='small' className={'overflow-hidden flex-normal mt5px'}>
              {subtitle}
            </Text>
          }
        </div>

        {
          !!showActive &&
          <input
            type='radio'
            checked={isActive}
            className={'ml-auto flex-shrink1 mt0'}
          />
        }

        {
          !hideArrow &&
          <Icon
            id={!!actionIcon ? actionIcon : 'arrow-right'}
            size={16}
            className={arrowClasses}
          />
        }
      </Button>
    );
  }

}

ListItem.propTypes = {
  icon: PropTypes.string,
  image: PropTypes.string,
  isLast: PropTypes.bool,
  isHidden: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isActive: PropTypes.bool,
  actionIcon: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf([
    'small',
    'normal',
    'large',
  ]),
  hideArrow: PropTypes.bool,
  openInNewTab: PropTypes.bool,
};

export default ListItem;
