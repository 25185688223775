import { connect } from 'react-redux';
import Upload from '../components/upload';
import { openModal } from '../../../actions/modal';
import { submitCompose, undoUploadCompose } from '../../../actions/compose';
import { undoUploadChatCompose } from '../../../actions/chat_compose';

const mapStateToProps = (state, { id, chatConversationId }) => {
  let media = '';
  if (chatConversationId) {
    media = state.getIn(['chat_compose', chatConversationId, 'media_attachments']).find(item => item.get('id') === id);
  } else {
    media = state.getIn(['compose', 'media_attachments']).find(item => item.get('id') === id);
  }

  return {
    media: media,
    chatConversationId: chatConversationId,
  };
};

const mapDispatchToProps = dispatch => ({

  onUndo: (id, conversationId) => {
    if (conversationId) {
      dispatch(undoUploadChatCompose(id, conversationId));
    } else {
      dispatch(undoUploadCompose(id));
    }
  },

  onOpenFocalPoint: id => {
    dispatch(openModal('FOCAL_POINT', { id }));
  },

  onSubmit (router) {
    dispatch(submitCompose(router));
  },

});

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
