import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  expandNotifications,
  scrollTopNotifications,
  mountNotifications,
  unmountNotifications,
  markNotificationsAsRead,
} from '../../actions/notifications_status_bar';
import { submitMarkers } from '../../actions/markers';
import NotificationContainer from './containers/notification_container';
import { injectIntl } from 'react-intl';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';
import { debounce } from 'lodash';
import LoadGap from '../../components/load_gap';
import compareId from 'brighteon/compare_id';

const getExcludedTypes = createSelector([
  state => state.getIn(['settings', 'notifications', 'shows']),
], (shows) => {
  return ImmutableList(shows.filter(item => !item).keys());
});

const getNotificationsStatusBar = createSelector([
  getExcludedTypes,
  state => state.getIn(['notifications_status_bar', 'items']),
], (excludedTypes, notifications) => {
  // used if user changed the notification settings after loading the notifications from the server
  // otherwise a list of notifications will come pre-filtered from the backend
  // we need to turn it off for FilterBar in order not to block ourselves from seeing a specific category
  return notifications.filterNot(item => item !== null && excludedTypes.includes(item.get('type')));
});

const mapStateToProps = state => ({
  notificationsStatusBar: getNotificationsStatusBar(state),
  isLoading: state.getIn(['notifications_status_bar', 'isLoading'], true),
  lastReadId: state.getIn(['notifications', 'readMarkerId']),
  canMarkAsRead: state.getIn(['notifications', 'readMarkerId']) !== '0' && getNotificationsStatusBar(state).some(item => item !== null && compareId(item.get('id'), state.getIn(['notifications', 'readMarkerId'])) > 0),
});

export default @connect(mapStateToProps)
@injectIntl
class NotificationsStatusBar extends React.PureComponent {

  static propTypes = {
    notificationsStatusBar: ImmutablePropTypes.list.isRequired,
    dispatch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    lastReadId: PropTypes.string,
    canMarkAsRead: PropTypes.bool,
  };

  static defaultProps = {
    trackScroll: true,
  };

  UNSAFE_componentWillMount() {
    this.props.dispatch(mountNotifications());
  }

  componentWillUnmount() {
    this.handleLoadOlder.cancel();
    this.handleScrollToTop.cancel();
    this.handleScroll.cancel();
    this.props.dispatch(scrollTopNotifications(false));
    this.props.dispatch(unmountNotifications());
  }

  handleLoadGap = (maxId) => {
    this.props.dispatch(expandNotifications({ maxId }));
  };

  handleLoadOlder = debounce(() => {
    const last = this.props.notificationsStatusBar.last();
    this.props.dispatch(expandNotifications({ maxId: last && last.get('id') }));
  }, 300, { leading: true });

  handleScrollToTop = debounce(() => {
    this.props.dispatch(scrollTopNotifications(true));
  }, 100);

  handleScroll = debounce(() => {
    this.props.dispatch(scrollTopNotifications(false));
  }, 100);

  handleMoveUp = id => {
    const elementIndex = this.props.notificationsStatusBar.findIndex(item => item !== null && item.get('id') === id) - 1;
    this._selectChild(elementIndex, true);
  }

  handleMoveDown = id => {
    const elementIndex = this.props.notificationsStatusBar.findIndex(item => item !== null && item.get('id') === id) + 1;
    this._selectChild(elementIndex, false);
  }

  _selectChild(index, align_top) {
    const container = this.column.node;
    const element = container.querySelector(`article:nth-of-type(${index + 1}) .focusable`);

    if (element) {
      if (align_top && container.scrollTop > element.offsetTop) {
        element.scrollIntoView(true);
      } else if (!align_top && container.scrollTop + container.clientHeight < element.offsetTop + element.offsetHeight) {
        element.scrollIntoView(false);
      }
      element.focus();
    }
  }

  handleMarkAsRead = () => {
    this.props.dispatch(markNotificationsAsRead());
    this.props.dispatch(submitMarkers({ immediate: true }));
  };

  render() {
    const {
      notificationsStatusBar: notifications,
      isLoading,
      lastReadId,
    } = this.props;

    let scrollableContent = null;

    if (isLoading && this.scrollableContent) {
      scrollableContent = this.scrollableContent;
    } else if (notifications.size > 0) {
      scrollableContent = notifications.map((item, index) => item === null ? (
        <LoadGap
          key={'gap:' + notifications.getIn([index + 1, 'id'])}
          disabled={isLoading}
          maxId={index > 0 ? notifications.getIn([index - 1, 'id']) : null}
          onClick={this.handleLoadGap}
        />
      ) : (
        <NotificationContainer
          key={item.get('id')}
          notification={item}
          accountId={item.get('account')}
          onMoveUp={this.handleMoveUp}
          onMoveDown={this.handleMoveDown}
          unread={lastReadId !== '0' && compareId(item.get('id'), lastReadId) >
            0}
        />
      ));
    } else {
      scrollableContent = null;
    }

    this.scrollableContent = scrollableContent;

    return scrollableContent;
  }

}
