// import classNames from 'classnames/bind'
// export const CX = classNames.bind(_s)

export const APP_NAME = 'Brighteon Social';

export const DEFAULT_REL = 'noopener noreferrer';

export const BREAKPOINT_EXTRA_LARGE = 1480;
export const BREAKPOINT_LARGE = 1280;
export const BREAKPOINT_MEDIUM = 1160;
export const BREAKPOINT_SMALL = 1080;
export const BREAKPOINT_EXTRA_SMALL = 992;

export const MOUSE_IDLE_DELAY = 300;

export const MIN_UNAUTHENTICATED_PAGES = 1;

export const LAZY_LOAD_SCROLL_OFFSET = 50;

export const ALLOWED_AROUND_SHORT_CODE = '><\u0085\u0020\u00a0\u1680\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000\u2028\u2029\u0009\u000a\u000b\u000c\u000d';
export const MAX_POST_CHARACTER_COUNT = 3000;

export const URL_DISSENTER_SHOP = 'https://shop.dissenter.com';
export const URL_DISSENTER_SHOP_DONATIONS = 'https://shop.dissenter.com/category/donations';
export const URL_GAB_PRO = 'https://pro.gab.com';

export const PLACEHOLDER_MISSING_HEADER_SRC = '/original/missing.png';

export const POPOVER_CHAT_CONVERSATION_OPTIONS = 'CHAT_CONVERSATION_OPTIONS';
export const POPOVER_CHAT_MESSAGE_OPTIONS = 'CHAT_MESSAGE_OPTIONS';
export const POPOVER_CHAT_CONVERSATION_EXPIRATION_OPTIONS = 'CHAT_CONVERSATION_EXPIRATION_OPTIONS';
export const POPOVER_CHAT_SETTINGS = 'CHAT_SETTINGS';
export const POPOVER_COMMENT_SORTING_OPTIONS = 'COMMENT_SORTING_OPTIONS';
export const POPOVER_COMPOSE_POST_DESTINATION = 'COMPOSE_POST_DESTINATION';
export const POPOVER_DATE_PICKER = 'DATE_PICKER';
export const POPOVER_EMOJI_PICKER = 'EMOJI_PICKER';
export const POPOVER_GROUP_LIST_SORT_OPTIONS = 'GROUP_LIST_SORT_OPTIONS';
export const POPOVER_GROUP_MEMBER_OPTIONS = 'GROUP_MEMBER_OPTIONS';
export const POPOVER_GROUP_OPTIONS = 'GROUP_OPTIONS';
export const POPOVER_GROUP_TIMELINE_SORT_OPTIONS = 'GROUP_TIMELINE_SORT_OPTIONS';
export const POPOVER_GROUP_TIMELINE_SORT_TOP_OPTIONS = 'GROUP_TIMELINE_SORT_TOP_OPTIONS';
export const POPOVER_NAV_SETTINGS = 'NAV_SETTINGS';
export const POPOVER_PROFILE_OPTIONS = 'PROFILE_OPTIONS';
export const POPOVER_SIDEBAR_MORE = 'SIDEBAR_MORE';
export const POPOVER_STATUS_OPTIONS = 'STATUS_OPTIONS';
export const POPOVER_STATUS_EXPIRATION_OPTIONS = 'STATUS_EXPIRATION_OPTIONS';
export const POPOVER_SHARE = 'POPOVER_SHARE';
export const POPOVER_STATUS_VISIBILITY = 'STATUS_VISIBILITY';
export const POPOVER_TIMELINE_INJECTION_OPTIONS = 'TIMELINE_INJECTION_OPTIONS';
export const POPOVER_USER_INFO = 'USER_INFO';
export const POPOVER_VIDEO_STATS = 'VIDEO_STATS';

export const MODAL_ALBUM_CREATE = 'ALBUM_CREATE';
export const MODAL_BLOCK_ACCOUNT = 'BLOCK_ACCOUNT';
export const MODAL_BOOKMARK_COLLECTION_CREATE = 'BOOKMARK_COLLECTION_CREATE';
export const MODAL_BOOST = 'BOOST';
export const MODAL_CHAT_CONVERSATION_CREATE = 'CHAT_CONVERSATION_CREATE';
export const MODAL_CHAT_CONVERSATION_DELETE = 'CHAT_CONVERSATION_DELETE';
export const MODAL_CHAT_CONVERSATION_MEMBERS = 'CHAT_CONVERSATION_MEMBERS';
export const MODAL_COMPOSE = 'COMPOSE';
export const MODAL_CONFIRM_GAB = 'GAB_CONFIRM';
export const MODAL_DECK_COLUMN_ADD = 'DECK_COLUMN_ADD';
export const MODAL_DECK_COLUMN_ADD_OPTIONS = 'DECK_COLUMN_ADD_OPTIONS';
export const MODAL_DISPLAY_OPTIONS = 'DISPLAY_OPTIONS';
export const MODAL_EDIT_PROFILE = 'EDIT_PROFILE';
export const MODAL_EDIT_SHORTCUTS = 'EDIT_SHORTCUTS';
export const MODAL_EMAIL_CONFIRMATION_REMINDER = 'EMAIL_CONFIRMATION_REMINDER';
export const MODAL_GROUP_CREATE = 'GROUP_CREATE';
export const MODAL_GROUP_DELETE = 'GROUP_DELETE';
export const MODAL_GROUP_PASSWORD = 'GROUP_PASSWORD';
export const MODAL_HOME_TIMELINE_SETTINGS = 'HOME_TIMELINE_SETTINGS';
export const MODAL_HOTKEYS = 'HOTKEYS';
export const MODAL_LIST_ADD_USER = 'LIST_ADD_USER';
export const MODAL_LIST_CREATE = 'LIST_CREATE';
export const MODAL_LIST_DELETE = 'LIST_DELETE';
export const MODAL_LIST_EDITOR = 'LIST_EDITOR';
export const MODAL_LIST_TIMELINE_SETTINGS = 'LIST_TIMELINE_SETTINGS';
export const MODAL_MEDIA = 'MEDIA';
export const MODAL_MUTE = 'MUTE';
export const MODAL_PRO_UPGRADE = 'PRO_UPGRADE';
export const MODAL_REPORT = 'REPORT';
export const MODAL_STATUS = 'MODAL_STATUS';
export const MODAL_STATUS_LIKES = 'STATUS_LIKES';
export const MODAL_STATUS_REPOSTS = 'STATUS_REPOSTS';
export const MODAL_STATUS_REVISIONS = 'STATUS_REVISIONS';
export const MODAL_UNAUTHORIZED = 'UNAUTHORIZED';
export const MODAL_UNFOLLOW = 'UNFOLLOW';
export const MODAL_VIDEO = 'VIDEO';
export const MODAL_TIP_CRYPTO = 'TIP_CRYPTO';

export const COMMENT_SORTING_TYPE_NEWEST = 'newest';
export const COMMENT_SORTING_TYPE_OLDEST = 'oldest';
export const COMMENT_SORTING_TYPE_TOP = 'most-liked';

export const GROUP_LIST_SORTING_TYPE_ALPHABETICAL = 'abc';
export const GROUP_LIST_SORTING_TYPE_MOST_POPULAR = 'member-desc';

export const SUGGESTION_TYPE_VERIFIED = 'verified';
export const SUGGESTION_TYPE_RELATED = 'related';

export const FONT_SIZES_EXTRA_SMALL = '12px';
export const FONT_SIZES_SMALL = '13px';
export const FONT_SIZES_NORMAL = '14px';
export const FONT_SIZES_MEDIUM = '15px';
export const FONT_SIZES_LARGE = '16px';
export const FONT_SIZES_EXTRA_LARGE = '17px';

export const FONT_SIZES = {
  'extra-small': '12px',
  small: '13px',
  normal: '14px',
  medium: '15px',
  large: '16px',
  'extra-large': '17px',
};

export const THEMES = [
  'white',
  'light',
  'muted',
  'black',
];

export const DEFAULT_THEME = 'white';
export const DEFAULT_FONT_SIZE = 'normal';

export const NOTIFICATION_FILTERS = [
  'all',
  'mention',
  'favourite',
  'reblog',
  'follow',
  'poll',
  'follow_requests',
];

export const GAB_COM_INTRODUCE_YOURSELF_GROUP_ID = '12';

export const MIN_ACCOUNT_CREATED_AT_ONBOARDING = 1594789200000; // 2020-07-15

export const EXPIRATION_OPTION_5_MINUTES = 'five_minutes';
export const EXPIRATION_OPTION_1_HOUR = 'one_hour';
export const EXPIRATION_OPTION_6_HOURS = 'six_hours';
export const EXPIRATION_OPTION_1_DAY = 'one_day';
export const EXPIRATION_OPTION_3_DAYS = 'three_days';
export const EXPIRATION_OPTION_7_DAYS = 'one_week';

export const EXPIRATION_OPTION_NAMES = {
  [EXPIRATION_OPTION_5_MINUTES]: '5m',
  [EXPIRATION_OPTION_1_HOUR]: '1h',
  [EXPIRATION_OPTION_6_HOURS]: '6h',
  [EXPIRATION_OPTION_1_DAY]: '1d',
  [EXPIRATION_OPTION_3_DAYS]: '3d',
  [EXPIRATION_OPTION_7_DAYS]: '7d',
};

export const GROUP_TIMELINE_SORTING_TYPE_HOT = 'hot';
export const GROUP_TIMELINE_SORTING_TYPE_NEWEST = 'newest';
export const GROUP_TIMELINE_SORTING_TYPE_RECENT_ACTIVITY = 'recent';
export const GROUP_TIMELINE_SORTING_TYPE_TOP = 'top';

export const ACCEPTED_GROUP_TABS = ['new', 'featured', 'member', 'admin'];

export const GROUP_TIMELINE_SORTING_TYPE_TOP_OPTION_TODAY = 'today';
export const GROUP_TIMELINE_SORTING_TYPE_TOP_OPTION_WEEKLY = 'weekly';
export const GROUP_TIMELINE_SORTING_TYPE_TOP_OPTION_MONTHLY = 'monthly';
export const GROUP_TIMELINE_SORTING_TYPE_TOP_OPTION_YEARLY = 'yearly';
export const GROUP_TIMELINE_SORTING_TYPE_TOP_OPTION_ALL_TIME = 'all_time';

export const TOAST_TYPE_ERROR = 'error';
export const TOAST_TYPE_SUCCESS = 'success';

export const TIMELINE_INJECTION_FEATURED_GROUPS = 'TI_FEATURED_GROUPS';
export const TIMELINE_INJECTION_GROUP_CATEGORIES = 'TI_GROUP_CATEGORIES';
export const TIMELINE_INJECTION_PROGRESS = 'TI_PROGRESS';
export const TIMELINE_INJECTION_PRO_UPGRADE = 'TI_PRO_UPGRADE';
export const TIMELINE_INJECTION_PWA = 'TI_PWA';
export const TIMELINE_INJECTION_SHOP = 'TI_SHOP';
export const TIMELINE_INJECTION_USER_SUGGESTIONS = 'TI_USER_SUGGESTIONS';
export const TIMELINE_INJECTION_GAB_TV_EXPLORE = 'TI_GAB_TV_EXPLORE';

export const TIMELINE_INJECTION_WEIGHT_DEFAULT = 1;
export const TIMELINE_INJECTION_WEIGHT_MULTIPLIER = 100;
export const TIMELINE_INJECTION_WEIGHT_SUBTRACTOR = 0.005;
export const TIMELINE_INJECTION_WEIGHT_MIN = 0.01;

export const GAB_DECK_MAX_ITEMS = 8;
export const GAB_DECK_OPTIONS = ['home', 'user.id', 'notifications', 'list.id', 'likes', 'bookmarks', 'pro', 'compose', 'group.id', 'explore', 'news', 'news.id', 'hashtag.id'];

export const TRENDS_RSS_SOURCES = [
  { 'id':'5faf4fb95ca43345214f7989', 'title':'American Greatness' },
  { 'id':'6036dd6fe09bb91be52bf445', 'title':'Big League Politics' },
  { 'id':'614fe9b133254e072452208a', 'title':'Infowars' },
  { 'id':'614d160133254e072443ccb6', 'title':'Red Voice Media' },
  { 'id':'5daf64b18e955e2433b0f5ce', 'title':'Breitbart' },
  { 'id':'5daf66772fea4d3ba000883b', 'title':'Gateway Pundit' },
  { 'id':'5dafa767300c0e2601330386', 'title':'RT' },
  { 'id':'5e1e0a7dc46f1d5487be1806', 'title':'Yahoo News' },
  { 'id':'5e5037159e35822b6afb0f77', 'title':'CBN' },
  { 'id':'5e52dfc91f94b1111db105ed', 'title':'National File' },
  { 'id':'5e56dcff1f94b1111db95a75', 'title':'WND' },
  { 'id':'5e7160f7a994095d6ca9bbee', 'title':'Redstate' },
  { 'id':'5e8275900d86876052a853ae', 'title':'CD Media' },
];

export const GAB_AD_PLACEMENTS = {
  'status': 'status',
  'banner': 'banner',
  'panel': 'panel',
};

export const TIMELINE_SCROLL_KEYS_EXCLUDED_FROM_ADS = [
  'account_timeline',
  'liked_statuses',
  'account_comments_timeline',
];
