import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { shortNumberFormat } from '../../../utils/numbers';
import { fetchChatConversationBlockedCount } from '../../../actions/chat_conversations';
import Text from '../other_components/text';
import Icon from '../../../components/icon';

class ChatConversationBlockedListItem extends React.PureComponent {

  componentDidMount() {
    this.props.onFetchChatConversationBlockedCount();
  }

  render() {
    const { blockedCount } = this.props;

    if (!blockedCount || blockedCount < 1) return null;

    return (
      <NavLink
        className={'d w-100 bg-transparent bg-subtle-onhover border-bottom-medium1-1 decoration-none outline-none cursor-pointer'}
        to='/messages/blocks'
      >
        <div className={'d px15px py15px align-items-center flex-row color-black'}>
          <Text size='medium'>Blocked Conversations</Text>
          <Text size='medium' className='ml-auto mr15px' color='black'>
            {shortNumberFormat(blockedCount)}
          </Text>
          <Icon id='angle-right' size='10px' className={''} />
        </div>
      </NavLink>
    );
  }

}


const mapStateToProps = (state) => ({
  blockedCount: state.getIn(['chats', 'chatConversationBlockedCount'], 0),
});

const mapDispatchToProps = (dispatch) => ({
  onFetchChatConversationBlockedCount: () => dispatch(fetchChatConversationBlockedCount()),
});

ChatConversationBlockedListItem.propTypes = {
  blockedCount: PropTypes.number,
  onFetchChatConversationBlockedCount: PropTypes.func.isRequired,
};

ChatConversationBlockedListItem.defaultProps = {
  blockedCount: 0,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatConversationBlockedListItem);
