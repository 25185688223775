import { fromJS, Map as ImmutableMap, List as ImmutableList } from 'immutable';
import {
  CHAT_COMPOSE_MOUNT,
  CHAT_COMPOSE_UNMOUNT,
  CHAT_COMPOSE_CHANGE,
  CHAT_COMPOSE_COMPOSING_CHANGE,
  CHAT_COMPOSE_SUBMIT_REQUEST,
  CHAT_COMPOSE_UPLOAD_CHANGE_REQUEST,
  CHAT_COMPOSE_REPLY_CANCEL,
  CHAT_COMPOSE_RESET,
  CHAT_COMPOSE_SUBMIT_SUCCESS,
  CHAT_COMPOSE_SUBMIT_FAIL,
  CHAT_COMPOSE_UPLOAD_CHANGE_FAIL,
  CHAT_COMPOSE_UPLOAD_REQUEST,
  CHAT_COMPOSE_UPLOAD_SUCCESS,
  CHAT_COMPOSE_UPLOAD_FAIL,
  CHAT_COMPOSE_UPLOAD_PROGRESS,
  CHAT_COMPOSE_UPLOAD_UNDO,
  CHAT_THUMBNAIL_UPLOAD_REQUEST,
  CHAT_THUMBNAIL_UPLOAD_PROGRESS,
  CHAT_THUMBNAIL_UPLOAD_FAIL,
  CHAT_THUMBNAIL_UPLOAD_SUCCESS,
  CHAT_COMPOSE_MENTION,
  CHAT_COMPOSE_SUGGESTION_SELECT,
  CHAT_COMPOSE_TAG_HISTORY_UPDATE,
  CHAT_TIMELINE_DELETE,
  CHAT_COMPOSE_EMOJI_INSERT,
  CHAT_COMPOSE_UPLOAD_CHANGE_SUCCESS,
  CHAT_COMPOSE_SET_STATUS,
  CHAT_COMPOSE_SET_ORIGIN_MESSAGE_ID,
  CHAT_COMPOSE_SUGGESTIONS_CLEAR,
  CHAT_COMPOSE_SUGGESTIONS_READY,
  CHAT_COMPOSE_SUGGESTION_TAGS_UPDATE,
} from '../actions/chat_compose';
import {
  CHAT_CONVERSATION_APPROVED_SEARCH_FETCH_SUCCESS,
  CHAT_CONVERSATIONS_APPROVED_FETCH_SUCCESS,
  CHAT_CONVERSATIONS_APPROVED_EXPAND_SUCCESS,
  CHAT_CONVERSATIONS_REQUESTED_FETCH_SUCCESS,
  CHAT_CONVERSATIONS_REQUESTED_EXPAND_SUCCESS,
  CHAT_CONVERSATIONS_MUTED_FETCH_SUCCESS,
  CHAT_CONVERSATIONS_MUTED_EXPAND_SUCCESS,
  CHAT_CONVERSATIONS_HIDDEN_FETCH_SUCCESS,
  CHAT_CONVERSATIONS_HIDDEN_EXPAND_SUCCESS,
} from '../actions/chat_conversations';
import uuid from '../uuid';

const initialState = ImmutableMap();

const importChatConversation = (state, chatConversation) => {
  if (state.get(chatConversation.chat_conversation_id)) return;

  state.set(chatConversation.chat_conversation_id, fromJS({
    mounted: 0,
    id: null,
    text: '',
    focusDate: null,
    caretPosition: null,
    preselectDate: null,
    in_reply_to: null,
    is_composing: false,
    is_submitting: false,
    is_changing_upload: false,
    is_uploading: false,
    progress: 0,
    isUploadingThumbnail: false,
    thumbnailProgress: 0,
    media_attachments: ImmutableList(),
    pending_media_attachments: 0,
    suggestion_token: null,
    suggestions: ImmutableList(),
    resetFileKey: Math.floor((Math.random() * 0x10000)),
    idempotencyKey: null,
    tagHistory: ImmutableList(),
    originMessageId: null,
  }));
};

const importChatConversations = (state, chatConversations) => {
  if (!Array.isArray(chatConversations)) return state;

  return state.withMutations((mutable) => chatConversations.forEach(chatConversation => importChatConversation(mutable, chatConversation)));
};

const clear = (state, chatConversationId) => {
  return state.set(chatConversationId, fromJS({
    mounted: 0,
    id: null,
    text: '',
    focusDate: null,
    caretPosition: null,
    preselectDate: null,
    in_reply_to: null,
    is_composing: false,
    is_submitting: false,
    is_changing_upload: false,
    is_uploading: false,
    progress: 0,
    isUploadingThumbnail: false,
    thumbnailProgress: 0,
    media_attachments: ImmutableList(),
    pending_media_attachments: 0,
    suggestion_token: null,
    suggestions: ImmutableList(),
    resetFileKey: Math.floor((Math.random() * 0x10000)),
    idempotencyKey: null,
    tagHistory: ImmutableList(),
    originMessageId: null,
  }));
};

const insertEmoji = (state, position, emojiData, needsSpace, chatConversationId) => {
  const oldText = state.getIn([chatConversationId, 'text']);
  const emoji = needsSpace ? ' ' + emojiData.native : emojiData.native;

  return state.mergeIn([chatConversationId], {
    text: `${oldText.slice(0, position)}${emoji} ${oldText.slice(position)}`,
    focusDate: new Date(),
    caretPosition: position + emoji.length + 1,
    idempotencyKey: uuid(),
  });
};

const sortHashtagsByUse = (state, tags, chatConversationId) => {
  const personalHistory = state.getIn([chatConversationId, 'tagHistory']);

  return tags.sort((a, b) => {
    const usedA = personalHistory.includes(a.name);
    const usedB = personalHistory.includes(b.name);

    if (usedA === usedB) {
      return 0;
    } else if (usedA && !usedB) {
      return -1;
    } else {
      return 1;
    }
  });
};

const insertSuggestion = (state, position, token, completion, path, chatConversationId) => {
  return state.withMutations(map => {
    map.updateIn([chatConversationId, ...path], oldText => `${oldText.slice(0, position)}${completion} ${oldText.slice(position + token.length)}`);
    map.setIn([chatConversationId, 'suggestion_token'], null);
    map.setIn([chatConversationId, 'suggestions'], ImmutableList());
    if (path.length === 1 && path[0] === 'text') {
      map.setIn([chatConversationId, 'focusDate'], new Date());
      map.setIn([chatConversationId, 'caretPosition'], position + completion.length + 1);
    }
    map.setIn([chatConversationId, 'idempotencyKey'], uuid());
  });
};

const mergeLocalHashtagResults = (suggestions, prefix, tagHistory) => {
  prefix = prefix.toLowerCase();
  if (suggestions.length < 4) {
    const localTags = tagHistory.filter(tag => tag.toLowerCase().startsWith(prefix) && !suggestions.some(suggestion => suggestion.type === 'hashtag' && suggestion.name.toLowerCase() === tag.toLowerCase()));
    return suggestions.concat(localTags.slice(0, 4 - suggestions.length).toJS().map(tag => ({ type: 'hashtag', name: tag })));
  } else {
    return suggestions;
  }
};

const normalizeSuggestions = (state, { accounts, emojis, tags, token, chatConversationId }) => {
  if (accounts) {
    return accounts.map(item => ({ id: item.id, type: 'account' }));
  } else if (emojis) {
    return emojis.map(item => ({ ...item, type: 'emoji' }));
  } else {
    return mergeLocalHashtagResults(
      sortHashtagsByUse(state, tags.map(item => ({ ...item, type: 'hashtag' })), chatConversationId),
      token.slice(1),
      state.getIn([chatConversationId, 'tagHistory']),
    );
  }
};

function appendMedia(state, media, file, chatConversationId) {
  return state.withMutations(map => {
    if (media.get('type') === 'image') {
      media = media.set('file', file);
    }
    map.updateIn([chatConversationId, 'media_attachments'], list => list.push(media));
    map.setIn([chatConversationId, 'is_uploading'], false);
    map.setIn([chatConversationId, 'resetFileKey'], Math.floor((Math.random() * 0x10000)));
    map.setIn([chatConversationId, 'idempotencyKey'], uuid());
    map.updateIn([chatConversationId, 'pending_media_attachments'], n => n - 1);
  });
};

function removeMedia(state, mediaId, chatConversationId) {
  return state.withMutations(map => {
    map.updateIn([chatConversationId, 'media_attachments'], list => list.filterNot(item => item.get('id') === mediaId));
    map.setIn([chatConversationId, 'idempotencyKey'], uuid());
  });
};

const updateSuggestionTags = (state, token, chatConversationId) => {
  const prefix = token.slice(1);

  const suggestions = state.getIn([chatConversationId, 'suggestions']).toJS();
  return state.mergeIn([chatConversationId], {
    suggestions: ImmutableList(mergeLocalHashtagResults(suggestions, prefix, state.getIn([chatConversationId, 'tagHistory']))),
    suggestion_token: token,
  });
};

export default function chat_compose(state = initialState, action) {
  switch(action.type) {
  case CHAT_CONVERSATIONS_APPROVED_FETCH_SUCCESS:
  case CHAT_CONVERSATIONS_APPROVED_EXPAND_SUCCESS:
  case CHAT_CONVERSATIONS_REQUESTED_FETCH_SUCCESS:
  case CHAT_CONVERSATIONS_REQUESTED_EXPAND_SUCCESS:
  case CHAT_CONVERSATIONS_MUTED_FETCH_SUCCESS:
  case CHAT_CONVERSATIONS_MUTED_EXPAND_SUCCESS:
  case CHAT_CONVERSATIONS_HIDDEN_FETCH_SUCCESS:
  case CHAT_CONVERSATIONS_HIDDEN_EXPAND_SUCCESS:
  case CHAT_CONVERSATION_APPROVED_SEARCH_FETCH_SUCCESS:
    return importChatConversations(state, action.chatConversations);
  case CHAT_COMPOSE_SET_ORIGIN_MESSAGE_ID:
    return state.setIn([action.chatConversationId, 'originMessageId'], action.value);
  case CHAT_COMPOSE_REPLY_CANCEL:
    return state.setIn([action.chatConversationId, 'originMessageId'], null);
  case CHAT_COMPOSE_MOUNT:
    return state.set('mounted', state.get('mounted') + 1);
  case CHAT_COMPOSE_UNMOUNT:
    return state
      .set('mounted', Math.max(state.get('mounted') - 1, 0))
      .set('is_composing', false);
  case CHAT_COMPOSE_CHANGE:
    return state
      .setIn([action.chatConversationId, 'text'], action.text)
      .setIn([action.chatConversationId, 'idempotencyKey'], uuid());
  case CHAT_COMPOSE_COMPOSING_CHANGE:
    return state.set('is_composing', action.value);
  case CHAT_COMPOSE_SUBMIT_REQUEST:
    return state.setIn([action.chatConversationId, 'is_submitting'], true);
  case CHAT_COMPOSE_UPLOAD_CHANGE_REQUEST:
    return state.setIn([action.chatConversationId, 'is_changing_upload'], true);
  case CHAT_COMPOSE_REPLY_CANCEL:
  case CHAT_COMPOSE_RESET:
  case CHAT_COMPOSE_SUBMIT_SUCCESS:
    return clear(state, action.chatConversationId);
  case CHAT_COMPOSE_SUBMIT_FAIL:
    return state.setIn([action.chatConversationId, 'is_submitting'], false);
  case CHAT_COMPOSE_UPLOAD_CHANGE_FAIL:
    return state.setIn([action.chatConversationId, 'is_changing_upload'], false);
  case CHAT_COMPOSE_UPLOAD_REQUEST:
    return state
      .setIn([action.chatConversationId, 'is_uploading'], true)
      .updateIn([action.chatConversationId, 'pending_media_attachments'], n => n + 1);
  case CHAT_COMPOSE_UPLOAD_SUCCESS:
    return appendMedia(state, fromJS(action.media), action.file, action.chatConversationId);
  case CHAT_COMPOSE_UPLOAD_FAIL:
    return state
      .setIn([action.chatConversationId, 'is_uploading'], false)
      .update([action.chatConversationId, 'pending_media_attachments'], n => n - 1);
  case CHAT_COMPOSE_UPLOAD_PROGRESS:
    return state.setIn([action.chatConversationId, 'progress'], Math.round((action.loaded / action.total) * 100));
  case CHAT_COMPOSE_UPLOAD_UNDO:
    return removeMedia(state, action.media_id, action.chatConversationId);
  case CHAT_THUMBNAIL_UPLOAD_REQUEST:
    return state.set('isUploadingThumbnail', true);
  case CHAT_THUMBNAIL_UPLOAD_PROGRESS:
    return state.set('thumbnailProgress', Math.round((action.loaded / action.total) * 100));
  case CHAT_THUMBNAIL_UPLOAD_FAIL:
    return state.set('isUploadingThumbnail', false);
  case CHAT_THUMBNAIL_UPLOAD_SUCCESS:
    return state
      .set('isUploadingThumbnail', false)
      .update('media_attachments', list => list.map(item => {
        if (item.get('id') === action.media.id) {
          return fromJS(action.media);
        }

        return item;
      }));
  case CHAT_COMPOSE_MENTION:
    return state.withMutations(map => {
      map.update('text', text => [text.trim(), `@${action.account.get('acct')} `].filter((str) => str.length !== 0).join(' '));
      map.set('focusDate', new Date());
      map.set('caretPosition', null);
      map.set('idempotencyKey', uuid());
    });
  case CHAT_COMPOSE_SUGGESTIONS_CLEAR:
    return state.updateIn([action.chatConversationId, 'suggestions'], ImmutableList(), list => list.clear()).set('suggestion_token', null);
  case CHAT_COMPOSE_SUGGESTIONS_READY:
    return state.setIn([action.chatConversationId, 'suggestions'], ImmutableList(normalizeSuggestions(state, action))).set('suggestion_token', action.token);
  case CHAT_COMPOSE_SUGGESTION_SELECT:
    return insertSuggestion(state, action.position, action.token, action.completion, action.path, action.chatConversationId);
  case CHAT_COMPOSE_SUGGESTION_TAGS_UPDATE:
    return updateSuggestionTags(state, action.token, action.chatConversationId);
  case CHAT_COMPOSE_TAG_HISTORY_UPDATE:
    return state.set('tagHistory', fromJS(action.tags));
  case CHAT_TIMELINE_DELETE:
    if (action.id === state.get('in_reply_to')) {
      return state.set('in_reply_to', null);
    } else {
      return state;
    }
  case CHAT_COMPOSE_EMOJI_INSERT:
    return insertEmoji(state, action.position, action.emoji, action.needsSpace, action.chatConversationId);
  case CHAT_COMPOSE_UPLOAD_CHANGE_SUCCESS:
    return state
      .set('is_changing_upload', false)
      .update('media_attachments', list => list.map(item => {
        if (item.get('id') === action.media.id) {
          return fromJS(action.media);
        }

        return item;
      }));
  case CHAT_COMPOSE_SET_STATUS:
    return state.withMutations(map => {
      map.set('id', action.status.get('id'));
      map.set('text', action.text);
      map.set('in_reply_to', action.status.get('in_reply_to_id'));
      map.set('media_attachments', action.status.get('media_attachments'));
      map.set('focusDate', new Date());
      map.set('caretPosition', null);
      map.set('idempotencyKey', uuid());
      map.set('sensitive', action.status.get('sensitive'));
    });
  default:
    return state;
  }
}
