import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';

export default @injectIntl
class FollowingAccountsModal extends React.PureComponent {

  static propTypes = {
    onClose: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    status: ImmutablePropTypes.map,
  };

  state = {
    followings: [],
  };

  componentDidMount() {
    this.getFollowing();
  }

  getFollowing = () => {
    const { status } = this.props;
    const accounts = status.getIn(['following_accounts', 'accounts']);

    const uniqueFollowing =
      accounts.reduce((unique, account) => {
        if (unique.findIndex(r => r.get('id') === account.get('id')) === -1) {
          unique.push(account);
        }
        return unique;
      }, []);

    this.setState({
      followings: uniqueFollowing,
    });
  };

  handleCancel = () => {
    this.props.onClose();
  };

  getHeaderText = () => {
    const title = this.props.title;
    return `Following ${title}`;
  }

  render() {
    const { followings } = this.state;

    return (
      <div className='modal-root__modal favourites-modal'>
        <div className='favourites-modal__container'>
          <div className='favourites-modal__header'>
            <div className='d-flex justify-content-space-between'>
              <span>{this.getHeaderText()}</span>
              <span>
                <i
                  className='fa fa-times favourites-modal__close'
                  onClick={this.handleCancel}
                  role='button' tabIndex={0}
                />
              </span>
            </div>
          </div>
          <div className='favourites-modal__body'>
            {followings.map(following => (
              <a
                href={following.get('url')}
                key={following.get('id')}
                className='favourites-modal__favourite-item'
              >
                <div className='d-flex'>
                  <div
                    className='favourites-modal__favourite-avatar mr2'
                    style={{
                      backgroundImage: `url(${following.get('avatar')})`,
                    }}
                  />
                  <strong>{following.get('username')}</strong>
                  <span> </span>
                  <span>@{following.get('acct')}</span>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    );
  }

}
