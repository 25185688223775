import React from 'react';
import PropTypes from 'prop-types';
import { getScrollbarWidth } from 'brighteon/utils/scrollbar';
import Base from 'brighteon/components/modal_root';
import BundleContainer from '../containers/bundle_container';
import BundleModalError from './bundle_modal_error';
import ModalLoading from './modal_loading';
import ActionsModal from './actions_modal';
import MediaModal from './media_modal';
import VideoModal from './video_modal';
import BoostModal from './boost_modal';
import AudioModal from './audio_modal';
import ComposeModal from './compose_modal';
import ConfirmationModal from './confirmation_modal';
import FocalPointModal from './focal_point_modal';
import RepliesModal from './replies_modal';
import ReblogsModal from './reblogs_modal';
import FavouritesModal from './favourites_modal';
import FollowingAccountsModal from './following_accounts_modal';
import OtherBoostedAccountsModal from './other_boosted_accounts_modal';
import OtherRepliedAccountsModal from './other_replied_accounts_modal';
import OtherFavoritedAccountsModal from './other_favorited_accounts_modal';
import OtherFollowingAccountsModal from './other_following_accounts_modal';
import NotificationsOtherBoostedAccountsModal from './notifications_other_boosted_accounts_modal';
import NotificationsOtherFavoritedAccountsModal from './notifications_other_favorited_accounts_modal';
import NotificationsOtherFollowedAccountsModal from './notifications_other_followed_accounts_modal';
import NotificationsOtherRepliedAccountsModal from './notifications_other_replied_accounts_modal';
import NotificationsModal from './notifications_modal';

import {
  MuteModal,
  BlockModal,
  ReportModal,
  EmbedModal,
  ListEditor,
  ListAdder,
  ChatConversationCreateModal,
  ChatConversationMembersModal,
  ConfirmationModalGab,
  PostShareModal,
  TipCryptoModal,
} from '../../../features/ui/util/async-components';

const MODAL_COMPONENTS = {
  'MEDIA': () => Promise.resolve({ default: MediaModal }),
  'VIDEO': () => Promise.resolve({ default: VideoModal }),
  'AUDIO': () => Promise.resolve({ default: AudioModal }),
  'BOOST': () => Promise.resolve({ default: BoostModal }),
  'CONFIRM': () => Promise.resolve({ default: ConfirmationModal }),
  'COMPOSE': () => Promise.resolve({ default: ComposeModal }),
  'MUTE': MuteModal,
  'BLOCK': BlockModal,
  'REPORT': ReportModal,
  'ACTIONS': () => Promise.resolve({ default: ActionsModal }),
  'EMBED': EmbedModal,
  'LIST_EDITOR': ListEditor,
  'FOCAL_POINT': () => Promise.resolve({ default: FocalPointModal }),
  'LIST_ADDER': ListAdder,
  'REPLIES': () => Promise.resolve({ default: RepliesModal }),
  'REBLOGS': () => Promise.resolve({ default: ReblogsModal }),
  'FAVOURITES': () => Promise.resolve({ default: FavouritesModal }),
  'FOLLOWING_ACCOUNTS': () => Promise.resolve({ default: FollowingAccountsModal }),
  'OTHER_BOOSTED_ACCOUNTS': () => Promise.resolve({ default: OtherBoostedAccountsModal }),
  'OTHER_REPLIED_ACCOUNTS': () => Promise.resolve({ default: OtherRepliedAccountsModal }),
  'OTHER_FAVORITED_ACCOUNTS': () => Promise.resolve({ default: OtherFavoritedAccountsModal }),
  'OTHER_FOLLOWING_ACCOUNTS': () => Promise.resolve({ default: OtherFollowingAccountsModal }),
  'NOTIFICATIONS_OTHER_BOOSTED_ACCOUNTS': () => Promise.resolve({ default: NotificationsOtherBoostedAccountsModal }),
  'NOTIFICATIONS_OTHER_FAVORITED_ACCOUNTS': () => Promise.resolve({ default: NotificationsOtherFavoritedAccountsModal }),
  'NOTIFICATIONS_OTHER_FOLLOWED_ACCOUNTS': () => Promise.resolve({ default: NotificationsOtherFollowedAccountsModal }),
  'NOTIFICATIONS_OTHER_REPLIED_ACCOUNTS': () => Promise.resolve({ default: NotificationsOtherRepliedAccountsModal }),
  'NOTIFICATIONS': () => Promise.resolve({ default: NotificationsModal }),
  'CHAT_LIST': PostShareModal,
  'CHAT_CONVERSATION_CREATE': ChatConversationCreateModal,
  'CHAT_CONVERSATION_MEMBERS': ChatConversationMembersModal,
  'GAB_CONFIRM': ConfirmationModalGab,
  'TIP_CRYPTO': TipCryptoModal,
};

export default class ModalRoot extends React.PureComponent {

  static propTypes = {
    type: PropTypes.string,
    props: PropTypes.object,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    backgroundColor: null,
  };

  getSnapshotBeforeUpdate() {
    return { visible: !!this.props.type };
  }

  componentDidUpdate(prevProps, prevState, { visible }) {
    if (visible) {
      document.body.classList.add('with-modals--active');
      document.documentElement.style.marginRight = `${getScrollbarWidth()}px`;
    } else {
      document.body.classList.remove('with-modals--active');
      document.documentElement.style.marginRight = 0;
    }
  }

  setBackgroundColor = color => {
    this.setState({ backgroundColor: color });
  }

  renderLoading = modalId => () => {
    return ['MEDIA', 'VIDEO', 'BOOST', 'CONFIRM', 'ACTIONS'].indexOf(modalId) === -1 ? <ModalLoading /> : null;
  }

  renderError = (props) => {
    const { onClose } = this.props;

    return <BundleModalError {...props} onClose={onClose} />;
  }

  render() {
    const { type, props, onClose } = this.props;
    const { backgroundColor } = this.state;
    const visible = !!type;

    return (
      <Base backgroundColor={backgroundColor} onClose={onClose}>
        {visible && (
          <BundleContainer fetchComponent={MODAL_COMPONENTS[type]} loading={this.renderLoading(type)} error={this.renderError} renderDelay={200}>
            {(SpecificComponent) => <SpecificComponent {...props} onChangeBackgroundColor={this.setBackgroundColor} onClose={onClose} />}
          </BundleContainer>
        )}
      </Base>
    );
  }

}
