import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Define sizes for enumeration for Heading component `size` prop
const SIZES = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
};

const ARIA_LEVELS = {
  h1: '1',
  h2: '2',
  h3: '3',
  h4: '4',
  h5: '5',
  h6: '6',
};

/**
 * Renders an H-tag
 * @param {bool} [props.isCentered] - if text is centered within the element
 * @param {string} [props.size='h1'] - the size of the heading
 */
class Heading extends React.PureComponent {

  render() {
    const { children, size, isCentered } = this.props;

    // Each size has it's own custom style
    const classes = classNames({
      'd': 1,
      'text': 1,
      'text-align-left': !isCentered,
      'text-align-center': isCentered,

      'font-size-24px': size === SIZES.h1,
      'font-size-19px': size === SIZES.h2,
      'font-size-16px': size === SIZES.h3,
      'font-size-13px': size === SIZES.h4,
      'font-size-12px': size === SIZES.h5,

      'mt5px': [SIZES.h4].indexOf(size) > -1,

      'line-height-2': size === SIZES.h5,
      'py2px': size === SIZES.h5,

      'font-weight-500': [SIZES.h1, SIZES.h3, SIZES.h5].indexOf(size) > -1,
      'font-weight-600': [SIZES.h2, SIZES.h4].indexOf(size) > -1,
    });

    return React.createElement(
      size,
      {
        className: classes,
        role: 'heading',
        'aria-level': ARIA_LEVELS[size],
      },
      children,
    );
  }

}

Heading.propTypes = {
  children: PropTypes.any,
  isCentered: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(SIZES)),
};

Heading.defaultProps = {
  size: SIZES.h1,
};

export default Heading;