import { fromJS, Map as ImmutableMap, List as ImmutableList } from 'immutable';
import {
  UNCENSORED_NEWS_FEED_FETCH_REQUEST,
  UNCENSORED_NEWS_FEED_FETCH_SUCCESS,
  UNCENSORED_NEWS_FEED_FETCH_FAIL,
  UNCENSORED_NEWS_FEED_EXPAND_SUCCESS,
  UNCENSORED_NEWS_FEED_EXPAND_REQUEST,
  UNCENSORED_NEWS_FEED_EXPAND_FAIL,
  UNCENSORED_NEWS_FEED_EXPAND_END_SUCCESS,
} from '../actions/uncensored_news_feed';

const initialState = ImmutableMap({
  articles: ImmutableList(),
  isLoading: true,
  hasMore: true,
  page: 1,
});

export default function uncensoredNewsFeed(state = initialState, action) {
  switch (action.type) {
  case UNCENSORED_NEWS_FEED_FETCH_REQUEST:
    return state.set('isLoading', true);
  case UNCENSORED_NEWS_FEED_FETCH_SUCCESS:
    return state
      .set('articles', fromJS(action.articles))
      .set('isLoading', false);
  case UNCENSORED_NEWS_FEED_FETCH_FAIL:
    return state.set('isLoading', false);
  case UNCENSORED_NEWS_FEED_EXPAND_REQUEST:
    return state.set('isLoading', true);
  case UNCENSORED_NEWS_FEED_EXPAND_SUCCESS:
    return state
      .set('page', action.page)
      .set('isLoading', false)
      .update('articles', articles => articles.concat(fromJS(action.articles)));
  case UNCENSORED_NEWS_FEED_EXPAND_FAIL:
    return state.set('isLoading', false);
  case UNCENSORED_NEWS_FEED_EXPAND_END_SUCCESS:
    return state
      .set('isLoading', false)
      .set('hasMore', false);
  default:
    return state;
  }
};
