import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import Icon from './icon';
import Text from './text';

class ColumnIndicator extends React.PureComponent {

  render() {
    const { type, message, intl } = this.props;

    const title = type !== 'error' && !message ? intl.formatMessage(messages[type]) : message;

    return (
      <div className={'d-block w-100 jc-center ai-center py-15 px-10'}>
        <Icon id={type} size='30px' className={'c-primary'} />
        {
          type !== 'loading' &&
          <Text
            align='center'
            size='medium'
            className={'mt-10'}
          >
            {title}
          </Text>
        }
      </div>
    );
  }

}

const messages = defineMessages({
  loading: { id: 'loading_indicator.label', defaultMessage: 'Loading..' },
  missing: { id: 'missing_indicator.sublabel', defaultMessage: 'This resource could not be found.' },
});

ColumnIndicator.propTypes = {
  intl: PropTypes.object.isRequired,
  type: PropTypes.oneOf([
    'loading',
    'missing',
    'error',
  ]),
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default injectIntl(ColumnIndicator);