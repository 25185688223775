import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Icon extends React.PureComponent {

  constructor(props){
    super(props);
    this.ref = React.createRef();
    this.state = { useBackgroundIcon : !props.useFontAwesome };

  }

  static propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    size: PropTypes.number,
    marginAuto: PropTypes.bool,
    fixedWidth: PropTypes.bool,
    useFontAwesome: PropTypes.bool,
  };

  componentDidMount() {
    if (this.props.useFontAwesome || !this.ref.current) {
      return;
    }
    if (window.getComputedStyle(this.ref.current).backgroundImage === 'none') {
      this.setState({ useBackgroundIcon: false });
    }

  }
  render() {
    const { id, className, size, marginAuto, fixedWidth, ...other } = this.props;

    if(!this.state.useBackgroundIcon){
      return (
        <i role='img' className={classNames('fa', `fa-${id}`, className, { 'fa-fw': fixedWidth })} />
      );
    }

    const iconStyle = {
      backgroundSize: `${size}px ${size}px`,
      width: `${size}px`,
      height: `${size}px`,
      margin: marginAuto ? 'auto' : '',
    };

    return (
      <div
        ref={this.ref}
        className={classNames(`icon-${id} button-icon`, className)}
        style={iconStyle}
        {...other}
      />
    );
  }

}
