import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { shortNumberFormat } from '../../../utils/numbers';
import { fetchChatConversationRequestedCount } from '../../../actions/chat_conversations';
import Text from '../other_components/text';
import Icon from '../../../components/icon';

class ChatConversationRequestsListItem extends React.PureComponent {

  componentDidMount() {
    this.props.onFetchChatConversationRequestedCount();
  }

  render() {
    const { requestCount } = this.props;

    if (!requestCount || requestCount < 1) return null;

    return (
      <NavLink
        className={'d w-100 bg-transparent bg-subtle-onhover border-bottom-medium1-1 decoration-none outline-none cursor-pointer'}
        to='/messages/requests'
      >
        <div className={'d px15px py15px align-items-center flex-row color-black'}>
          <Text size='medium'>Message Requests</Text>
          <Text size='medium' className='ml-auto mr15px' color='black'>
            {shortNumberFormat(requestCount)}
          </Text>
          <Icon id='angle-right' size={10} className={''} />
        </div>
      </NavLink>
    );
  }

}


const mapStateToProps = (state) => ({
  requestCount: state.getIn(['chats', 'chatConversationRequestCount'], 0),
});

const mapDispatchToProps = (dispatch) => ({
  onFetchChatConversationRequestedCount: () => dispatch(fetchChatConversationRequestedCount()),
});

ChatConversationRequestsListItem.propTypes = {
  requestCount: PropTypes.number,
  onFetchChatConversationRequestedCount: PropTypes.func.isRequired,
};

ChatConversationRequestsListItem.defaultProps = {
  requestCount: 0,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatConversationRequestsListItem);
