import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Avatar from './avatar';

/**
 * Renders a inline group of avatars
 * @version 1.0.0
 */
const AvatarGroup = ({ accounts, avatarCount, size, maxVisible, showText }) => {
  if (!accounts) {
    return null;
  }

  const count = avatarCount || accounts.size;
  let restOne = '';
  if ((count > maxVisible) && showText) {
    const style = {
      width: size + 'px',
      height: size+ 'px',
    };
    const containerClasses = classNames({
      'circle border-medium1-2 bg-light1 z0 d-flex align-items-center justify-content-center border-box': 1,
      'ml-neg-25px': size >= 40,
      'ml-neg-15px': size < 40,
    });
    restOne = (<span className={containerClasses} style={style}>+{count - maxVisible + 1}</span>);
  } else if (count === maxVisible) {
    const account = accounts.slice(maxVisible - 1, maxVisible);
    const containerClasses = classNames({
      'd': 1,
      'ml-neg-25px': size >= 40,
      'ml-neg-15px': size < 40,
      'max-circle': 1,
      'border-2px': 1,
      borderColorSecondary: 1,
    });
    restOne = (
      <div key={`grouped-avatar-${maxVisible}`} className={containerClasses} >
        <Avatar account={account.get(0)} size={size} />
      </div>
    );
  }

  return (
    // eslint-disable-next-line array-element-newline
    <div className={'d flex-row align-items-center'}>
      {accounts.slice(0, maxVisible - 1).map((account, i) => {
        const containerClasses = classNames({
          'd': 1,
          'ml-neg-25px': i !== 0 && size >= 40,
          'ml-neg-15px': i !== 0 && size < 40,
          'max-circle': 1,
          'border-2px': 1,
          borderColorSecondary: 1,
        });
        return (
          <div key={`grouped-avatar-${i}`} className={containerClasses} >
            <Avatar account={account} size={size} />
          </div>
        );
      })}
      {count >= maxVisible && showText && (
        restOne
      )}
    </div>
  );
};

AvatarGroup.defaultProps = {
  maxVisible: 3,
  size: 40,
};

AvatarGroup.propTypes = {
  accounts: PropTypes.any,
  avatarCount: PropTypes.number,
  maxVisible: PropTypes.number,
  size: PropTypes.number,
  showText: PropTypes.bool,
};

export default AvatarGroup;
