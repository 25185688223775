import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MODAL_MEDIA } from '../../../constants';
import classNames from 'classnames';
import { openModal } from '../../../actions/modal';

class Image extends React.PureComponent {

  state = {
    error: false,
  }

  handleOnError = () => {
    this.setState({ error: true });
  }

  render() {
    const {
      alt,
      src,
      fit,
      className,
      nullable,
      isLazy,
      imageRef,
      expandOnClick,
      width,
      height,
    } = this.props;
    const { error } = this.state;

    const classes = classNames(className, {
      'd': 1,
      'objectFitCover': !!src && fit === 'cover',
      'bgSecondary': !src,
      'cursor-pointer': expandOnClick,
    });

    //If error and not our own image
    if (error && nullable) {
      return null;
    }

    if (!src) {
      return <div className={classes} />;
    }

    return (
      <img
        alt={alt}
        className={classes}
        ref={imageRef}
        src={src}
        onError={this.handleOnError}
        loading={isLazy ? 'lazy' : undefined}
        width={width}
        height={height}
        onLoad={this.props.onLoad}
      />
    );
  }

}

const mapDispatchToProps = (dispatch, { alt, src, expandOnClick }) => ({
  onOpenMediaModal() {
    if (expandOnClick) {
      dispatch(openModal(MODAL_MEDIA, { alt, src }));
    }
  },
});

Image.propTypes = {
  alt: PropTypes.string,
  isLazy: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  fit: PropTypes.oneOf(['contain', 'cover', 'tile', 'none']),
  nullable: PropTypes.bool,
  lazy: PropTypes.bool,
  imageRef: PropTypes.func,
  expandOnClick: PropTypes.bool,
  onOpenMediaModal: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onLoad: PropTypes.func,
  src: PropTypes.string,
};

Image.defaultProps = {
  width: '100%',
  fit: 'cover',
};

export default connect(null, mapDispatchToProps)(Image);
