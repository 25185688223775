import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Icon from '../../../components/icon';
import { profile_directory, me } from 'brighteon/initial_state';
import NotificationsCounterIcon from './notifications_counter_icon';
import FollowRequestsNavLink from '../../../../brighteon/features/ui/components/follow_requests_nav_link';

class LeftSideMenu extends React.Component {

  static propTypes = {
    closeMenu: PropTypes.func.isRequired,
    onOpenNewPostModal: PropTypes.func.isRequired,
  };

  handleCloseMenu = () => {
    this.props.closeMenu();
  }

  handleNewPostModal = (e) => {
    e.stopPropagation();
    this.props.closeMenu();
    this.props.onOpenNewPostModal();
  }

  render() {
    if (me) {
      return (
        <div className='navigation-panel- left-side-menu'>
          <div className='column-menu'>
            Menu
          </div>
          <a
            className='column-link column-link--transparent' href='/web/timelines/home'
            data-preview-title-id='column.home' data-preview-icon='home'
          >
            <Icon className='column-link__icon left-menu-icon' id='home' />
            <FormattedMessage id='tabs_bar.home' defaultMessage='Home' />
          </a>

          <div
            className='column-link column-link--transparent cursor-pointer' href='#'
            data-preview-title-id='column.new_post' data-preview-icon='new-post' onClick={this.handleNewPostModal}
            role='button' tabIndex={0}
          >
            <Icon className='column-link__icon left-menu-icon' id='new-post' />
            <FormattedMessage id='tabs_bar.new_post' defaultMessage='New Post' />
          </div>

          <a
            className='column-link column-link--transparent' href='/web/notifications'
            data-preview-title-id='column.notifications' data-preview-icon='bell'
          >
            <NotificationsCounterIcon className='column-link__icon left-menu-icon' />
            <FormattedMessage id='tabs_bar.notifications' defaultMessage='Notifications' />
          </a>

          <FollowRequestsNavLink />
          <a className='column-link column-link--transparent' href='/web/messages'>
            <Icon className='column-link__icon left-menu-icon' id='envelope' />
            <FormattedMessage id='navigation_bar.direct' defaultMessage='Direct messages' />
          </a>

          <a className='column-link column-link--transparent' href='/web/settings/relationships' >
            <Icon className='column-link__icon left-menu-icon' id='users' />
            <FormattedMessage id='navigation_bar.follows_and_followers' defaultMessage='Follows and followers' />
          </a>

          {
            profile_directory &&
            <a className='column-link column-link--transparent' href='/web/directory' >
              <Icon className='column-link__icon left-menu-icon' id='address-book-o' />
              <FormattedMessage id='getting_started.directory' defaultMessage='Connect' />
            </a>
          }

          <a className='column-link column-link--transparent' href='/web/favourites'>
            <Icon className='column-link__icon left-menu-icon' id='star' />
            <FormattedMessage id='navigation_bar.favourites' defaultMessage='Favourites' />
          </a>

          <a className='column-link column-link--transparent' href='/web/bookmarks'>
            <Icon className='column-link__icon left-menu-icon' id='bookmark' />
            <FormattedMessage id='navigation_bar.bookmarks' defaultMessage='Bookmarks' />
          </a>

          <a className='column-link column-link--transparent' href='/web/brighteon_feed'>
            <Icon className='column-link__icon left-menu-icon' id='brighteon-feed' />
            <FormattedMessage id='navigation_bar.brighteon_feed' defaultMessage='Brighteon Feed' />
          </a>

          <a className='column-link column-link--transparent' href='https://announcements.brighteon.social'>
            <Icon className='column-link__icon left-menu-icon' id='announcements' />
            <FormattedMessage id='navigation_bar.announcements' defaultMessage='Announcements' />
          </a>

          <a className='column-link column-link--transparent' href='/web/lists'>
            <Icon className='column-link__icon left-menu-icon' id='list-ul' />
            <FormattedMessage id='navigation_bar.lists' defaultMessage='Lists' />
          </a>

          <a className='column-link column-link--transparent' href='/redirect_me'>
            <Icon className='column-link__icon left-menu-icon' id='profile' />
            <FormattedMessage id='navigation_bar.profile' defaultMessage='Profile' />
          </a>

          <hr />

          <div className='column-menu'>
            Explore
          </div>

          <a className='column-link column-link--transparent' href='/web/timelines/public' target='_self' rel='noopener noreferrer' >
            <Icon className='column-link__icon left-menu-icon' id='live-stream' />
            <FormattedMessage id='navigation_bar.live_stream' defaultMessage='Live Stream' />
          </a>

          <a className='column-link column-link--transparent' href='/web/timelines/trend' target='_self' rel='noopener noreferrer' >
            <Icon className='column-link__icon left-menu-icon' id='trending' />
            <FormattedMessage id='navigation_bar.trends' defaultMessage='Trending' />
          </a>

          <a className='column-link column-link--transparent' href='/web/timelines/news' target='_self' rel='noopener noreferrer' >
            <Icon className='column-link__icon left-menu-icon' id='news' />
            <FormattedMessage id='navigation_bar.uncensored_news_feed' defaultMessage='Uncensored News' />
          </a>

          <a className='column-link column-link--transparent' href='http://www.brighteonstore.com' target='_blank' rel='noopener noreferrer' onClick={this.handleCloseMenu}>
            <Icon className='column-link__icon left-menu-icon' id='shop' /><FormattedMessage id='navigation_bar.shop' defaultMessage='Shop' />
          </a>

          <a className='column-link column-link--transparent' href='http://www.brighteonbooks.com' target='_blank' rel='noopener noreferrer' onClick={this.handleCloseMenu}>
            <Icon className='column-link__icon left-menu-icon' id='website' /><FormattedMessage id='navigation_bar.books' defaultMessage='Brighteon Books' />
          </a>

          <hr />

          <div className='column-menu'>
            Uncensored News
          </div>

          <a className='column-link column-link--transparent' href='http://brighteon.com' target='_blank' rel='noopener noreferrer' onClick={this.handleCloseMenu}>
            <Icon className='column-link__icon left-menu-icon' id='website' /><FormattedMessage id='navigation_bar.brighteon' defaultMessage='Brighteon.com' />
          </a>
          <a className='column-link column-link--transparent' href='http://brighteon.tv' target='_blank' rel='noopener noreferrer' onClick={this.handleCloseMenu}>
            <Icon className='column-link__icon left-menu-icon' id='website' /><FormattedMessage id='navigation_bar.brighteon_tv' defaultMessage='Brighteon.TV' />
          </a>
          <a className='column-link column-link--transparent' href='http://brighteonradio.com' target='_blank' rel='noopener noreferrer' onClick={this.handleCloseMenu}>
            <Icon className='column-link__icon left-menu-icon' id='website' /><FormattedMessage id='navigation_bar.brighteon_radio' defaultMessage='BrighteonRadio.com' />
          </a>
          <a className='column-link column-link--transparent' href='http://naturalNews.com' target='_blank' rel='noopener noreferrer' onClick={this.handleCloseMenu}>
            <Icon className='column-link__icon left-menu-icon' id='website' /><FormattedMessage id='navigation_bar.natural_news_company' defaultMessage='NaturalNews.com' />
          </a>
          <a className='column-link column-link--transparent' href='https://newstarget.com' target='_blank' rel='noopener noreferrer' onClick={this.handleCloseMenu}>
            <Icon className='column-link__icon left-menu-icon' id='website' /><FormattedMessage id='navigation_bar.newstarget' defaultMessage='Newstarget.com' />
          </a>

          <hr />

          <a className='column-link column-link--transparent' href='https://donation.brighteon.com' target='_blank' rel='noopener noreferrer' onClick={this.handleCloseMenu}>
            <Icon className='column-link__icon left-menu-icon' id='donate' /><FormattedMessage id='navigation_bar.donate' defaultMessage='Donate' />
          </a>

          <a className='column-link column-link--transparent' href='/help' target='_blank' rel='noopener noreferrer' onClick={this.handleCloseMenu}>
            <Icon className='column-link__icon left-menu-icon' id='help' /><FormattedMessage id='navigation_bar.help' defaultMessage='Help' />
          </a>

          <a className='column-link column-link--transparent' href='/settings/preferences' onClick={this.handleCloseMenu}>
            <Icon className='column-link__icon left-menu-icon' id='settings' /><FormattedMessage id='navigation_bar.preferences' defaultMessage='Preferences' />
          </a>
        </div>
      );
    }

    return (<div className='navigation-panel- left-side-menu'>
      <div className='column-menu'>
        Menu
      </div>
      <a className='column-link column-link--transparent' href='/web/timelines/trend' data-preview-title-id='column.home' data-preview-icon='home' onClick={this.handleCloseMenu} >
        <Icon className='column-link__icon left-menu-icon' id='home' />
        <FormattedMessage id='tabs_bar.home' defaultMessage='Home' />
      </a>

      <a className='column-link column-link--transparent' href='/web/timelines/news' target='_self' rel='noopener noreferrer' onClick={this.handleCloseMenu}>
        <Icon className='column-link__icon left-menu-icon' id='news' />
        <FormattedMessage id='navigation_bar.uncensored_news_feed' defaultMessage='Uncensored News' />
      </a>

      <a className='column-link column-link--transparent' href='/auth/sign_up'>
        <Icon className='column-link__icon left-menu-icon' id='profile' size={24} />
        <FormattedMessage id='navigation_bar.signup' defaultMessage='Sign Up' />
      </a>

      <a className='column-link column-link--transparent' href='/auth/sign_in' rel='noopener noreferrer' >
        <Icon className='column-link__icon left-menu-icon' id='signout' size={24} />
        <FormattedMessage id='navigation_bar.signin' defaultMessage='Sign In' />
      </a>

      <hr />

      <a className='column-link column-link--transparent' href='http://www.brighteonstore.com' target='_blank' rel='noopener noreferrer' >
        <Icon className='column-link__icon left-menu-icon' id='shop' />
        <FormattedMessage id='navigation_bar.shop' defaultMessage='Shop' />
      </a>

      <a className='column-link column-link--transparent' href='http://www.brighteonbooks.com' target='_blank' rel='noopener noreferrer' >
        <Icon className='column-link__icon left-menu-icon' id='website' />
        <FormattedMessage id='navigation_bar.books' defaultMessage='Brighteon Books' />
      </a>

      <hr />

      <div className='column-menu'>
        Uncensored News
      </div>

      <a className='column-link column-link--transparent' href='http://brighteon.com' target='_blank' rel='noopener noreferrer' >
        <Icon className='column-link__icon left-menu-icon' id='website' />
        <FormattedMessage id='navigation_bar.brighteon' defaultMessage='Brighteon.com' />
      </a>
      <a className='column-link column-link--transparent' href='http://brighteon.tv' target='_blank' rel='noopener noreferrer' >
        <Icon className='column-link__icon left-menu-icon' id='website' />
        <FormattedMessage id='navigation_bar.brighteon_tv' defaultMessage='Brighteon.TV' />
      </a>
      <a className='column-link column-link--transparent' href='http://brighteonradio.com' target='_blank' rel='noopener noreferrer' >
        <Icon className='column-link__icon left-menu-icon' id='website' />
        <FormattedMessage id='navigation_bar.brighteon_radio' defaultMessage='BrighteonRadio.com' />
      </a>
      <a className='column-link column-link--transparent' href='http://naturalNews.com' target='_blank' rel='noopener noreferrer' >
        <Icon className='column-link__icon left-menu-icon' id='website' />
        <FormattedMessage id='navigation_bar.natural_news_company' defaultMessage='NaturalNews.com' />
      </a>
      <a className='column-link column-link--transparent' href='https://newstarget.com' target='_blank' rel='noopener noreferrer' >
        <Icon className='column-link__icon left-menu-icon' id='website' />
        <FormattedMessage id='navigation_bar.newstarget' defaultMessage='Newstarget.com' />
      </a>

      <hr />

      <a className='column-link column-link--transparent' href='https://donation.brighteon.com' target='_blank' rel='noopener noreferrer' >
        <Icon className='column-link__icon left-menu-icon' id='donate' />
        <FormattedMessage id='navigation_bar.donate' defaultMessage='Donate' />
      </a>

      <a className='column-link column-link--transparent' href='/help' target='_blank' rel='noopener noreferrer' >
        <Icon className='column-link__icon left-menu-icon' id='help' />
        <FormattedMessage id='navigation_bar.help' defaultMessage='Help' />
      </a>
    </div>);
  }

};

export default withRouter(LeftSideMenu);
