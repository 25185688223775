import api from '../api';

const UNCENSORED_NEWS_URL = 'https://censored.news/getjson.asp';

export const UNCENSORED_NEWS_FEED_FETCH_REQUEST = 'UNCENSORED_NEWS_FEED_FETCH_REQUEST';
export const UNCENSORED_NEWS_FEED_FETCH_SUCCESS = 'UNCENSORED_NEWS_FEED_FETCH_SUCCESS';
export const UNCENSORED_NEWS_FEED_FETCH_FAIL = 'UNCENSORED_NEWS_FEED_FETCH_FAIL';

export const UNCENSORED_NEWS_FEED_EXPAND_REQUEST = 'UNCENSORED_NEWS_FEED_EXPAND_REQUEST';
export const UNCENSORED_NEWS_FEED_EXPAND_SUCCESS = 'UNCENSORED_NEWS_FEED_EXPAND_SUCCESS';
export const UNCENSORED_NEWS_FEED_EXPAND_FAIL = 'UNCENSORED_NEWS_FEED_EXPAND_FAIL';
export const UNCENSORED_NEWS_FEED_EXPAND_END_SUCCESS = 'UNCENSORED_NEWS_FEED_EXPAND_END_SUCCESS';

const defaultAxiosConfig = {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    accept: 'application/json',
  },
  data: {},
  transformRequest: (data, headers) => {
    delete headers['X-CSRF-Token'];
    delete headers.Authorization;
  },
  crossDomain: true,
};

const decodeHtml = (html) => {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

const processArticle = (page, article) => {
  return { ...article, article: page + article.article, headline: decodeHtml(article.headline) };
};

export function fetchArticles() {
  return (dispatch, getState) => {
    dispatch(fetchArticlesRequest());

    const page = getState().getIn(['uncensored_news_feed', 'page']);

    api().get(UNCENSORED_NEWS_URL, { ...defaultAxiosConfig, params: { page } })
      .then(response => {
        let resp_data = response.data;
        if (typeof resp_data === 'string') {
          resp_data = JSON.parse(resp_data.replaceAll('\t', '').replaceAll('\n', ''));
        }
        dispatch(fetchArticlesSuccess(resp_data.articles[0].articles.map(
          processArticle.bind(null, page))));
      })
      .catch(error => {
        dispatch(fetchArticlesFail(error));
      });
  };
}

export function expandArticles() {
  return (dispatch, getState) => {
    const page = getState().getIn(['uncensored_news_feed', 'page']) + 1;

    dispatch(expandArticlesRequest());

    api().get(UNCENSORED_NEWS_URL, { ...defaultAxiosConfig, params: { page } })
      .then(response => {
        if (response.data.articles?.[0]) {
          dispatch(expandArticlesSuccess(response.data.articles[0].articles.map(
            processArticle.bind(null, page)), page));
        } else {
          dispatch(expandArticlesEndSuccess());
        }
      })
      .catch(error => {
        dispatch(expandArticlesFail(error));
      });

  };
}

export function fetchArticlesRequest() {
  return {
    type: UNCENSORED_NEWS_FEED_FETCH_REQUEST,
    skipLoading: true,
  };
}

export function fetchArticlesSuccess(articles) {
  return {
    type: UNCENSORED_NEWS_FEED_FETCH_SUCCESS,
    articles,
    skipLoading: true,
  };
}

export function fetchArticlesFail(error) {
  return {
    type: UNCENSORED_NEWS_FEED_FETCH_FAIL,
    error,
    skipLoading: true,
  };
}

export function expandArticlesRequest() {
  return {
    type: UNCENSORED_NEWS_FEED_EXPAND_REQUEST,
  };
}

export function expandArticlesSuccess(articles, page) {
  return {
    type: UNCENSORED_NEWS_FEED_EXPAND_SUCCESS,
    articles,
    page,
  };
}

export function expandArticlesFail(error) {
  return {
    type: UNCENSORED_NEWS_FEED_EXPAND_FAIL,
    error,
  };
}

export function expandArticlesEndSuccess() {
  return {
    type: UNCENSORED_NEWS_FEED_EXPAND_END_SUCCESS,
  };
}
