import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import Button from '../../../brighteon/components/icon_button';
import Block from '../../features/messages/other_components/block';
import Heading from '../../features/messages/other_components/heading';
import classNames from 'classnames';

class ModalLayout extends React.PureComponent {

  static propTypes = {
    intl: intlShape,
  };

  onHandleCloseModal = () => {
    this.props.onClose();
  }

  render() {
    const {
      title,
      children,
      intl,
      width,
      hideClose,
      noPadding,
      isXS,
    } = this.props;

    const childrenContainerClasses = classNames({
      'd': 1,
      'max-h-80vh': 1,
      'overflow-y-auto': 1,
      'px15px': !noPadding,
      'py10px': !noPadding,
    });

    return (
      <div style={{ width: `${width}px` }} className={'modal-root__modal reblogs-modal'}>
        <Block>
          <div className={'d flex-row align-items-center justify-content-center border-bottom-medium1-1 borderColorSecondary h-53px px15px'}>
            <Heading size='h2'>
              {title}
            </Heading>
            {
              !hideClose && !isXS &&
              <Button
                backgroundColor='none'
                title={intl.formatMessage(messages.close)}
                className={'ml-auto circle'}
                onClick={this.onHandleCloseModal}
                color='secondary'
                icon='clear'
                size={30}
              />
            }
          </div>
          <div className={childrenContainerClasses}>
            {children}
          </div>
        </Block>
      </div>
    );
  }

}

const messages = defineMessages({
  close: { id: 'lightbox.close', defaultMessage: 'Close' },
});

ModalLayout.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  width: PropTypes.number,
  hideClose: PropTypes.bool,
  noPadding: PropTypes.bool,
  isXS: PropTypes.bool,
};

ModalLayout.defaultProps = {
  width: 600,
};

export default injectIntl(ModalLayout);
