import { connect } from 'react-redux';
import ChatMessageComposeForm from '../components/chat_message_compose_form';
import { me } from '../../../initial_state';
import {
  selectChatComposeSuggestion,
  changeChatCompose,
  clearChatCompose,
  insertEmojiChatCompose,
  cancelReply,
  uploadChatCompose,
} from '../../../actions/chat_compose';
import {
  clearComposeSuggestions,
  fetchComposeSuggestions,
} from '../../../actions/compose';
import { sendChatMessage } from '../../../actions/chat_messages';
import { makeGetChatMessage } from '../../../selectors';

const mapStateToProps = (state) => {
  const conversationId = state.getIn(['chats', 'selectedChatConversationId'], null);
  const chatCompose = state.get('chat_compose');
  const mediaAttachments = chatCompose.getIn([conversationId, 'media_attachments']);
  const originMessageId = chatCompose.getIn([conversationId, 'originMessageId']);
  if (conversationId && chatCompose.get(conversationId)) {
    return {
      text: chatCompose.getIn([conversationId, 'text']),
      suggestions: state.getIn(['compose', 'suggestions']),
      focusDate: chatCompose.getIn([conversationId, 'focusDate']),
      caretPosition: chatCompose.getIn([conversationId, 'caretPosition']),
      preselectDate: chatCompose.getIn([conversationId, 'preselectDate']),
      isSubmitting: chatCompose.getIn([conversationId, 'is_submitting']),
      isChangingUpload: chatCompose.getIn([conversationId, 'is_changing_upload']),
      isUploading: chatCompose.getIn([conversationId, 'is_uploading']),
      anyMedia: mediaAttachments && mediaAttachments.size > 0,
      account: state.getIn(['accounts', me]),
      chatConversationId: conversationId,
      originMessageId: originMessageId,
      originMessage: originMessageId ? makeGetChatMessage()(state, { id: originMessageId }) : null,
      mediaAttachments: chatCompose.getIn([conversationId, 'media_attachments']),
    };
  };
  return {
    text: '',
    suggestions: [],
    focusDate: state.getIn(['compose', 'focusDate']),
    caretPosition: state.getIn(['compose', 'caretPosition']),
    preselectDate: state.getIn(['compose', 'preselectDate']),
    isSubmitting: state.getIn(['compose', 'is_submitting']),
    isChangingUpload: state.getIn(['compose', 'is_changing_upload']),
    isUploading: state.getIn(['compose', 'is_uploading']),
    anyMedia: state.getIn(['compose', 'media_attachments']).size > 0,
    account: state.getIn(['accounts', me]),
    chatConversationId: conversationId,
    originMessageId: null,
    originMessage: null,
  };
};

const mapDispatchToProps = (dispatch) => ({

  onChange(text, chatConversationId) {
    dispatch(changeChatCompose(text, chatConversationId));
  },

  onSendChatMessage(text, chatConversationId, originMessageId, mediaAttachments) {
    dispatch(sendChatMessage(text, chatConversationId, originMessageId, mediaAttachments));
  },

  onClearSuggestions() {
    dispatch(clearComposeSuggestions());
  },

  onFetchSuggestions(token) {
    dispatch(fetchComposeSuggestions(token));
  },

  onSuggestionSelected(position, token, suggestion, path, chatConversationId) {
    dispatch(selectChatComposeSuggestion(position, token, suggestion, path, chatConversationId));
    dispatch(clearComposeSuggestions());
  },

  onPaste(files, chatConversationId) {
    dispatch(uploadChatCompose(files, chatConversationId));
  },

  onPickEmoji(position, data, needsSpace, chatConversationId) {
    dispatch(insertEmojiChatCompose(position, data, needsSpace, chatConversationId));
  },

  onClear(chatConversationId) {
    dispatch(clearChatCompose(chatConversationId));
  },

  onCancelReply(chatConversationId) {
    dispatch(cancelReply(chatConversationId));
  },

});

export default connect(mapStateToProps, mapDispatchToProps)(ChatMessageComposeForm);
