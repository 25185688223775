import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { injectIntl } from 'react-intl';
import ImmutablePureComponent from 'react-immutable-pure-component';
import classNames from 'classnames';
import {
  followAccount,
  unfollowAccount,
  blockAccount,
  unblockAccount,
  muteAccount,
  unmuteAccount,
} from '../../../actions/accounts';
import { openModal } from '../../../actions/modal';
import { initMuteModal } from '../../../actions/mutes';
import { unfollowModal } from '../../../initial_state';
import { makeGetAccount } from '../../../selectors';
import AccountActionButton from './account_action_button';
import Avatar from './avatar';
import DisplayName from './display_name';
import Button from './button';
import IconButton from '../../../../brighteon/components/icon_button';

class Account extends ImmutablePureComponent {

  handleAction = (e) => {
    this.props.onActionClick(this.props.account, e);
    e.preventDefault();
    return false;
  }

  handleUnrequest = () => {
    //
  }

  render() {
    const {
      account,
      isHidden,
      onActionClick,
      actionIcon,
      actionTitle,
      compact,
      dismissAction,
      showDismiss,
      withBio,
      isCard,
      normalActionButton,
    } = this.props;

    if (!account) return null;

    if (isHidden) {
      return (
        <React.Fragment>
          {account.get('display_name')}
          {`@${account.get('username')}`}
        </React.Fragment>
      );
    }

    let actionButton = '';
    if (onActionClick && (actionIcon || actionTitle)) {
      actionButton = (
        normalActionButton ?
          (<button
            className={'normal-button button'}
            disabled={this.props.disabled}
            onClick={this.handleAction}
            ref={this.setRef}
            title={actionTitle}
          >
            {actionTitle}
          </button>) :
          (<IconButton
            onClick={this.handleAction}
            icon={actionIcon}
            size={30}
            className='circle'
            title={actionTitle || ''}
            hasOutline
          />)
      );
    } else actionButton = <AccountActionButton account={account} isSmall />;

    const avatarSize = compact ? 40 : 52;
    const dismissBtn = !showDismiss ? null : (
      <Button
        isNarrow
        backgroundColor='none'
        className={'align-items-end px5px'}
        onClick={dismissAction}
        icon='close'
        iconSize='8px'
        iconClassName={''}
      />
    );

    const content = { __html: account.get('note_emojified') };

    const buttonClasses = classNames({
      'd': 1,
      'pt2px': 1,
      'pr5px': 1,
      'decoration-none': 1,
      'overflow-hidden': 1,
      'flex-normal': 1,
      'outline-none': 1,
      'bg-transparent': 1,
      'align-items-start': !isCard,
      'align-items-center': isCard,
    });

    const containerClasses = classNames({
      'd': 1,
      'px15px': 1,
      'py10px': 1,
      'bg-subtle-onHover': 1,
      'border-bottom-medium1-1': !isCard,
      'borderColorSecondary': !isCard,
    });

    const innerContainerClasses = classNames({
      'd': 1,
      'flex-row': !isCard,
      'align-items-start': !isCard,
      'align-items-center': isCard,
    });

    const displayNameWrapperClasses = classNames({
      'py10px': isCard,
    });

    return (
      <div className={containerClasses}>
        <div className={innerContainerClasses}>

          <Button
            noClasses
            className={'d decoration-none outline-none bg-transparent max-circle'}
            title={account.get('acct')}
            href={account.get('url')}
          >
            <Avatar account={account} size={avatarSize} />
          </Button>

          <div className={'d px10px overflow-hidden flex-normal'}>
            <div className={'d flex-row align-items-center'}>
              <Button
                noClasses
                title={account.get('acct')}
                href={account.get('url')}
                className={buttonClasses}
              >
                <div className={displayNameWrapperClasses}>
                  <DisplayName account={account} isMultiline={compact || isCard} />
                </div>
                {!compact && actionButton}
              </Button>

              <div className={'d flex-row align-items-center'}>
                {dismissBtn}
                {compact && actionButton}
              </div>
            </div>

            {
              withBio &&
              <div className={'py5px dangerous-content'} dangerouslySetInnerHTML={content} />
            }
          </div>
        </div>
      </div>
    );
  }

}


const makeMapStateToProps = (state, props) => ({
  account: makeGetAccount()(state, props.id),
});

const mapDispatchToProps = (dispatch) => ({

  onFollow (account) {
    if (account.getIn(['relationship', 'following']) || account.getIn(['relationship', 'requested'])) {
      if (unfollowModal) {
        dispatch(openModal('UNFOLLOW', {
          account,
        }));
      } else {
        dispatch(unfollowAccount(account.get('id')));
      }
    } else {
      dispatch(followAccount(account.get('id')));
    }
  },

  onBlock (account) {
    if (account.getIn(['relationship', 'blocking'])) {
      dispatch(unblockAccount(account.get('id')));
    } else {
      dispatch(blockAccount(account.get('id')));
    }
  },

  onMute (account) {
    if (account.getIn(['relationship', 'muting'])) {
      dispatch(unmuteAccount(account.get('id')));
    } else {
      dispatch(initMuteModal(account));
    }
  },


  onMuteNotifications (account, notifications) {
    dispatch(muteAccount(account.get('id'), notifications));
  },
});

Account.propTypes = {
  account: ImmutablePropTypes.map.isRequired,
  onFollow: PropTypes.func.isRequired,
  onBlock: PropTypes.func.isRequired,
  onMute: PropTypes.func.isRequired,
  onMuteNotifications: PropTypes.func,
  intl: PropTypes.object.isRequired,
  isHidden: PropTypes.bool,
  actionIcon: PropTypes.string,
  actionTitle: PropTypes.string,
  onActionClick: PropTypes.func,
  compact: PropTypes.bool,
  expanded: PropTypes.bool,
  showDismiss: PropTypes.bool,
  dismissAction: PropTypes.func,
  withBio: PropTypes.bool,
  isCard: PropTypes.bool,
  noClick: PropTypes.bool,
};

export default injectIntl(connect(makeMapStateToProps, mapDispatchToProps)(Account));