import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { HotKeys } from 'react-hotkeys';
import PropTypes from 'prop-types';
import ImmutablePureComponent from 'react-immutable-pure-component';
import { me } from 'brighteon/initial_state';
import AccountContainer from 'brighteon/containers/account_container';
import FollowRequestContainer from '../containers/follow_request_container';
import Icon from '../../../../brighteon/components/icon';
import classNames from 'classnames';

const messages = defineMessages({
  favourite: { id: 'notification.favourite', defaultMessage: '{name} favourited your status' },
  follow: { id: 'notification.follow', defaultMessage: '{name} followed you' },
  ownPoll: { id: 'notification.own_poll', defaultMessage: 'Your poll has ended. See the results {here}.' },
  poll: { id: 'notification.poll', defaultMessage: 'A poll you have voted in has ended. See the results {here}.' },
  reblog: { id: 'notification.reblog', defaultMessage: '{name} boosted your status' },
  status: { id: 'notification.status', defaultMessage: '{name} just posted' },
});

const style = {
  size: 24,
};

const notificationForScreenReader = (intl, message, timestamp) => {
  const output = [message];

  output.push(intl.formatDate(timestamp, { hour: '2-digit', minute: '2-digit', month: 'short', day: 'numeric' }));

  return output.join(', ');
};

export default @injectIntl
class Notification extends ImmutablePureComponent {

  static contextTypes = {
    router: PropTypes.object,
  };

  static propTypes = {
    notification: ImmutablePropTypes.map.isRequired,
    hidden: PropTypes.bool,
    onMoveUp: PropTypes.func.isRequired,
    onMoveDown: PropTypes.func.isRequired,
    onMention: PropTypes.func.isRequired,
    onFavourite: PropTypes.func.isRequired,
    onReblog: PropTypes.func.isRequired,
    onToggleHidden: PropTypes.func.isRequired,
    onOpenOtherBoostedAccounts: PropTypes.func,
    onOpenOtherFollowedAccounts: PropTypes.func,
    onOpenOtherFavoritedAccounts: PropTypes.func,
    onOpenOtherRepliedAccounts: PropTypes.func,
    status: ImmutablePropTypes.map,
    chat_message: ImmutablePropTypes.map,
    intl: PropTypes.object.isRequired,
    getScrollPosition: PropTypes.func,
    updateScrollBottom: PropTypes.func,
    cacheMediaWidth: PropTypes.func,
    cachedMediaWidth: PropTypes.number,
    unread: PropTypes.bool,
  };

  handleMoveUp = () => {
    const { notification, onMoveUp } = this.props;
    onMoveUp(notification.get('id'));
  }

  handleMoveDown = () => {
    const { notification, onMoveDown } = this.props;
    onMoveDown(notification.get('id'));
  }

  handleOpen = () => {
    const { notification } = this.props;

    if (notification.get('status')) {
      this.context.router.history.push(`/statuses/${notification.get('status')}`);
    } else {
      this.handleOpenProfile();
    }
  }

  handleOpenProfile = () => {
    const { notification } = this.props;
    this.context.router.history.push(`/accounts/${notification.getIn(['account', 'id'])}`);
  }

  handleOpenOtherFavoritedAccounts = () => {
    const { notification } = this.props;

    this.props.onOpenOtherFavoritedAccounts(notification);
  }

  handleOpenOtherFollowedAccounts = () => {
    const { notification } = this.props;

    this.props.onOpenOtherFollowedAccounts(notification);
  }

  handleOpenOtherBoostedAccounts = () => {
    const { notification } = this.props;

    this.props.onOpenOtherBoostedAccounts(notification);
  }

  handleOpenOtherRepliedAccounts = () => {
    const { notification } = this.props;

    this.props.onOpenOtherRepliedAccounts(notification);
  }

  handleMention = e => {
    e.preventDefault();

    const { notification, onMention } = this.props;
    onMention(notification.get('account'), this.context.router.history);
  }

  handleHotkeyFavourite = () => {
    const { status } = this.props;
    if (status) this.props.onFavourite(status);
  }

  handleHotkeyBoost = e => {
    const { status } = this.props;
    if (status) this.props.onReblog(status, e);
  }

  handleHotkeyToggleHidden = () => {
    const { status } = this.props;
    if (status) this.props.onToggleHidden(status);
  }

  getHandlers() {
    return {
      reply: this.handleMention,
      favourite: this.handleHotkeyFavourite,
      boost: this.handleHotkeyBoost,
      mention: this.handleMention,
      open: this.handleOpen,
      openProfile: this.handleOpenProfile,
      moveUp: this.handleMoveUp,
      moveDown: this.handleMoveDown,
      toggleHidden: this.handleHotkeyToggleHidden,
    };
  }

  renderFollow(notification, account, link) {
    const { intl, unread } = this.props;

    const otherAccounts = notification.get('otherAccounts');
    const otherCount = notification.get('otherCount');
    if (!otherAccounts) {
      return (
        <HotKeys handlers={this.getHandlers()}>
          <div className={classNames('notification focusable', { unread })} tabIndex='0' aria-label={notificationForScreenReader(intl, intl.formatMessage(messages.follow, { name: account.get('acct') }), notification.get('created_at'))}>
            <div className='notification__message'>
              <div className='notification__favourite-icon-wrapper'>
                <Icon id='user-plus' size={style.size} />
              </div>

              <span title={notification.get('created_at')}>
                <FormattedMessage id='notification.follow' defaultMessage='{name} followed you' values={{ name: link }} />
              </span>
            </div>

            <AccountContainer id={account.get('id')} hidden={this.props.hidden} />
          </div>
        </HotKeys>
      );
    }

    const otherFollowingText = (otherCount > 2) ?
      (<>
        <span>&nbsp;and&nbsp;</span>
        <span>
          <div
            className='status__following-accounts__other cursor-pointer'
            onClick={this.handleOpenOtherFollowedAccounts}
            role='button' tabIndex={0}
          >{otherCount - 2} others</div>
        </span>
      </>) : null;

    const followedNames = (<>
      <React.Fragment key={0}>
        <a onClick={this.handleAccountClick} data-id={account.get('id')} href={account.get('url')} className='notification__display-name muted'>
          <bdi>
            <b>
              <strong dangerouslySetInnerHTML={{
                __html: '@' + (account.get('display_name') || account.get('username')),
              }}
              />
            </b>
          </bdi>
        </a>
      </React.Fragment>
      {otherAccounts.slice(0, 2).map((followingAccount, index) => (
        <React.Fragment key={index + 1}>
          <a onClick={this.handleAccountClick} data-id={followingAccount.id} href={followingAccount.url} className='notification__display-name muted'>
            <bdi>
              <b>
                <strong dangerouslySetInnerHTML={{
                  __html: '@' + (followingAccount.display_name || followingAccount.username),
                }}
                />
              </b>
            </bdi>
          </a>
        </React.Fragment>
      )).reduce((prev, curr) => [prev, ', ', curr], '')}
      {otherFollowingText}
    </>);

    const prepend = (<>
      {followedNames} started following you
    </>);

    return (
      <HotKeys handlers={this.getHandlers()}>
        <div className={classNames('notification notification-follow focusable', { unread })} tabIndex='0' aria-label={notificationForScreenReader(intl, intl.formatMessage(messages.follow, { name: account.get('acct') }), notification.get('created_at'))}>
          <div className='notification__message'>
            <div className='notification__favourite-icon-wrapper'>
              <Icon id='user-plus' size={style.size} />
            </div>

            <span title={notification.get('created_at')}>
              {/* <FormattedMessage id='notification.follow' defaultMessage='{name} followed you' values={{ name: link }} /> */}
              {prepend}
            </span>
          </div>
        </div>
      </HotKeys>
    );
  }

  renderFollowRequest(notification, account, link) {
    const { intl, unread } = this.props;

    return (
      <HotKeys handlers={this.getHandlers()}>
        <div className={classNames('notification notification-follow-request focusable', { unread })} tabIndex='0' aria-label={notificationForScreenReader(intl, intl.formatMessage({ id: 'notification.follow_request', defaultMessage: '{name} has requested to follow you' }, { name: account.get('acct') }), notification.get('created_at'))}>
          <div className='notification__message'>
            <div className='notification__favourite-icon-wrapper'>
              <Icon id='user' size={style.size} />
            </div>

            <span title={notification.get('created_at')}>
              <FormattedMessage id='notification.follow_request' defaultMessage='{name} has requested to follow you' values={{ name: link }} />
            </span>
          </div>

          <FollowRequestContainer id={account.get('id')} withNote={false} hidden={this.props.hidden} />
        </div>
      </HotKeys>
    );
  }

  renderMention(notification, link) {
    const { unread, status, chat_message } = this.props;
    let post = undefined;
    if (status && status.get('in_reply_to')) {
      post = status.get('in_reply_to');
    }
    if (!post && !chat_message && !chat_message && !status) return (<></>);

    const otherAccounts = notification.get('otherAccounts');
    const otherCount = notification.get('otherCount');

    const otherRepliedText = (otherCount > 2) ?
      (<>
        {' '}
        <span>and&nbsp;</span>
        <span>
          <div
            className='status__replied-accounts__other cursor-pointer'
            onClick={this.handleOpenOtherRepliedAccounts}
            role='button' tabIndex={0}
          >
            {otherCount - 2} others
          </div>
        </span>
      </>) : null;

    const repliedNames = (<>
      {link}
      {otherAccounts.slice(0, 2).map((repliedAccount, index) => (
        <React.Fragment key={index + 1}>
          <a onClick={this.handleAccountClick} data-id={repliedAccount.id} href={repliedAccount.url} className='notification__display-name muted'>
            <bdi>
              <b>
                <strong dangerouslySetInnerHTML={{
                  __html: '@' + (repliedAccount.display_name || repliedAccount.username),
                }}
                />
              </b>
            </bdi>
          </a>
        </React.Fragment>
      )).reduce((prev, curr) => [prev, ', ', curr], '')}
      {otherRepliedText}
    </>);

    let activity_id = status && (status.get('visibility') === 'direct' ? 'direct' : 'reply');
    activity_id = chat_message ? 'direct' : 'reply';

    let prepend = '';
    if (post) {
      if (post.getIn(['account', 'id']) === me) {
        prepend = (<>
          {repliedNames} replied to your <a href={post.get('url')}>post</a>
        </>);
      } else {
        prepend = (<>
          {repliedNames} mentioned you in this <a href={post.get('url')}>post</a>'s reply
        </>);
      }
    } else {
      if (status) {
        prepend = (<>
          {repliedNames} mentioned you in this <a href={status.get('url')}>post</a>
        </>);
      } else if (chat_message) {
        prepend = (<>
          {repliedNames} mentioned you in this <a href={`/web/messages/${chat_message.chat_conversation_id}`}>chat</a>
        </>);
      }
    }

    return (
      <HotKeys handlers={this.getHandlers()}>
        <div className={classNames(`notification notification-${activity_id} focusable`, { unread })} tabIndex='0'>
          <div className='notification__message'>
            <div className={`notification__${activity_id}-icon-wrapper`}>
              <Icon id={activity_id} size={style.size} />
            </div>

            <span title={notification.get('created_at')}>
              {/* <FormattedMessage id='notification.favourite' defaultMessage='{name} favourited your status' values={{ name: link }} /> */}
              {prepend}
            </span>
          </div>
        </div>
      </HotKeys>
    );
  }

  renderFavourite(notification, link) {
    const { intl, unread, status } = this.props;
    if (!status) return (<></>);

    const otherAccounts = notification.get('otherAccounts');
    const otherCount = notification.get('otherCount');

    const otherFavoriteText = (otherCount > 2) ?
      (<>
        {' '}
        <span>and&nbsp;</span>
        <span>
          <div
            className='status__following-accounts__other cursor-pointer'
            onClick={this.handleOpenOtherFavoritedAccounts}
            role='button' tabIndex={0}
          >
            {otherCount - 2} others
          </div>
        </span>
      </>) : null;

    const favoritesNames = (<>
      {link}
      {otherAccounts.slice(0, 2).map((favoritedAccount, index) => (
        <React.Fragment key={index + 1}>
          <a onClick={this.handleAccountClick} data-id={favoritedAccount.id} href={favoritedAccount.url} className='notification__display-name muted'>
            <bdi>
              <strong dangerouslySetInnerHTML={{
                __html: '@' + (favoritedAccount.display_name || favoritedAccount.username),
              }}
              />
            </bdi>
          </a>
        </React.Fragment>
      )).reduce((prev, curr) => [prev, ', ', curr], '')}
      {otherFavoriteText}
    </>);

    const prepend = (<>
      {favoritesNames} favorited your <a href={status.get('url')}>post</a>
    </>);

    return (
      <HotKeys handlers={this.getHandlers()}>
        <div className={classNames('notification notification-favourite focusable', { unread })} tabIndex='0' aria-label={notificationForScreenReader(intl, intl.formatMessage(messages.favourite, { name: notification.getIn(['account', 'acct']) }), notification.get('created_at'))}>
          <div className='notification__message'>
            <div className='notification__favourite-icon-wrapper'>
              <Icon id='favorite' size={style.size} />
            </div>

            <span title={notification.get('created_at')}>
              {/* <FormattedMessage id='notification.favourite' defaultMessage='{name} favourited your status' values={{ name: link }} /> */}
              {prepend}
            </span>
          </div>
        </div>
      </HotKeys>
    );
  }

  renderReblog(notification, link) {
    const { intl, unread, status } = this.props;

    const otherAccounts = notification.get('otherAccounts');
    const otherCount = notification.get('otherCount');

    const otherReblogText = (otherCount > 2) ?
      (<>
        {' '}
        <span>and&nbsp;</span>
        <span>
          <div
            className='status__following-accounts__other'
            onClick={this.handleOpenOtherBoostedAccounts}
            role='button' tabIndex={0}
          >
            {otherCount - 2} others
          </div>

        </span>
      </>) : null;

    const rebloggedNames = (<>
      {link}
      {otherAccounts.slice(0, 2).map((favoritedAccount, index) => (
        <React.Fragment key={index + 1}>
          <a onClick={this.handleAccountClick} data-id={favoritedAccount.id} href={favoritedAccount.url} className='notification__display-name muted'>
            <bdi>
              <strong dangerouslySetInnerHTML={{
                __html: '@' + (favoritedAccount.display_name || favoritedAccount.username),
              }}
              />
            </bdi>
          </a>
        </React.Fragment>
      )).reduce((prev, curr) => [prev, ', ', curr], '')}
      {otherReblogText}
    </>);

    const prepend = (<>
      {rebloggedNames} reposted your <a href={status.get('url')}>post</a>
    </>);

    return (
      <HotKeys handlers={this.getHandlers()}>
        <div className={classNames('notification notification-reblog focusable', { unread })} tabIndex='0' aria-label={notificationForScreenReader(intl, intl.formatMessage(messages.reblog, { name: notification.getIn(['account', 'acct']) }), notification.get('created_at'))}>
          <div className='notification__message'>
            <div className='notification__reblog-icon-wrapper'>
              <Icon id='retweet' size={style.size} />
            </div>

            <span title={notification.get('created_at')}>
              {/* <FormattedMessage id='notification.reblog' defaultMessage='{name} boosted your status' values={{ name: link }} /> */}
              {prepend}
            </span>
          </div>
        </div>
      </HotKeys>
    );
  }

  renderStatus(notification, link) {
    const { intl, unread } = this.props;

    return (
      <HotKeys handlers={this.getHandlers()}>
        <div className={classNames('notification notification-status focusable', { unread })} tabIndex='0' aria-label={notificationForScreenReader(intl, intl.formatMessage(messages.status, { name: notification.getIn(['account', 'acct']) }), notification.get('created_at'))}>
          <div className='notification__message'>
            <div className='notification__favourite-icon-wrapper'>
              <Icon id='home' size={style.size} />
            </div>

            <span title={notification.get('created_at')}>
              <FormattedMessage id='notification.status' defaultMessage='{name} just posted' values={{ name: link }} />
            </span>
          </div>
        </div>
      </HotKeys>
    );
  }

  renderPoll(notification, account) {
    const { intl, unread, status } = this.props;
    if (!status) return (<></>);

    const ownPoll = me === account.get('id');
    const message = ownPoll ? intl.formatMessage(messages.ownPoll) : intl.formatMessage(messages.poll);

    return (
      <HotKeys handlers={this.getHandlers()}>
        <div className={classNames('notification notification-poll focusable', { unread })} tabIndex='0' aria-label={notificationForScreenReader(intl, message, notification.get('created_at'))}>
          <div className='notification__message'>
            <div className='notification__favourite-icon-wrapper'>
              <Icon id='poll-gray' size={style.size} />
            </div>

            <span title={notification.get('created_at')}>
              {ownPoll ? (
                <FormattedMessage
                  id='notification.own_poll'
                  defaultMessage='Your poll has ended. See the results {here}.'
                  values={{ here: <a href={status.get('url')}>here</a> }}
                />
              ) : (
                <FormattedMessage
                  id='notification.poll'
                  defaultMessage='A poll you have voted in has ended. See the results {here}.'
                  values={{ here: <a href={status.get('url')}>here</a> }}
                />
              )}
            </span>
          </div>
        </div>
      </HotKeys>
    );
  }

  render() {
    const { notification } = this.props;
    const account = notification.get('account');
    const displayNameHtml = { __html: '@' + account.get('display_name_html') };
    const link = (
      <bdi>
        <b>
          <a
            className='notification__display-name'
            href={account.get('url')}
            title={account.get('acct')}
            to={`/accounts/${account.get('id')}`}
            dangerouslySetInnerHTML={displayNameHtml}
          />
        </b>
      </bdi>);

    switch (notification.get('type')) {
    case 'follow':
      return this.renderFollow(notification, account, link);
    case 'follow_request':
      return this.renderFollowRequest(notification, account, link);
    case 'mention':
      return this.renderMention(notification, link);
    case 'favourite':
      return this.renderFavourite(notification, link);
    case 'reblog':
      return this.renderReblog(notification, link);
    case 'status':
      return this.renderStatus(notification, link);
    case 'poll':
      return this.renderPoll(notification, account);
    }

    return null;
  }

}
