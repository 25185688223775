import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Icon from '../../../components/icon';
import Avatar from '../../../../brighteon/components/avatar';
import LinkFooter from '../../../../brighteon/features/ui/components/link_footer';

const avatarStyle = {
  borderRadius: '50%',
  border: '2px solid white',
};

const RightSideMenu = (props) => {
  return (
    <div className='navigation-panel- right-side-menu'>
      <div className='user-section'>
        <div className='profile-section d-flex'>
          <div className='profile-pic-section'>
            <a href={props.account.get('url')}>
              <Avatar account={props.account} size={50} style={avatarStyle} />
            </a>
          </div>
          <div className='profile-name-section d-flex'>
            <a className='profile-displayname' href={props.account.get('url')}>
              <span>
                {props.account.get('display_name') || props.account.get('username')}
              </span>
            </a>
            <a className='profile-username' href={props.account.get('url')}>
              <span>
                {'@' + props.account.get('username')}
              </span>
            </a>
          </div>
        </div>
        <div className='stats-section'>
          <a href={props.account.get('url')}>
            <b>{props.statuses_count}</b>
            <span>&nbsp;Posts</span>
          </a>
          <a href={`/users/${props.account.get('username')}/following`}>
            <b>{props.following_count}</b>
            <span>&nbsp;Followings</span>
          </a>
          <a href={`/users/${props.account.get('username')}/followers`}>
            <b>{props.followers_count}</b>
            <span>&nbsp;Followers</span>
          </a>
        </div>
      </div>

      <hr />

      <div className='column-menu'>
        Profile
      </div>

      <a className='column-link column-link--transparent' href='/settings/profile'>
        <Icon className='column-link__icon right-menu-icon' id='profile' /><FormattedMessage id='navigation_bar.edit_profile' defaultMessage='Edit Profile' />
      </a>

      <a className='column-link column-link--transparent' href='/settings/preferences'>
        <Icon className='column-link__icon right-menu-icon' id='settings' /><FormattedMessage id='navigation_bar.edit_settings' defaultMessage='Edit Settings' />
      </a>

      <hr />

      <a className='column-link column-link--transparent' href='/help' target='_blank' rel='noopener noreferrer'>
        <Icon className='column-link__icon right-menu-icon' id='help' /><FormattedMessage id='navigation_bar.help' defaultMessage='Help' />
      </a>
      <div className='column-link column-link--transparent cursor-pointer' href='#' target='_blank' rel='noopener noreferrer' onClick={props.handleSignOut} role='button' tabIndex={0}>
        <Icon className='column-link__icon right-menu-icon' id='signout' /><FormattedMessage id='navigation_bar.signout' defaultMessage='Sign Out' />
      </div>

      <hr />

      <LinkFooter />
    </div>
  );
};

RightSideMenu.propTypes = {
  account: PropTypes.object,
  statuses_count: PropTypes.number,
  following_count: PropTypes.number,
  followers_count: PropTypes.number,
  handleSignOut: PropTypes.func,
};

export default withRouter(RightSideMenu);
