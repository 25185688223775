import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { makeGetChatConversation } from '../../../selectors';
import { openPopover } from '../../../actions/popover';
import { setChatConversationSelected } from '../../../actions/chats';
import { POPOVER_CHAT_CONVERSATION_OPTIONS } from '../../../constants';
import Heading from '../other_components/heading';
import BackButton from '../other_components/back_button';
import IconButton from '../../../../brighteon/components/icon_button';

class ChatNavigationBar extends React.PureComponent {

  handleOnOpenChatConversationOptionsPopover = () => {
    const isChatConversationRequest = !!this.props.chatConversation ? !this.props.chatConversation.get('is_approved') : false;
    const isHidden = this.props.chatConversation.get('is_hidden');
    this.props.onOpenChatConversationOptionsPopover({
      isHidden,
      isChatConversationRequest,
      chatConversationId: this.props.chatConversationId,
      targetRef: this.optionsBtnRef,
    });
  }

  handleOnBack = () => {
    this.props.onSetChatConversationSelectedEmpty();
  }

  setOptionsBtnRef = (c) => {
    this.optionsBtnRef = c;
  }

  render() {
    const { chatConversation } = this.props;

    const otherAccounts = chatConversation ? chatConversation.get('other_accounts') : null;
    const nameHTML = !!otherAccounts ? otherAccounts.get(0).get('display_name_html') : '';

    return (
      <div className={['d', 'z4', 'min-h-53px', 'w-100'].join(' ')}>
        <div className={['d', 'min-h-53px', 'bg-white', 'align-items-center', 'z3', 'top-0', 'right-0', 'left-0', 'pos-fixed'].join(' ')} >
          <div className={['d', 'flex-row', 'saveAreaInsetPT', 'saveAreaInsetPL', 'saveAreaInsetPR', 'w-100'].join(' ')}>

            <BackButton
              className={['h-53px', 'pl10px', 'pr10px'].join(' ')}
              iconSize={18}
              onClick={this.handleOnBack}
              iconClassName={['mr5px', 'fill-navigation'].join(' ')}
            />

            <div className={['d', 'min-h-53px', 'flex-row', 'align-items-center', 'mr-auto', 'flex-fill', 'overflow-hidden'].join(' ')}>
              <div className={['d', 'min-h-53PX', 'justify-content-center', 'w-100', 'flex-shrink'].join(' ')}>
                <Heading size='h1'>
                  <div className={['d', 'w-100'].join(' ')}>
                    <span
                      className={['w-100', 'text-overflow-ellipsis', 'color-black'].join(' ')}
                      dangerouslySetInnerHTML={{ __html: nameHTML }}
                    />
                  </div>
                </Heading>
              </div>
            </div>

            <div className={['d', 'h-53px', 'ml-auto', 'align-items-center', 'justify-content-center', 'mr15px'].join(' ')}>
              <IconButton
                title='Options'
                icon='ellipsis-h'
                size={26}
                onClick={this.handleOnOpenChatConversationOptionsPopover}
                buttonRef={this.setOptionsBtnRef}
                circle
                className='ml-auto'
              />
            </div>

          </div>

        </div>
      </div>
    );
  }

}

const mapStateToProps = (state, { chatConversationId }) => ({
  chatConversation: makeGetChatConversation()(state, { id: chatConversationId }),
});

const mapDispatchToProps = (dispatch) => ({
  onSetChatConversationSelectedEmpty() {
    dispatch(setChatConversationSelected(null));
  },
  onOpenChatConversationOptionsPopover(options) {
    dispatch(openPopover(POPOVER_CHAT_CONVERSATION_OPTIONS, {
      ...options,
      position: 'bottom',
    }));
  },
});

ChatNavigationBar.propTypes = {
  chatConversation: ImmutablePropTypes.map,
  chatConversationId: PropTypes.string.isRequired,
  onOpenChatConversationOptionsPopover: PropTypes.func.isRequired,
  onSetChatConversationSelectedEmpty: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatNavigationBar);