import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import Avatar from 'brighteon/components/avatar';
import RelativeTimestamp from 'brighteon/components/relative_timestamp';
import DisplayName from 'brighteon/components/display_name';
import StatusContent from 'brighteon/components/status_content';
import { StatusActionBar, StatusPrepend } from './components';
import AttachmentList from 'brighteon/components/attachment_list';
import Card from 'brighteon/features/status/components/card';
import { injectIntl, FormattedMessage } from 'react-intl';
import ImmutablePureComponent from 'react-immutable-pure-component';
import { MediaGallery, Video, Audio } from 'brighteon/features/ui/util/async-components';
import classNames from 'classnames';
import { displayMedia, me } from 'brighteon/initial_state';
import api from 'brighteon/api';
import StatusContainer from 'brighteon/containers/status_container';
import { checkIfAndroid } from 'brighteon/is_mobile';
import NotificationStatus from '../../../brighteon/components/notification_status';
import Icon from '../../../brighteon/components/icon';

// We use the component (and not the container) since we do not want
// to use the progress bar to show download progress
import Bundle from 'brighteon/features/ui/components/bundle';
import { importFetchedStatuses } from 'brighteon/actions/importer';

import { connect } from 'react-redux';
import { StatusReplyContainer } from './containers';
import ComposeFormContainer from '../../../brighteon/components/status_with_reply/containers/compose_form_container';
import StatusMenu from '../../../brighteon/components/status_with_reply/components/status_menu';

export const textForScreenReader = (intl, status, rebloggedByText = false) => {
  const displayName = status.getIn(['account', 'display_name']);

  const values = [
    displayName.length === 0 ? status.getIn(['account', 'acct']).split('@')[0] : displayName,
    status.get('spoiler_text') && status.get('hidden') ? status.get('spoiler_text') : status.get('search_index').slice(status.get('spoiler_text').length),
    intl.formatDate(status.get('created_at'), { hour: '2-digit', minute: '2-digit', month: 'short', day: 'numeric' }),
    status.getIn(['account', 'acct']),
  ];

  if (rebloggedByText) {
    values.push(rebloggedByText);
  }

  return values.join(', ');
};

export const defaultMediaVisibility = (status) => {
  if (!status) {
    return undefined;
  }

  if (status.get('reblog', null) !== null && typeof status.get('reblog') === 'object') {
    status = status.get('reblog');
  }

  return (displayMedia !== 'hide_all' && !status.get('sensitive') || displayMedia === 'show_all');
};

const mapDispatchToProps = dispatch => ({
  importFetchedStatuses: (...args) => dispatch(importFetchedStatuses(...args)),
});

export default @connect(null, mapDispatchToProps) @injectIntl
class Status extends ImmutablePureComponent {

  static contextTypes = {
    router: PropTypes.object,
  };

  static propTypes = {
    statuses: ImmutablePropTypes.map,
    status: ImmutablePropTypes.map,
    account: ImmutablePropTypes.map,
    otherAccounts: ImmutablePropTypes.list,
    onClick: PropTypes.func,
    onReply: PropTypes.func,
    onFavourite: PropTypes.func,
    onReblog: PropTypes.func,
    onDelete: PropTypes.func,
    onDirect: PropTypes.func,
    onMention: PropTypes.func,
    onPin: PropTypes.func,
    onOpenMedia: PropTypes.func,
    onOpenVideo: PropTypes.func,
    onBlock: PropTypes.func,
    onEmbed: PropTypes.func,
    onHeightChange: PropTypes.func,
    onToggleHidden: PropTypes.func,
    onToggleCollapsed: PropTypes.func,
    muted: PropTypes.bool,
    hidden: PropTypes.bool,
    unread: PropTypes.bool,
    onMoveUp: PropTypes.func,
    onMoveDown: PropTypes.func,
    showThread: PropTypes.bool,
    getScrollPosition: PropTypes.func,
    updateScrollBottom: PropTypes.func,
    cacheMediaWidth: PropTypes.func,
    cachedMediaWidth: PropTypes.number,
    scrollKey: PropTypes.string,
    replyOrigin: PropTypes.any,
    onOpenRepliesModal: PropTypes.func,
    onOpenFollowingAccounts: PropTypes.func,
    onOpenReblogsModal: PropTypes.func,
    onOpenFavouritesModal: PropTypes.func,
  };

  // Avoid checking props that are functions (and whose equality will always
  // evaluate to false. See react-immutable-pure-component for usage.
  updateOnProps = [
    'statuses',
    'status',
    'account',
    'muted',
    'hidden',
  ];

  state = {
    showMedia: defaultMediaVisibility(this.props.status),
    statusId: undefined,
    rerenderFlag: false,
    showReplyBox: true,
    showAllReplies: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.status && nextProps.status.get('id') !== prevState.statusId) {
      return {
        showMedia: defaultMediaVisibility(nextProps.status),
        statusId: nextProps.status.get('id'),
      };
    } else {
      return null;
    }
  }

  handleToggleMediaVisibility = () => {
    this.setState({ showMedia: !this.state.showMedia });
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
      return;
    }

    if (!this.context.router) {
      return;
    }

    const { status } = this.props;
    window.open(status.get('url'), '_blank');
  }

  handleExpandClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  handleAccountClick = () => {
    // if (this.context.router && e.button === 0 && !(e.ctrlKey || e.metaKey)) {
    //   const id = e.currentTarget.getAttribute('data-id');
    //   e.preventDefault();
    //   this.context.router.history.push(`/accounts/${id}`);
    // }
  }

  handleExpandedToggle = () => {
    this.props.onToggleHidden(this._properStatus());
  }

  handleCollapsedToggle = isCollapsed => {
    this.props.onToggleCollapsed(this._properStatus(), isCollapsed);
  }

  renderLoadingMediaGallery() {
    return <div className='media-gallery' style={{ height: '110px' }} />;
  }

  renderLoadingVideoPlayer() {
    return <div className='video-player' style={{ height: '110px' }} />;
  }

  renderLoadingAudioPlayer() {
    return <div className='audio-player' style={{ height: '110px' }} />;
  }

  handleOpenVideo = (options) => {
    const status = this._properStatus();
    this.props.onOpenVideo(status.get('id'), status.getIn(['media_attachments', 0]), options);
  }

  handleOpenMedia = (media, index) => {
    this.props.onOpenMedia(this._properStatus().get('id'), media, index);
  }

  handleHotkeyOpenMedia = e => {
    const { onOpenMedia, onOpenVideo } = this.props;
    const status = this._properStatus();

    e.preventDefault();

    if (status.get('media_attachments').size > 0) {
      if (status.getIn(['media_attachments', 0, 'type']) === 'audio') {
        // TODO: toggle play/paused?
      } else if (status.getIn(['media_attachments', 0, 'type']) === 'video') {
        onOpenVideo(status.getIn(['media_attachments', 0]), { startTime: 0 });
      } else {
        onOpenMedia(status.get('media_attachments'), 0);
      }
    }
  }

  handleHotkeyReply = e => {
    e.preventDefault();
    this.props.onReply(this._properStatus(), this.context.router.history);
  }

  handleHotkeyFavourite = () => {
    this.props.onFavourite(this._properStatus());
  }

  handleHotkeyBoost = e => {
    this.props.onReblog(this._properStatus(), e);
  }

  handleHotkeyMention = e => {
    e.preventDefault();
    this.props.onMention(this._properStatus().get('account'), this.context.router.history);
  }

  handleHotkeyOpen = () => {
    window.open(this._properStatus().get('url'), '_blank');
  }

  handleHotkeyOpenProfile = () => {
    this.context.router.history.push(`/accounts/${this._properStatus().getIn(['account', 'id'])}`);
  }

  handleHotkeyMoveUp = e => {
    this.props.onMoveUp(this.props.status.get('id'), e.target.getAttribute('data-featured'));
  }

  handleHotkeyMoveDown = e => {
    this.props.onMoveDown(this.props.status.get('id'), e.target.getAttribute('data-featured'));
  }

  handleHotkeyToggleHidden = () => {
    this.props.onToggleHidden(this._properStatus());
  }

  handleHotkeyToggleSensitive = () => {
    this.handleToggleMediaVisibility();
  }

  _properStatus() {
    const { status } = this.props;

    if (status.get('reblog', null) !== null && typeof status.get('reblog') === 'object') {
      return status.get('reblog');
    } else {
      return status;
    }
  }

  handleRef = c => {
    this.node = c;
  }

  handleReply = () => {
    // this.props.onReply(this.props.status);
    this.replyTextareaRef.textarea.focus();
  }

  setTextareaRef = (c) => {
    this.replyTextareaRef = c;
  }

  toggleShowAllReplies = () => {
    this.setState({
      showAllReplies: !this.state.showAllReplies,
    });
  }

  // OnInput() {
  //   this.style.height = 'auto';
  //   this.style.height = (this.scrollHeight) + 'px';
  // }

  getDescendants = () => {
    const { status } = this.props;
    api().get(`/api/v1/statuses/${status.get('id')}/context`)
      .then(({ data }) => {
        if (0 < data.descendants.length) {
          this.props.importFetchedStatuses(data.descendants);
          // workaround start: force rerender
          this.setState({
            rerenderFlag: true,
          });
          this.setState({
            rerenderFlag: false,
          });
          // workaround end
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  componentDidMount() {
    if (this.props.status) {
      if (!this.props.replyOrigin) {
        this.getDescendants();
      }
    }
  }


  // componentWillUnmount() {
  //   if (this.replyBox) {
  //     this.replyBox.removeEventListener('input', this.OnInput);
  //   }
  // }

  // setReplyBox = (c) => {
  //   this.replyBox = c;
  //   if (this.replyBox) {
  //     this.replyBox.style = 'height:' + (this.replyBox.scrollHeight) + 'px;overflow-y:hidden;';
  //     this.replyBox.addEventListener('input', this.OnInput);
  //   }
  // }

  showFullRails() {
    const tabsBarWrappers = document.getElementsByClassName('tabs-bar__wrapper');
    if (tabsBarWrappers && tabsBarWrappers[0]) {
      let element = tabsBarWrappers[0];

      let yPosition = 0;
      while (element) {
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
      }
      window.scroll(0, yPosition);
    }
  }

  ensureShowReplyBox = () => {
    if (checkIfAndroid()) {
      this.showFullRails();
      let element = this.replyBox;

      let yPosition = 0;
      while (element) {
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
      }
      const postRails = document.getElementsByClassName('post-rails');
      if (postRails && postRails[0]) {
        if (yPosition > 800) {
          postRails[0].scrollBy(0, 200);
        }
      }
    }
  }

  handleOpenFollowingAccounts = () => {
    const { status } = this.props;

    let targetStatus, title;

    if (status.get('reblog', null) !== null && typeof status.get('reblog') === 'object') {
      [targetStatus, title] = [status.get('reblog'), 'Boosts'];
    } else if (status.get('repies_count') > 0) {
      [targetStatus, title] = [status, 'Replies'];
    } else if (status.get('favourites_count') > 0) {
      [targetStatus, title] = [status, 'Favourites'];
    }

    this.props.onOpenFollowingAccounts(targetStatus, title);
  };

  render () {
    let media = null;
    let statusAvatar, rebloggedByText;

    const { intl, hidden, featured, otherAccounts, unread, showThread, scrollKey, contextType } = this.props;

    let { status, account, replyOrigin, ...other } = this.props;
    let show_prepend = false;
    const home_timeline = window.location.pathname.includes('/web/timelines/home');

    if (status === null) {
      return null;
    }

    if (!replyOrigin && (status.get('in_reply_to') || status.get('reblog'))) {
      // return null;
      /* hide reply in home, trends and live stream */
      return (
        <StatusContainer
          id={status.get('in_reply_to_id')}
          contextType={contextType}
          showThread
          replyOrigin={status.getIn(['account', 'display_name_html'])}
        />
      );
    }

    if (home_timeline) {
      show_prepend = true;
    }

    if (status.get('notification_following_count') > 0) {
      if (home_timeline) {
        return (
          <NotificationStatus status={status} account={account} onOpenOtherFollowingAccounts={this.props.onOpenOtherFollowingAccounts} />
        );
      } else {
        return '';
      }
    }

    if (replyOrigin && status.get('visibility') !== 'public') {
      return '';
    }

    if (hidden) {
      return (
        <div ref={this.handleRef} className={classNames('status__wrapper', { focusable: !this.props.muted })} tabIndex='0'>
          {status.getIn(['account', 'display_name']) || status.getIn(['account', 'username'])}
          {status.get('content')}
        </div>
      );
    }

    if (status.get('filtered') || status.getIn(['reblog', 'filtered'])) {
      return (
        <div className='status__wrapper status__wrapper--filtered focusable' tabIndex='0' ref={this.handleRef}>
          <FormattedMessage id='status.filtered' defaultMessage='Filtered' />
        </div>
      );
    }

    if (status.get('media_attachments').size > 0) {
      if (this.props.muted) {
        media = (
          <AttachmentList
            compact
            media={status.get('media_attachments')}
          />
        );
      } else if (status.getIn(['media_attachments', 0, 'type']) === 'audio') {
        const attachment = status.getIn(['media_attachments', 0]);

        media = (
          <Bundle fetchComponent={Audio} loading={this.renderLoadingAudioPlayer} >
            {Component => (
              <Component
                src={attachment.get('url')}
                alt={attachment.get('description')}
                poster={attachment.get('preview_url') || status.getIn(['account', 'avatar_static'])}
                backgroundColor={attachment.getIn(['meta', 'colors', 'background'])}
                foregroundColor={attachment.getIn(['meta', 'colors', 'foreground'])}
                accentColor={attachment.getIn(['meta', 'colors', 'accent'])}
                duration={attachment.getIn(['meta', 'original', 'duration'], 0)}
                width={this.props.cachedMediaWidth}
                height={110}
                cacheWidth={this.props.cacheMediaWidth}
              />
            )}
          </Bundle>
        );
      } else if (status.getIn(['media_attachments', 0, 'type']) === 'video') {
        const attachment = status.getIn(['media_attachments', 0]);

        media = (
          <Bundle fetchComponent={Video} loading={this.renderLoadingVideoPlayer} >
            {Component => (
              <Component
                preview={attachment.get('preview_url')}
                blurhash={attachment.get('blurhash')}
                src={attachment.get('url')}
                alt={attachment.get('description')}
                width={this.props.cachedMediaWidth}
                height={110}
                inline
                sensitive={status.get('sensitive')}
                onOpenVideo={this.handleOpenVideo}
                cacheWidth={this.props.cacheMediaWidth}
                visible={this.state.showMedia}
                onToggleVisibility={this.handleToggleMediaVisibility}
              />
            )}
          </Bundle>
        );
      } else {
        media = (
          <Bundle fetchComponent={MediaGallery} loading={this.renderLoadingMediaGallery}>
            {Component => (
              <Component
                media={status.get('media_attachments')}
                sensitive={status.get('sensitive')}
                height={110}
                onOpenMedia={this.handleOpenMedia}
                cacheWidth={this.props.cacheMediaWidth}
                defaultWidth={this.props.cachedMediaWidth}
                visible={this.state.showMedia}
                onToggleVisibility={this.handleToggleMediaVisibility}
              />
            )}
          </Bundle>
        );
      }
    } else if (status.get('spoiler_text').length === 0 && status.get('card')) {
      media = (
        <Card
          onOpenMedia={this.props.onOpenMedia}
          card={status.get('card')}
          cacheWidth={this.props.cacheMediaWidth}
          defaultWidth={this.props.cachedMediaWidth}
          sensitive={status.get('sensitive')}
        />
      );
    }

    // if (otherAccounts && otherAccounts.size > 0) {
    //   statusAvatar = <AvatarComposite accounts={otherAccounts} size={48} />;
    // } else if (account === undefined || account === null) {
    statusAvatar = <Avatar account={status.get('account')} size={48} />;
    // } else {
    //   statusAvatar = <AvatarOverlay account={status.get('account')} friend={account} />;
    // }

    const avatarStyle = {
      width: '36px',
      height: '36px',
      backgroundSize: '36px 36px',
      backgroundImage: `url(${account && (account.get('avatar') || account.get('avatar_static'))})`,
    };

    const replies = Object.values(this.props.statuses.toJS())
      .filter(st => st.in_reply_to_id === status.get('id'))
      .sort((st1, st2) => st1.created_at > st2.created_at ? 1 : -1);
    const repliesCount = replies.length;

    return (
      <div className={classNames('status__wrapper', `status__wrapper-${status.get('visibility')}`, { 'status__wrapper-reply': !!status.get('in_reply_to_id'), read: unread === false, focusable: !this.props.muted })} tabIndex={this.props.muted ? null : 0} data-featured={featured ? 'true' : null} aria-label={textForScreenReader(intl, status, rebloggedByText)} ref={this.handleRef}>
        {show_prepend && <StatusPrepend status={status} account={account} contextType={contextType} {...other} />}

        <div className={classNames('status', `status-${status.get('visibility')}`, { 'status-reply': !!status.get('in_reply_to_id'), muted: this.props.muted, read: unread === false })} data-id={status.get('id')}>
          <div className='status__expand' onClick={this.handleExpandClick} role='presentation' />
          <div className='status__info'>
            <div className='d-flex'>
              <a data-id={status.getIn(['account', 'id'])} href={status.getIn(['account', 'url'])} title={status.getIn(['account', 'acct'])} className='status__display-name' target='_blank' rel='noopener noreferrer'>
                <div className='status__avatar'>
                  {statusAvatar}
                </div>

                <DisplayName account={status.get('account')} others={otherAccounts} />
              </a>
              <a href={status.get('url')} className='status__relative-time' target='_blank' rel='noopener noreferrer'><RelativeTimestamp timestamp={status.get('created_at')} /></a>
              <StatusMenu scrollKey={scrollKey} status={status} account={account} {...other} onReply={this.handleReply} repliesCount={repliesCount} showAllReplies={this.state.showAllReplies} toggleShowAllReplies={this.toggleShowAllReplies} />
            </div>
            <div>
              {/*<span className='status__visibility-icon'><Icon id={visibilityIcon.icon} title={visibilityIcon.text} /></span>*/}
            </div>
          </div>

          <StatusContent status={status} onClick={this.handleClick} expanded={!status.get('hidden')} showThread={showThread} onExpandedToggle={this.handleExpandedToggle} collapsable onCollapsedToggle={this.handleCollapsedToggle} />

          {media}

          <StatusActionBar scrollKey={scrollKey} status={status} account={account} {...other} onReply={this.handleReply} repliesCount={repliesCount} showAllReplies={this.state.showAllReplies} toggleShowAllReplies={this.toggleShowAllReplies} />

          {
            replies.filter(
              (reply, idx) => (idx < 3 || this.state.showAllReplies),
            ).map((reply) => (
              <StatusReplyContainer
                key={reply.id}
                id={reply.id}
                contextType={this.props.contextType}
              />
            ))
          }

          {
            !this.state.showAllReplies && repliesCount > 3 && (
              <button className='status__content__read-more-button d-flex' onClick={this.toggleShowAllReplies}>
                <span>Show all replies</span>
                <Icon id='arrow-right' />
              </button>
            )
          }

          {
            this.state.showAllReplies && repliesCount > 3 && (
              <button className='status__content__read-more-button d-flex' onClick={this.toggleShowAllReplies}>
                <span>Show Top 3 Replies</span>
                <Icon id='arrow-left' />
              </button>
            )
          }

          {
            this.state.showReplyBox && (
              <div className='status__reply'>
                <div className='status__avatar'>
                  <div className='account__avatar' style={avatarStyle} />
                </div>

                <div className='status__reply-box'>
                  {/*<textarea className='textarea' placeholder='Write a reply' rows='1' onChange={this.updateReply} value={this.state.replyText} ref={this.setReplyBox} onFocus={this.ensureShowReplyBox} />*/}
                  {/*<EmojiPickerDropdown onPickEmoji={this.handleEmojiPick} />*/}
                  {me && <ComposeFormContainer passRefCb={this.setTextareaRef} ancestor={this.props.status} getReplies={this.getDescendants} />}

                  {/*<button className='button btn-post' onClick={this.reply} disabled={this.state.replyText.length > 500}>Post</button>*/}
                  {/*<div className='character-counter__wrapper'><CharacterCounter max={500} text={this.state.replyText} /></div>*/}
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }

}
