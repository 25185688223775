import React from 'react';
import PropTypes from 'prop-types';
import Heading from './heading';

class BlockHeading extends React.PureComponent {

  render() {
    const { title } = this.props;

    return (
      <div className={'d px15px py10px'}>
        <div className={'d flex-row align-items-center'}>
          <Heading size='h2'>{title}</Heading>
        </div>
      </div>
    );
  }

}

BlockHeading.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};

export default BlockHeading;
