import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import Text from '../other_components/text';
import Icon from '../../../components/icon';
import EmojiPickerDropdown from '../../../components/status_with_reply/containers/emoji_picker_dropdown_container';
import AutosuggestTextarea from '../../../components/autosuggest_textarea';
import ChatComposeUploadFormContainer from '../containers/chat_compose_upload_form_container';
import ChatUploadButtonContainer from '../containers/chat_upload_button_container';

const allowedAroundShortCode = '><\u0085\u0020\u00a0\u1680\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000\u2028\u2029\u0009\u000a\u000b\u000c\u000d';

const style = {
  size: 20,
};

export default class ChatMessageComposeForm extends React.PureComponent {

  static propTypes = {
    isXS: PropTypes.bool,
    isPro: PropTypes.bool,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    suggestions: ImmutablePropTypes.list,
    focusDate: PropTypes.instanceOf(Date),
    caretPosition: PropTypes.number,
    preselectDate: PropTypes.instanceOf(Date),
    isSubmitting: PropTypes.bool,
    isChangingUpload: PropTypes.bool,
    isUploading: PropTypes.bool,
    anyMedia: PropTypes.bool,
    mediaAttachments: ImmutablePropTypes.list,
    account: PropTypes.object,
    chatConversationId: PropTypes.string,
    originMessageId: PropTypes.string,
    originMessage: PropTypes.object,
    onSendChatMessage: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onClearSuggestions: PropTypes.func.isRequired,
    onFetchSuggestions: PropTypes.func.isRequired,
    onSuggestionSelected: PropTypes.func.isRequired,
    onPickEmoji: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    onCancelReply: PropTypes.func.isRequired,
    onShowExpirePopover: PropTypes.func,
    onShowProModal: PropTypes.func,
    onPaste: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.textareaRef = React.createRef();
  }

  state = {
    focused: !this.props.isXS,
  }

  handleOnSendChatMessage = () => {
    this.props.onSendChatMessage(this.props.text, this.props.chatConversationId, this.props.originMessageId, this.props.mediaAttachments);
    this.props.onClear(this.props.chatConversationId);
    // document.querySelector('#gabsocial').focus()
    this.onFocus();
  }

  handleOnExpire = () => {
    if (this.props.isPro) {
      this.props.onShowExpirePopover(this.expiresBtn);
    } else {
      this.props.onShowProModal();
    }
  }

  onSuggestionsClearRequested = () => {
    this.props.onClearSuggestions(this.props.chatConversationId);
  }

  onSuggestionsFetchRequested = (token) => {
    this.props.onFetchSuggestions(token, this.props.chatConversationId);
  }

  onSuggestionSelected = (tokenStart, token, value) => {
    this.props.onSuggestionSelected(tokenStart, token, value, ['text'], this.props.chatConversationId);
  }

  handleChange = (e) => {
    this.props.onChange(e.target.value, this.props.chatConversationId);
  }

  onBlur = () => {
    this.setState({ focused: false });
  }

  onFocus = () => {
    this.setState({ focused: true });
  }

  onKeyDown = (e) => {
    const { disabled } = this.props;

    if (disabled) return e.preventDefault();

    // Ignore key events during text composition
    // e.key may be a name of the physical key even in this case (e.x. Safari / Chrome on Mac)
    // if (e.which === 229) return null;

    // switch (e.key) {
    // case 'Escape':
    //   document.querySelector('#gabsocial').focus();
    //   break;
    // case 'Enter':
    //   this.handleOnSendChatMessage();
    //   return e.preventDefault();
    // case 'Tab':
    //   this.sendBtn.focus();
    //   return e.preventDefault();
    // }

    // return null;

    if (e.keyCode === 13 && (e.ctrlKey || e.metaKey)) {
      this.handleOnSendChatMessage();
      return e.preventDefault();
    }
    return null;
  }

  setTextbox = (c) => {
    this.textbox = c;
  }

  setSendBtn = (c) => {
    this.sendBtn = c;
  }

  setExpiresBtn = (c) => {
    this.expiresBtn = c;
  }

  setAutosuggestTextarea = (c) => {
    this.autosuggestTextarea = c;
  }

  _updateFocusAndSelection = (prevProps) => {
    // This statement does several things:
    // - If we're beginning a reply, and,
    //     - Replying to zero or one users, places the cursor at the end of the textbox.
    //     - Replying to more than one user, selects any usernames past the first;
    //       this provides a convenient shortcut to drop everyone else from the conversation.
    if (this.props.focusDate !== prevProps.focusDate) {
      let selectionEnd, selectionStart;

      if (this.props.preselectDate !== prevProps.preselectDate) {
        selectionEnd = this.props.text.length;
        selectionStart = this.props.text.search(/\s/) + 1;
      } else if (typeof this.props.caretPosition === 'number') {
        selectionStart = this.props.caretPosition;
        selectionEnd = this.props.caretPosition;
      } else {
        selectionEnd = this.props.text.length;
        selectionStart = selectionEnd;
      }

      this.autosuggestTextarea.textarea.setSelectionRange(selectionStart, selectionEnd);
      this.autosuggestTextarea.textarea.focus();
    } else if (prevProps.isSubmitting && !this.props.isSubmitting) {
      this.autosuggestTextarea.textarea.focus();
    }
  }

  handleEmojiPick = (data) => {
    // const start = this.textareaRef.current.selectionStart;
    // const end = this.textareaRef.current.selectionEnd;
    // const strArr = this.state.value.split('');
    // strArr.splice(start, end - start, data.native);
    // const newValue = strArr.join('');
    // this.setState({ value: newValue });

    const { text } = this.props;
    const position = this.autosuggestTextarea.textarea.selectionStart;
    const needsSpace = data.custom && position > 0 && !allowedAroundShortCode.includes(text[position - 1]);

    this.props.onPickEmoji(position, data, needsSpace, this.props.chatConversationId);
  }

  handleFocus = () => {}

  handleCancelReply = () => {
    this.props.onCancelReply(this.props.chatConversationId);
  }

  handlePaste = (files) => {
    this.props.onPaste(files, this.props.chatConversationId);
  }

  componentDidMount() {
    this.props.onClearSuggestions();
    this._updateFocusAndSelection({});
  }

  componentDidUpdate (prevProps) {
    this._updateFocusAndSelection(prevProps);
  }

  render () {
    const { isXS, originMessage, originMessageId, text, suggestions, chatConversationId } = this.props;
    const { focused } = this.state;
    const disabled = false;

    const textareaClasses = classNames({
      'd': 1,
      'wrap': 1,
      'resize-none': 1,
      'bg-transparent': 1,
      'outline-none': 1,
      'line-height-125': 1,
      'px10px': 1,
      'font-size-14px': 1,
      'max-h-200px': 1,
      'w-100': 1,
      'py5px': 1,
      'border-medium1-1': 1,
    });

    // const expireBtnClasses = classNames({
    //   'd': 1,
    //   'border-right-1px': 1,
    //   'borderColorSecondary': 1,
    //   'w-40px': 1,
    //   'h-100': 1,
    //   'align-items-center': 1,
    //   'justify-content-center': 1,
    //   'cursor-pointer': 1,
    //   'outline-none': 1,
    //   'color-white': !!expiresAtValue,
    //   'font-weight-500': !!expiresAtValue,
    //   'bg-subtle': !expiresAtValue,
    //   'bg-black': !!expiresAtValue,
    // });

    const mobileInnerClasses = classNames({
      'd': 1,
      'w-100': 1,
      'pb5px': 1,
      'px15px': 1,
      'align-items-center': 1,
      'justify-content-center': 1,
      'saveAreaInsetPB': !focused,
      'saveAreaInsetPL': 1,
      'saveAreaInsetPR': 1,
    });

    const textarea = (
      <AutosuggestTextarea
        id='chat-message-compose-input'
        ref={this.setAutosuggestTextarea}
        className={textareaClasses}
        placeholder='Type a new message'
        disabled={disabled}
        value={text}
        onChange={this.handleChange}
        suggestions={suggestions}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        aria-autocomplete='list'
        maxLength={1600}
        onKeyDown={this.onKeyDown}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        autoFocus={!isXS}
        onPaste={this.handlePaste}
      >
        <div className='compose-form__modifiers'>
          <ChatComposeUploadFormContainer chatConversationId={chatConversationId} />
        </div>
      </AutosuggestTextarea>
    );

    const button = (
      <div
        disabled={disabled}
        onClick={this.handleOnSendChatMessage}
        className='button normal-button cursor-pointer'
        role='button' tabIndex={0}
      >
        <Text color='inherit' weight='medium' className={isXS ? undefined : ''}>Send</Text>
      </div>
    );

    if (isXS) {
      return (
        <div className={['d', 'z4', 'min-h-58px', 'w-100', 'mt-auto', 'chat-compose-form-container'].join(' ')}>
          <div className={['d', 'min-h-58px', 'bg-white', 'align-items-center', 'z3', 'bottom-0', 'right-0', 'left-0', 'pos-fixed'].join(' ')}>
            <div className={mobileInnerClasses}>
              <div className={['d', 'flex-row', 'align-items-center', 'min-h-58px', 'w-100', 'border-top-1px', 'borderColorSecondary'].join(' ')}>
                <div className={['d', 'flex-row', 'flex-grow-1', 'radius-rounded-4px', 'border-1px', 'borderColorSecondary'].join(' ')}>
                  <div className={['d', 'flex-grow-1'].join(' ')}>
                    {
                      originMessageId && (
                        <div className='d-flex border-bottom-medium1-1 mb5px pb5px pos-relative'>
                          <div>
                            <Icon id='reply' size={24} />
                          </div>
                          <div className='ml3px'>
                            <div>
                              {originMessage.get('account').get('username')}
                            </div>
                            <div className='font-style-italic'>
                              {originMessage.get('text')}
                            </div>
                          </div>
                          <div onClick={this.handleCancelReply} className='pos-absolute right-0 top-0' role='button' tabIndex={0}>
                            <Icon id='clear' size={24} />
                          </div>
                        </div>)
                    }
                    {textarea}
                  </div>
                </div>
                <div className='d pl10px h-100 align-items-center justify-content-center flex-row'>
                  <div className='mr5px d-flex'>
                    <EmojiPickerDropdown
                      onPickEmoji={this.handleEmojiPick}
                      onFocus={this.handleFocus}
                    />
                    <ChatUploadButtonContainer iconSize={style.size} chatConversationId={chatConversationId} />
                  </div>
                  {button}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={'pos-absolute chat-compose-form-container bottom-0 d-flex w-100 border-box px15px align-items-center bg-white'}>
        <div className={'d pr5px flex-grow-1 py10px'}>
          <div className={'d-flex'}>
            <div className={'d flex-grow-1'}>
              {
                originMessageId && (
                  <div className='d-flex border-bottom-medium1-1 mb5px pb5px pos-relative'>
                    <div>
                      <Icon id='reply' size={24} />
                    </div>
                    <div className='ml3px'>
                      <div>
                        {originMessage.get('account').get('username')}
                      </div>
                      <div className='font-style-italic'>
                        {originMessage.get('text')}
                      </div>
                    </div>
                    <div onClick={this.handleCancelReply} className='pos-absolute right-0 top-0' role='button' tabIndex={0}>
                      <Icon id='clear' size={24} />
                    </div>
                  </div>)
              }
              {textarea}
            </div>
          </div>
        </div>
        <div className={'d h-100 mb-auto mt10px align-items-center d-flex flex-row'}>
          <div className='mr5px d-flex'>
            <EmojiPickerDropdown
              onPickEmoji={this.handleEmojiPick}
              onFocus={this.handleFocus}
            />
            <ChatUploadButtonContainer iconSize={style.size} chatConversationId={chatConversationId} />
          </div>
          {button}
        </div>
      </div>
    );
  }

};
